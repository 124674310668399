import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useWidth } from '../Hooks';

import Flex from '../Flex';

import SideBar from './SideBar';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebar: {
    width: 72 * 6,
  },
  sidebarFull: {
    width: '100%',
  },
}));

const SplitView = ({
  children,
  open,
  onClose,
  onSizeChange,
  sideContent,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const isSmall = isWidthDown('sm', width);

  useEffect(() => {
    onSizeChange(isSmall);
  }, [isSmall]);

  return (
    <Flex className={classes.root} {...other}>
      <div className={classNames(classes.sidebar, isSmall ? classes.sidebarFull : '')}>
        {children}
      </div>
      <SideBar open={open} onClose={onClose}>
        {sideContent}
      </SideBar>
    </Flex>
  );
};

SplitView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSizeChange: PropTypes.func,
  open: PropTypes.bool,
  sideContent: PropTypes.node.isRequired,
};

SplitView.defaultProps = {
  onSizeChange: () => {
    console.log('implement onsizeChange');
  },
};

export default SplitView;
