import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Scrollbars } from 'react-custom-scrollbars';

// Handles layout and scrolling behaviour of the chat ouput

// TODO:
// - don't scroll down if view is scrolled by user
// - rerender when container height or width changes (+ throttle)

const useStyles = makeStyles(() => ({
  output: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
}));
const ScrollbarsRef = forwardRef(({ children }, ref) => {
  return <Scrollbars ref={ref}>{children}</Scrollbars>;
});
ScrollbarsRef.propTypes = {
  children: PropTypes.node,
};

const ChatOutput = ({ children, classes: eClasses, ...other }) => {
  const scrollbars = useRef();
  const classes = useStyles({ classes: eClasses });
  useEffect(() => {
    if (scrollbars && scrollbars.current !== undefined) {
      scrollbars.current.scrollToBottom();
    }
  });

  return (
    <ScrollbarsRef ref={scrollbars} {...other}>
      <div className={classes.output}>{children}</div>
    </ScrollbarsRef>
  );
};

ChatOutput.propTypes = {
  children: PropTypes.node,
};

ChatOutput.defaultProps = {
  children: null,
};

export default ChatOutput;
