import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import LightTextField from '../LightTextField';

const months = [
  'january',
  'feburary',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const DateSelect = ({ classes, allLabel, monthLabelMap, ...other }) => {
  return (
    <LightTextField id="country-global" select box SelectProps={{ displayEmpty: true }} {...other}>
      <MenuItem key="none" value="ALL">
        {allLabel}
      </MenuItem>
      {months &&
        months.map(m => (
          <MenuItem key={m} value={m}>
            {monthLabelMap[m]}
          </MenuItem>
        ))}
    </LightTextField>
  );
};
DateSelect.propTypes = {
  t: PropTypes.func.isRequired,
};

export default DateSelect;
