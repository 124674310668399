import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { LabeledIconToggle } from '../LabeledIconToggle';
import { Fade } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 0,
  },
  hidden: {
    display: 'none',
  },
}));

const FiltersToggleButton = ({ filtersOn, filtersOpen, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <LabeledIconToggle
      on={filtersOn}
      labelOn="on"
      labelOff="off"
      accent
      {...other}
      classes={{ root: classes.root }}
    >
      <Fade in={!filtersOpen}>
        <FilterIcon className={classNames(filtersOpen && classes.hidden)} />
      </Fade>
      <Fade in={filtersOpen}>
        <ExpandLessIcon className={classNames(!filtersOpen && classes.hidden)} />
      </Fade>
    </LabeledIconToggle>
  );
};
FiltersToggleButton.propTypes = {
  filtersOn: PropTypes.bool,
  filtersOpen: PropTypes.bool,
};

FiltersToggleButton.defaultProps = {
  filtersOn: false,
  filtersOpen: false,
};
export default FiltersToggleButton;
