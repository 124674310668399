import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { Avatar, Typography } from '@material-ui/core';

import { DiscreteProgress } from '../Progress';

const useStyles = makeStyles(theme => ({
  score: {
    marginRight: theme.spacing(2),
  },
  body: {
    flexGrow: 2,
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}));

const ScoreItem = ({ children, primaryText, secondaryText, value, max, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Fragment>
      <Avatar className={classes.score}>{value}</Avatar>
      <div className={classes.body}>
        {children}
        {!children && primaryText && (
          <Fragment>
            <Typography variant="body2">{primaryText}</Typography>
            {secondaryText && <Typography variant="body1">{secondaryText}</Typography>}
          </Fragment>
        )}
        <DiscreteProgress value={value} max={max} className={classes.progress} color="secondary" />
      </div>
    </Fragment>
  );
};

ScoreItem.propTypes = {
  children: PropTypes.node,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  value: PropTypes.number,
};

ScoreItem.defaultProps = {
  value: 0,
};

export default ScoreItem;
