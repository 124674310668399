// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Button } from '@material-ui/core';

// TODO: rework with ButtonBase?

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 'initial',
    borderRadius: 0,
    padding: 8,
  },
  iconColor: {
    '&:not([disabled])': {
      color: theme.palette.text.secondary,
    },
  },
}));
const ToolbarButton = ({ children, className, icon, classes: eClasses, ...props }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Button className={classNames(classes.root, icon && classes.iconColor, className)} {...props}>
      {children}
    </Button>
  );
};

ToolbarButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.bool,
};

ToolbarButton.defaultProps = {
  className: '',
  icon: false,
};

export default ToolbarButton;
