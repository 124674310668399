// @flow

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { ListItem, ListItemIcon, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    color: theme.palette.text.primary, // for TouchRipple color
    '&:hover': {
      textDecoration: 'none',
    },
  },
  active: {
    '& $listItem': {
      backgroundColor: theme.palette.action.selected,
    },
    '& $listItemText, & $listItemIcon': {
      color: theme.palette.primary[500],
    },
  },
  listItemText: {
    padding: '0 16px',
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    listItem: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));

const NavItem = ({ className, icon, label, classes: eClasses, ...otherProps }) => {
  const classes = useStyles({ classes: eClasses });
  const { TypographyProps, ...other } = otherProps;
  return (
    <ListItem button className={classNames(classes.root, className)} {...other}>
      {icon && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
      <Typography
        component="span"
        variant="body1"
        className={classes.listItemText}
        {...TypographyProps}
      >
        {label}
      </Typography>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  className: '',
  icon: '',
};

// prettier-ignore
export default NavItem;
