import React from 'react';
import PropTypes from 'prop-types';

import { Input as MuiInput, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {},
  chip: {
    marginRight: 8,
  },
  chipWrap: {
    marginBottom: 8,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    overflowX: 'auto',
  },
  wrap: {
    flexWrap: 'wrap',
    overflowX: 'initial',
  },
  input: {
    flexGrow: 1,
    minWidth: 128,
  },
  inputUnderlineDisabled: {
    height: 32, // give back missing 1px because of disableUnderline
  },
}));

const Input = ({
  children,
  getInputProps,
  selectedItem,
  downshiftProps,
  onRemoveItem,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  const {
    label,
    labelProps,
    disabled,
    wrap,
    disableUnderline,
    CustomComponent,
    CustomWrapperComponent,
    ...inputProps
  } = getInputProps ? getInputProps(downshiftProps) : {};
  const { itemToString } = downshiftProps;

  const SelectedItemComponent = CustomComponent || Chip;
  const WrapperComponent = CustomWrapperComponent || 'div';

  const getItemProps = item => ({
    key: itemToString(item),
    label: itemToString(item),
    onDelete: () => onRemoveItem(item),
  });

  if (children) {
    return React.cloneElement(children, {
      selectedItem,
      getItemProps,
      downshiftProps,
    });
  }

  return (
    <WrapperComponent className={classNames(classes.inputContainer, wrap && classes.wrap)}>
      {selectedItem &&
        selectedItem.length > 0 &&
        selectedItem.map(sItem => (
          <SelectedItemComponent
            {...getItemProps(sItem)}
            className={classNames(classes.chip, wrap && classes.chipWrap)}
          />
        ))}
      <MuiInput
        className={classNames(classes.input, disableUnderline && classes.inputUnderlineDisabled)}
        {...downshiftProps.getInputProps(inputProps)}
      />
    </WrapperComponent>
  );
};

Input.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  downshiftProps: PropTypes.object,
  getInputProps: PropTypes.func,
  items: PropTypes.array,
  onRemoveItem: PropTypes.func,
  selectedItem: PropTypes.array,
  theme: PropTypes.object,
};
export default Input;
