import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Slide } from '@material-ui/core';
// import Fade from '@material-ui/core/Fade';

import FullBleedButton from '../FullBleedButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    height: theme.heights.toolbarTopMobile,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar + 1,
    // never make invisible part blocking
    // (when setting buttonsContainer width manually)
    pointerEvents: 'none',
  },
  open: {
    '& $buttons': {
      pointerEvents: 'auto',
      boxShadow: theme.shadows[4],
    },
  },
  buttons: {
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.secondary[500],
    borderRadius: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    pointerEvents: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    root: {
      height: theme.heights.toolbarTop,
    },
  },
}));

// TODO: better name?

const ActionBar = ({
  affirmativeButtonContent,
  affirmativeButtonProps,
  dismissiveButtonContent,
  dismissiveButtonProps,
  open,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Slide in={open} direction="down" mountOnEnter unmountOnExit>
      <div className={classNames(classes.root, open && classes.open)} {...other}>
        <div className={classes.buttons}>
          <FullBleedButton color="transparent" {...dismissiveButtonProps}>
            {dismissiveButtonContent}
          </FullBleedButton>
          <FullBleedButton color="transparent" {...affirmativeButtonProps}>
            {affirmativeButtonContent}
          </FullBleedButton>
        </div>
      </div>
    </Slide>
  );
};
ActionBar.propTypes = {
  affirmativeButtonContent: PropTypes.node.isRequired,
  affirmativeButtonProps: PropTypes.object, // make required?
  dismissiveButtonContent: PropTypes.node.isRequired,
  dismissiveButtonProps: PropTypes.object, // make required?
  open: PropTypes.bool,
};

ActionBar.defaultProps = {
  affirmativeButtonContent: 'Bevestigen',
  dismissiveButtonContent: 'Annuleren',
  open: false,
};

export default ActionBar;
