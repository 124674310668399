import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import Flex from '../Flex';
import SlotItemBlock from './SlotItemBlock';

const SlotItemBlocks = ({ blocks = [], withScroll, small, ...other }) => {
  const list = (
    <Flex
      flexGrow={0}
      flexDirection={small ? 'column' : 'row'}
      minWidth={small ? '12px' : '100%'}
      minHeight={small ? '100%' : '8px'}
    >
      {blocks.map(block => (
        <SlotItemBlock
          flexGrow={block.duration}
          key={block.sequence}
          start={block.start}
          end={block.end}
          text={block.label}
          active={block.isActive}
          small={small}
          {...other}
        />
      ))}
    </Flex>
  );

  return withScroll ? <Scrollbars autoHide>{list}</Scrollbars> : list;
};

SlotItemBlocks.propTypes = {
  blocks: PropTypes.array,
  small: PropTypes.bool,
  t: PropTypes.object,
  withScroll: PropTypes.bool,
};

SlotItemBlocks.defaultValues = {
  blocks: [],
  small: false,
  withScroll: false,
};

export default SlotItemBlocks;
