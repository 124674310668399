import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import CheckIcon from '@material-ui/icons/Check';

import { CircularProgress, Fade } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: 24,
    height: 24,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

const SaveIndicator = ({
  className,
  iconColor,
  isSaving,
  progressColor,
  saved,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(classes.root, className)} {...other}>
      <Fade in={saved} mountOnEnter unmountOnExit>
        <CheckIcon color={iconColor} className={classes.absolute} />
      </Fade>
      <Fade in={isSaving} mountOnEnter unmountOnExit>
        <CircularProgress color={progressColor} size={24} className={classes.absolute} />
      </Fade>
    </div>
  );
};
SaveIndicator.propTypes = {
  className: PropTypes.string,
  iconColor: PropTypes.string,
  isSaving: PropTypes.bool,
  progressColor: PropTypes.string,
  saved: PropTypes.bool,
};

SaveIndicator.defaultProps = {
  className: '',
  iconColor: 'inherit',
  isSaving: false,
  progressColor: 'secondary',
  saved: false,
};

export default SaveIndicator;
