// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0, // preserve noWrap of children
  },
  label: {
    marginBottom: 6,
  },
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  marginDense: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));
const Label = ({ children, className, label, margin, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.marginNormal]: margin === 'normal',
          [classes.marginDense]: margin === 'dense',
        },
        className,
      )}
    >
      <Typography variant="caption" className={classes.label}>
        {label}
      </Typography>
      {children}
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
};

Label.defaultProps = {
  className: '',
  margin: 'none',
};

export default Label;
