import React, { useRef, useEffect, useMemo, useState, useImperativeHandle } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Slide, IconButton, Avatar } from '@material-ui/core';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import SelectableButton from './SelectableButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  itemsWrapper: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    minHeight: 64,
  },
  items: {
    position: 'absolute',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    top: '50%',
    minWidth: '100%',
  },
  control: {
    flexGrow: 0,
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(240,240,240,0.6)',
    padding: theme.spacing(1),
    // border: "solid 4px transparent"
    color: theme.palette.text.primary,
  },
  button: {
    flexGrow: 0,
    margin: theme.spacing(1),
  },
  hide: {
    opacity: 0.2,
  },
}));

const SelectableButtonBar = React.forwardRef(
  ({ open, items, selected, onChange, withControls }, ref) => {
    const getMeasures = () => [
      buttons[currentIdx].current.offsetLeft,
      buttons[currentIdx].current.clientWidth,
      wrapper.current.clientWidth,
      scroller.current.clientWidth,
    ];

    const classes = useStyles();
    const scroller = useRef();
    const wrapper = useRef();
    const [showControls, setShowControls] = useState(false);
    const buttons = useMemo(() => items.map(React.createRef), [items.length]);
    const [anim, setAnim, stop] = useSpring(() => ({ transform: 'translate3d(0px,-50%,0)' }));
    const offset = useRef(0);

    const bind = useDrag(({ down, movement: [mx, my] }) => {
      const [buttonOffset, buttonWidth, wrapperWidth, scrollerWidth] = getMeasures();
      // console.log(offset.current + mx);
      // if (scrollerWidth <= wrapperWidth) {
      // console.log(offset.current + mx);
      setAnim({
        transform: `translate3d(${
          down
            ? offset.current + mx
            : Math.min(0, Math.max(wrapperWidth - scrollerWidth, offset.current + mx))
        }px,-50%,0)`,
      });
      if (!down) {
        offset.current = Math.min(0, Math.max(wrapperWidth - scrollerWidth, offset.current + mx));
      }
    });

    const currentIdx = items.findIndex(({ key }) => key === selected);
    const onNext = (amount = 1) => {
      if (currentIdx > -1) {
        onChange(items[(currentIdx + amount + items.length) % items.length].key);
      }
    };
    useImperativeHandle(ref, () => ({
      next: (amount = 1) => onNext(amount),
    }));
    const distance = idx => Math.abs(currentIdx - idx);
    useEffect(() => {
      let offsetLeft = 0;
      if (buttons && buttons[currentIdx] && buttons[currentIdx].current) {
        const [buttonOffset, buttonWidth, wrapperWidth, scrollerWidth] = getMeasures();
        if (scrollerWidth <= wrapperWidth) {
          setShowControls(false);
        } else {
          setShowControls(true);
          offsetLeft = -buttonOffset - buttonWidth / 2 + wrapperWidth / 2;
        }
        offsetLeft = Math.min(0, Math.max(wrapperWidth - scrollerWidth, offsetLeft));
        offset.current = offsetLeft;
      } else {
        console.log('nok');
        console.log({
          buttons,
          currentIdx,
          button: buttons[currentIdx],
        });
        setShowControls(false);
      }
      // console.log(offsetLeft);
      setAnim({ transform: `translate3d(${offsetLeft}px,-50%,0)` });
    }, [currentIdx, items.length]);

    return (
      <div className={classes.root} ref={ref}>
        <Slide direction="right" mountOnEnter unmountOnExit in={withControls && showControls}>
          <Avatar className={classes.control}>
            <IconButton onClick={() => onNext(-1)}>
              <ChevronLeftIcon />
            </IconButton>
          </Avatar>
        </Slide>
        <div className={classes.itemsWrapper} ref={wrapper} {...bind()}>
          <animated.div className={classes.items} ref={scroller} style={anim}>
            {items.map(({ key, label }, idx) => (
              <SelectableButton
                ref={buttons[idx]}
                key={key}
                className={clsx(
                  classes.button,
                  withControls && showControls && distance(idx) > 1 && classes.hide,
                )}
                selected={selected === key}
                onClick={() => onChange(key)}
              >
                {label}
              </SelectableButton>
            ))}
          </animated.div>
        </div>
        <Slide direction="left" mountOnEnter unmountOnExit in={withControls && showControls}>
          <Avatar className={classes.control}>
            <IconButton onClick={() => onNext(1)}>
              <ChevronRightIcon />
            </IconButton>
          </Avatar>
        </Slide>
      </div>
    );
  },
);

export default SelectableButtonBar;
