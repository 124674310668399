// @flow

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AccountIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';

import { Avatar, Typography, Box } from '@material-ui/core';

import Toolbar from '../Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatar: {},
  fallbackAvatar: {
    width: '100%',
    height: '100%',
  },
  text: {
    flexGrow: 1,
    minWidth: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
  },
  username: {},
  role: {},
  logoutButton: {},
}));

const ProfileHeader = ({
  avatar,
  className,
  defaultIcon: DefaultIcon,
  user,
  children,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Toolbar size="large" className={classNames(classes.root, className)} {...other}>
      {avatar || (
        <Avatar src={user.avatarUrl} className={classes.avatar}>
          {!user.avatarUrl && <DefaultIcon className={classes.fallbackAvatar} />}
        </Avatar>
      )}
      <Box className={classes.text} display="flex" flexDirection="column">
        <Typography variant="body2" component="span" noWrap className={classes.username}>
          {user.username}
        </Typography>
        {user.role && (
          <Typography variant="caption" component="span" noWrap className={classes.role}>
            {user.role}
          </Typography>
        )}
      </Box>
      {children}
    </Toolbar>
  );
};

ProfileHeader.propTypes = {
  avatar: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultIcon: PropTypes.node,
  user: PropTypes.object.isRequired,
};

ProfileHeader.defaultProps = {
  className: '',
  defaultIcon: AccountIcon,
};

export default ProfileHeader;
