// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Downshift from 'downshift';
import { TextField, Paper, List, MenuItem } from '@material-ui/core';

function renderInput(inputProps) {
  const { classes, autoFocus, fullWidth, value, ref, ...other } = inputProps;

  return (
    <TextField
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      value={value}
      inputRef={ref}
      InputProps={{
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}

function renderSuggestion(params) {
  const { suggestion, index, itemProps, theme, highlightedIndex, selectedItem } = params;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem === suggestion;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id || suggestion._id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular,
      }}
    >
      {suggestion.label || suggestion.partNr || suggestion.name}
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children, classes } = options;

  return (
    <div className={classes.container} {...containerProps}>
      <Paper elevation={2} className={classes.paper}>
        <List className={classes.list}>{children}</List>
      </Paper>
    </div>
  );
}

// function getSuggestions(suggestions, inputValue) {
//   let count = 0;

//   return suggestions.filter(suggestion => {
//     const keep =
//       (!inputValue || suggestion.label.toLowerCase().includes(inputValue.toLowerCase())) &&
//       count < 5;

//     if (keep) {
//       count += 1;
//     }

//     return keep;
//   });
// }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const styles = {
  root: {},
  container: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
  },
  list: {
    maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
    overflowY: 'auto',
  },
};

class SingleAutosuggest extends React.Component {
  state = { inputValue: '' };

  onInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleStateChange = (changes, downshiftStateAndHelpers) => {
    if (!downshiftStateAndHelpers.isOpen) {
      this.setState({ inputValue: '' });
    }

    if (this.props.onChangedState) {
      this.props.onChangedState(changes, downshiftStateAndHelpers);
    }
  };

  render() {
    const {
      classes,
      className,
      fullWidth,
      theme,
      placeholder,
      onChange,
      itemToString,
      selectedItem,
      items,
    } = this.props;

    return (
      <Downshift
        onStateChange={this.handleStateChange}
        itemToString={itemToString}
        // onSelect={selection => onSelect(selection)}
        onChange={onChange}
        render={({
          getInputProps,
          getItemProps,
          isOpen,
          // inputValue,
          // selectedItem,
          highlightedIndex,
        }) => (
          <div className={classNames(classes.root, className)}>
            {renderInput({
              fullWidth,
              ...getInputProps({
                classes,
                placeholder,
                id: 'integration-downshift',
                value: this.state.inputValue,
                onChange: this.onInputChange,
              }),
            })}
            {isOpen && items.length > 0
              ? renderSuggestionsContainer({
                  classes,
                  children: items.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      theme,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  ),
                })
              : null}
          </div>
        )}
      />
    );
  }
}

SingleAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangedState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  // selectedItem: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SingleAutosuggest);
