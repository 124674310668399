// @flow
import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import { Fade } from '@material-ui/core';

const COLORS = ['green', 'blue', 'red', 'brown', 'yellow'];

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCSM46stvKY8VakTahd2zQOQXe3nGBcU1U&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ markerKey, onClickMarker, MarkerComponent, markers, googleMapProps }) => {
  const mergedGoogleMapProps = {
    defaultZoom: 9,
    defaultCenter: { lat: 51.0569264, lng: 5.1664556 },
    ...googleMapProps,
  };

  const DefaultMarkerComponent = marker => (
    <Fade in>
      <Marker
        key={marker[markerKey] || marker.id || marker.title}
        onClick={() => {
          onClickMarker(marker);
        }}
        position={{ lat: marker.latitude, lng: marker.longitude }}
        icon={{
          path: 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z',
          fillColor: COLORS[Math.floor(Math.random() * 5)],
          fillOpacity: 0.9,
          scale: 0.1,
          strokeColor: 'gold',
          strokeWeight: 1,
        }}
      />
    </Fade>
  );

  return (
    <GoogleMap {...mergedGoogleMapProps}>
      {markers.map(MarkerComponent || DefaultMarkerComponent)}
    </GoogleMap>
  );
});

// const MyMapContainer = MyMapComponent;
MyMapComponent.defaultProps = {
  markerKey: '_id',
  onClickMarker: marker => console.log('Add onClickMarker function', marker),
};

export default MyMapComponent;
