import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Paper, Typography } from '@material-ui/core';
import Flex from '../Flex';
import SlotItemBlocks from './SlotItemBlocks';

const ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: ({ color }) => color.main,
    color: theme.palette.common.white,
  },
  interactive: {
    cursor: 'pointer',
  },
  deleted: {
    opacity: 0.6,
  },
  clickable: {
    cursor: 'pointer',
  },
  timings: {
    color: theme.palette.text.hint,
    flexWrap: 'nowrap',
    flexGrow: 0,
  },
  timings__separator: {
    margin: `0 ${theme.spacing(1) / 2}px`,
  },
  title: {
    overflow: 'hidden',
    paddingRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    width: '100%',
  },
  icon: {
    marginBottom: 8,
    marginRight: 8,
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
  },
  vertical: {
    padding: `0px ${theme.spacing(1)}px`,
  },
}));

const SlotItem = ({
  blocks,
  button,
  children,
  className: classNameProp,
  color,
  endLabel,
  icons,
  onClick,
  orientation,
  startLabel,
  title,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses, color });
  const className = classNames(
    classes.root,
    button && classes.clickable,
    onClick && classes.onClick,
    classNameProp,
  );
  return (
    <Paper elevation={1} className={className} onClick={onClick}>
      <Flex
        width={'100%'}
        flexDirection={orientation === ORIENTATION.HORIZONTAL ? 'column' : 'row'}
      >
        <Flex
          fullColumn
          className={classNames(classes.content, {
            [classes.vertical]: orientation === ORIENTATION.HORIZONTAL,
          })}
        >
          <Flex
            flexDirection={orientation === ORIENTATION.HORIZONTAL ? 'row' : 'column'}
            alignItems={orientation === ORIENTATION.HORIZONTAL ? 'center' : 'flex-start'}
            paddingBottom={orientation === ORIENTATION.HORIZONTAL ? 0 : 8}
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              color="inherit"
              className={classNames(classes.title, classes.projectTitle)}
            >
              {title}
            </Typography>
            <Flex className={classes.timings}>
              <Typography variant="body2" color="inherit">
                {startLabel}
              </Typography>
              <Typography variant="body2" color="inherit" className={classes.timings__separator}>
                -
              </Typography>
              <Typography variant="body2" color="inherit">
                {endLabel}
              </Typography>
            </Flex>
          </Flex>
          {icons && <Flex flexWrap="wrap">{icons.map(({ icon }) => icon)}</Flex>}
          {children}
        </Flex>
        {blocks && (
          <SlotItemBlocks
            color={color}
            blocks={blocks}
            small={orientation === ORIENTATION.VERTICAL}
          />
        )}
      </Flex>
    </Paper>
  );
};

SlotItem.propTypes = {
  blocks: PropTypes.array,
  button: PropTypes.bool,
  children: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.object.isRequired,
  endLabel: PropTypes.string,
  icons: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  orientation: PropTypes.string,
  startLabel: PropTypes.string,
  title: PropTypes.string,
};

SlotItem.defaultProps = {
  button: false,
  className: '',
  color: {
    main: '#9e9e9e',
    dark: '#616161',
    light: '#e0e0e0',
  },
  endLabel: '',
  orientation: ORIENTATION.HORIZONTAL,
  startLabel: '',
};

export default SlotItem;
