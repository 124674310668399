import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';

// Returns an array which constists off one entry per unit of time from start till end
function mapFromUntil(start, end, unit, fn) {
  const m = start.clone();
  const result = [];
  while (m.isBefore(end)) {
    result.push(fn(m));
    m.add(1, unit);
  }

  return result;
}

// Get the index for today on the rendered part of the timeline:
// (for use in the styleSheet)

const useStyles = makeStyles(theme => ({
  schedulingGrid: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  timelineHeader: {
    height: timelineHeaderHeight,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginLeft: theme.widths.schedulingSideColumn || schedulingSideColumn,
    zIndex: 1,
  },
  timeline: {
    marginLeft: (theme.widths.schedulingSideColumn || schedulingSideColumn) - 1, // -1 to align border with ProjectRows
    flexGrow: 1,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  timelineWeekColumns: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    height: timelineHeaderHeight / 1.5, // / 3 * 2
    borderBottom: weekDivider,
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  timelineWeekColumn: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 500,
    borderLeft: weekDivider,
    '&:first-child': {
      borderLeft: 0,
    },
  },
  weekNumber: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 2,
    fontSize: 9,
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, .05)',
    color: theme.palette.text.secondary,
  },
  timelineDayColumns: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    height: '100%',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    counterReset: 'item',
  },
  timelineDayColumn: {
    position: 'relative',
    height: '100%',
    fontSize: 9,
    lineHeight: '9px', // same as line-height
    paddingTop: timelineHeaderHeight - 14, // TODO: refactor, make robust
    textAlign: 'center',
    borderLeft: `1px solid ${theme.palette.divider}`,
    // Mark the start of the week:
    '&:nth-child(7n+1)': {
      borderLeft: weekDivider,
    },

    '&:first-child': {
      borderLeft: 0,
    },

    // Disable right border for the first day of the weekend:
    // '&:nth-child(7n-1)': {
    //   // borderRight: 0,
    //   // Add a divider line after the day number in the timeline header:
    //   '&:after': {
    //     content: '""',
    //     position: 'absolute',
    //     top: 0,
    //     right: 0,
    //     height: timelineHeaderHeight - 1,
    //     borderRight: '1px solid rgba(0, 0, 0, .05)',
    //   },
    // },
  },
  today: {
    backgroundColor: 'rgba(96, 125, 139, .2)',
    borderLeft: '1px solid rgba(0, 0, 0, .4)',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      marginRight: -1,
      borderRight: '1px solid rgba(0, 0, 0, .4)',
    },
  },
  vacationDay: {
    backgroundColor: 'rgba(0, 0, 0, .05)',
    color: theme.palette.text.secondary,
  },
  disableBorder: {
    borderLeft: 0,
  },
}));
const TimelineGrid = ({ timeline, weeklyVacationDays, vacationDays, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  const today = moment();
  let vacationPreviousDay = false;
  const timelineDayColumns = mapFromUntil(timeline.start, timeline.end, 'days', date => {
    let vacation = weeklyVacationDays.includes(date.isoWeekday());

    if (!vacation) {
      vacation = !!vacationDays.find(day => date.isSame(day.startDate, 'day'));
    }
    const disableBorder = vacationPreviousDay && vacation;

    vacationPreviousDay = vacation;

    return (
      <li
        key={date.valueOf()}
        className={classNames(
          classes.timelineDayColumn,
          today.isSame(date, 'day') && classes.today,
          vacation && classes.vacationDay,
          disableBorder && classes.disableBorder,
        )}
        style={{ width: timeline.scale }}
      >
        {date.format('D')}
      </li>
    );
  });

  const timelineWeekColumns = mapFromUntil(timeline.start, timeline.end, 'weeks', date => (
    <li
      key={date.valueOf()}
      className={classes.timelineWeekColumn}
      style={{ width: timeline.scale * 7 }}
    >
      <div className={classes.weekNumber}>{date.week()}</div>
      {date.format('D')} -{' '}
      {date
        .clone()
        .add(6, 'days')
        .format('D MMM')}
    </li>
  ));

  return (
    <div className={classes.schedulingGrid}>
      <header className={classes.timelineHeader}>
        <ol className={classes.timelineWeekColumns}>{timelineWeekColumns}</ol>
      </header>
      <div className={classes.timeline}>
        <ol className={classes.timelineDayColumns}>{timelineDayColumns}</ol>
      </div>
    </div>
  );
};

TimelineGrid.propTypes = {
  timeline: PropTypes.object.isRequired,
  vacationDays: PropTypes.array.isRequired,
  weeklyVacationDays: PropTypes.array.isRequired,
};

const timelineHeaderHeight = 56;
const schedulingSideColumn = 320;
const weekDivider = '1px solid rgba(0, 0, 0, .2)';

export default TimelineGrid;
