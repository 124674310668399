import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import InfoIcon from '@material-ui/icons/Info';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing.unit * 6,
    width: '100%',
  },
  content: {
    alignItems: 'center',
    color: theme.palette.text.hint,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
  },
  icon: {
    marginBottom: theme.spacing.unit * 2,
  },
  text: {
    color: theme.palette.text.disabled,
    textAlign: 'center',
  },
}));

const EmptyState = ({ Icon, label, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Icon fontSize="large" className={classes.icon} />
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  Icon: PropTypes.node,
  label: PropTypes.string.isRequired,
};

EmptyState.defaultProps = {
  Icon: InfoIcon,
};

export default EmptyState;
