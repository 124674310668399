// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Ruler from '../Ruler';
import Keyline from '../Keyline';

// TODO: make Rulers positioning (outside or inside(overlay?)) configurable
// TODO: allow toggling Keylines

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  blendMode: {
    mixBlendMode: 'overlay', // TODO: make configurable (JSS dynamic props)
  },
  ruler: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  topRuler: {
    bottom: 'initial',
    transform: 'translateY(-100%)',
  },
  rightRuler: {
    left: 'initial',
    transform: 'translateX(100%)',
  },
  bottomRuler: {
    top: 'initial',
    transform: 'translateY(100%)',
  },
  leftRuler: {
    right: 'initial',
    transform: 'translateX(-100%)',
  },
}));

// Wraps Rulers and Keylines around its children

const Metrics = ({ children, className: classNameProp, keylines, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });

  const className = classNames(
    classes.root,
    classes.blendMode,
    {
      // [classes.someState]: prop_expression_here,
    },
    classNameProp,
  );

  // TODO: clean up
  // TODO: pass increment etc to Rulers
  return (
    <div className={className} {...other}>
      <Ruler className={classNames(classes.ruler, classes.topRuler)} />
      <Ruler className={classNames(classes.ruler, classes.rightRuler)} side="right" />
      <Ruler className={classNames(classes.ruler, classes.bottomRuler)} side="bottom" />
      <Ruler className={classNames(classes.ruler, classes.leftRuler)} side="left" />
      {keylines &&
        keylines.length > 0 &&
        keylines.map(({ at, fromSide }) => (
          <Keyline key={`${at} ${fromSide}`} at={at} fromSide={fromSide} />
        ))}
      {children}
    </div>
  );
};
Metrics.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  keylines: PropTypes.arrayOf(
    PropTypes.shape({
      at: PropTypes.number,
      fromSide: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    }),
  ),
};

Metrics.defaultProps = {
  children: '',
  className: '',
};
export default Metrics;
