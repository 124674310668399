import React, { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Camera from './Camera';
import CordovaCamera from './CordovaCamera';

import STATE from './state';

/** ****************************************************************************
 * Styles
 **************************************************************************** */
const useStyles = makeStyles(theme => ({
  btn: {
    margin: theme.spacing.unit,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  [theme.breakpoints.only('xs')]: {
    btn: {
      alignSelf: 'stretch',
      height: '50px',
    },
    buttons: {
      flexDirection: 'column',
    },
  },
  progress: {
    position: 'absolute',
    left: 0,
    width: '100%',
  },
}));

/** ****************************************************************************
 * Component
 **************************************************************************** */
const UploadImageChoice = forwardRef(
  ({ children, src, onChange, onChangeState, onStartUpload, uploading, state, t }, ref) => {
    const classes = useStyles();

    return (
      <Fragment>
        {state === STATE.DEFAULT && !uploading && (
          <div className={classes.buttons}>
            {src && (
              <Button className={classes.btn} onClick={() => onChange(src)}>
                <EditIcon className={classes.leftIcon} />
                {t.actions.edit}
              </Button>
            )}

            <Button
              className={classes.btn}
              onClick={_ => {
                onStartUpload();
              }}
            >
              <CloudUploadIcon className={classes.leftIcon} />
              {t.photoUpload.choose}
            </Button>

            <Button
              className={classes.btn}
              onClick={_ => {
                onChangeState(STATE.CAPTURE);
              }}
            >
              <PhotoCameraIcon className={classes.leftIcon} />
              {t.photoUpload.capture}
            </Button>
          </div>
        )}

        {state === STATE.CAPTURE && !navigator.camera && <Camera ref={ref} />}
        {state === STATE.CAPTURE && navigator.camera && <CordovaCamera onChange={onChange} />}

        {children}
      </Fragment>
    );
  },
);

UploadImageChoice.propTypes = {
  file: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeState: PropTypes.func.isRequired,
  state: PropTypes.string,
};

UploadImageChoice.defaultProps = {
  file: null,
  state: 'default',
};

export default UploadImageChoice;
