import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { TextField, InputAdornment } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '5px 0',
  },
  input: {
    boxSizing: 'border-box',
    alignItems: 'flex-end',
    padding: '8px 40px 9px 12px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 19,
  },
  inputMultiline: {
    overflow: 'hidden',
  },
  adornment: {
    position: 'absolute',
    right: -6,
    bottom: 18,
  },
}));
const ChatInput = ({ adornment, className, getInputNative, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <TextField
      className={classNames(classes.root, className)}
      InputProps={{
        disableUnderline: true,
        classes: { root: classes.input, inputMultiline: classes.inputMultiline },
        endAdornment: adornment && (
          <InputAdornment position="end" className={classes.adornment}>
            {adornment}
          </InputAdornment>
        ),
      }}
      inputRef={input => getInputNative(input)}
      multiline
      fullWidth
      {...other}
    />
  );
};

ChatInput.propTypes = {
  adornment: PropTypes.node,
  className: PropTypes.string,
  getInputNative: PropTypes.func.isRequired,
};

ChatInput.defaultProps = {
  className: '',
  getInputNative: () => console.log('provide getInputNative'),
};

export default ChatInput;
