import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Button, LinearProgress, Typography } from '@material-ui/core';

import Cropper from './Cropper';
import UploadImageChoice from './UploadImageChoice';
import ResponsiveDialog from '../ResponsiveDialog';

import { getImageUrl } from './helpers';

import STATE from './state';

/** ****************************************************************************
 * Styles
 **************************************************************************** */
const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonsRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  buttonDelete: {
    color: theme.palette.error[500],
  },
  cropper: {
    maxHeight: '80vh',
  },
  body: {
    position: 'relative',
  },
  preview: {
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '80vh',
  },
  previewProgress: {
    padding: theme.spacing(3),
  },
  waitMessage: {
    marginBottom: theme.spacing(1),
  },
}));

/** ****************************************************************************
 * Component
 **************************************************************************** */
const UploadImageDialog = ({
  canCrop,
  aspectRatio,
  src,
  cropData,
  onClose,
  onComplete,
  onImageChange,
  onImageReset,
  onClear,
  onCapture,
  onCropChange,
  onStartUpload,
  onRemove,
  open,
  state,
  changeState,
  t,
  title,
  uploader,
  uploading,
  progress,
  preview,
  children,
}) => {
  const classes = useStyles();
  const [fileTemp, setFileTemp] = useState();

  const camera = useRef();
  const cropper = useRef();
  const getCropData = _ => cropper.current.getData();

  const handleSave = fileNew => {
    onComplete({
      ...(fileNew || fileTemp),
      data: getCropData(),
    });
  };

  const handleChange = fileNew => {
    setFileTemp(fileNew);

    if (canCrop) {
      changeState(STATE.CROP);
      onImageChange(fileNew.data ? getImageUrl(fileNew) : fileNew.url);
    } else {
      handleSave(fileNew);
    }
  };

  const handleCameraCapture = async () => {
    const { url, buffer } = await camera.current.getImageSrc(true);
    onImageChange(url);
    onCapture(buffer);
  };

  const handleReset = () => {
    setFileTemp(null);
    changeState(STATE.DEFAULT);
    onImageReset();
  };

  const handleClear = () => {
    handleReset();
    onClear();
  };

  useEffect(() => {
    handleReset();
  }, [open]);

  const dialogActions = {
    [STATE.CAPTURE]: (
      <Fragment>
        <Button onClick={handleReset}>{t.actions.cancel}</Button>
        <Button color="primary" variant="outlined" onClick={handleCameraCapture}>
          {t.photoUpload.capture}
        </Button>
      </Fragment>
    ),
    [STATE.CROP]: (
      <div className={classes.buttons}>
        {src && (
          <Button
            className={classes.buttonDelete}
            onClick={() => {
              onRemove();
            }}
          >
            {t.actions.delete}
          </Button>
        )}
        <div className={classes.buttonsRight}>
          <Button onClick={handleClear}>{t.actions.cancel}</Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              handleSave(null);
            }}
          >
            {t.actions.save}
          </Button>
        </div>
      </div>
    ),
    [STATE.DEFAULT]: null,
  };

  return (
    <ResponsiveDialog
      actions={dialogActions[src ? 'crop' : state]}
      dismissiveActionText={t.actions.close}
      onDismiss={onClose}
      open={open}
      title={title}
      maxWidth={'xl'}
      noPadding={state !== STATE.DEFAULT || uploading}
    >
      <Fragment>
        {/* {children({ preview: state.userTemp })} */}
        {children}
        {!src ? (
          <UploadImageChoice
            ref={camera}
            state={state}
            onChange={handleChange}
            onChangeState={changeState}
            onStartUpload={onStartUpload}
            t={t}
            uploading={uploading}
          >
            {uploader}
          </UploadImageChoice>
        ) : (
          <Cropper
            aspectRatio={aspectRatio}
            ref={cropper}
            src={src}
            data={cropData}
            onCrop={onCropChange}
            className={classes.cropper}
          />
        )}
        {uploading ? (
          <Fragment>
            {preview ? (
              <center>
                <img src={preview} className={classes.preview} />
              </center>
            ) : (
              ''
            )}
            <div className={classes.previewProgress}>
              <Typography variant="caption" component="p" className={classes.waitMessage}>
                {t.photoUpload.pleaseWait}
              </Typography>
              <LinearProgress variant="determinate" value={progress} className={classes.progress} />
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </Fragment>
    </ResponsiveDialog>
  );
};

UploadImageDialog.propTypes = {
  aspectRatio: PropTypes.number,
  bucket: PropTypes.string,
  canCrop: PropTypes.bool,
  children: PropTypes.func,
  classes: PropTypes.object,
  src: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  onImageReset: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  open: PropTypes.bool,
  state: PropTypes.oneOf(Object.values(STATE)),
  t: PropTypes.object.isRequired,
  title: PropTypes.string,
  uploader: PropTypes.node,
};

UploadImageDialog.defaultProps = {
  bucket: 'default',
  aspectRatio: 16 / 9,
  canCrop: true,
  open: false,
  state: STATE.DEFAULT,
  title: 'Set the title of upload image dialog!',
  onImageChange: () => {},
  onImageReset: () => {},
  onRemove: () => {},
};

export default UploadImageDialog;
