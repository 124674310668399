import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import ActionBar from './ActionBar';

const useStyles = makeStyles(theme => {
  return {
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 9999,
      width: '100%',
      height: 64,
    },
  };
});

const ActionBarPortal = ({ open, getContainer, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return open
    ? ReactDOM.createPortal(
        <ActionBar classes={{ root: classes.root }} open={open} {...other} />,
        getContainer(),
      )
    : null;
};

ActionBarPortal.propTypes = {
  affirmativeButtonContent: PropTypes.node.isRequired,
  affirmativeButtonProps: PropTypes.object, // make required?
  dismissiveButtonContent: PropTypes.node.isRequired,
  dismissiveButtonProps: PropTypes.object, // make required?
  getContainer: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ActionBarPortal.defaultProps = {
  affirmativeButtonContent: 'Bevestigen',
  dismissiveButtonContent: 'Annuleren',
  open: false,
};

export default ActionBarPortal;
