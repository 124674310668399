// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 72,
  },
  [theme.breakpoints.up('sm')]: {
    root: {
      paddingLeft: 24,
    },
  },
}));

const StepTitle = ({ className, children, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Typography variant="h6" className={classNames(classes.root, className)}>
      {children}
    </Typography>
  );
};

StepTitle.propTypes = {
  children: PropTypes.node.isRequired,

  className: PropTypes.string,
};

StepTitle.defaultProps = {
  className: '',
};

// prettier-ignore
export default StepTitle;
