import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    width: `calc(100vw - ${theme.increments.mobile}px)`,
  },
  inner: {
    width: 'inherit',
  },
  [theme.breakpoints.up('sm')]: {
    paper: {
      width: theme.increments.tablet * 5,
    },
  },
}));

const NavigationDrawer = ({
  children,
  className,
  classes: classesProps,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: classesProps });
  return (
    <Drawer
      className={className}
      classes={{
        paper: classes.paper,
      }}
      {...other}
    >
      <div className={classes.inner}>{children}</div>
    </Drawer>
  );
};

NavigationDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

NavigationDrawer.defaultProps = {
  className: '',
};

/* prettier-ignore */
export default NavigationDrawer;
