import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    pointerEvents: 'auto',
    '&:hover $resizeHandle': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      },
    },
  },
}));

const Timeslot = ({
  className,
  children,
  extraStyle,
  left,
  onClick,
  onClickProps,
  width,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  let cursor = 'default';
  cursor = onClick ? 'pointer' : cursor;
  const style = {
    width,
    left,
    cursor,
    ...extraStyle,
  };

  return (
    <div
      className={classNames(classes.root, className)}
      onClick={onClick}
      style={style}
      role="presentation"
      {...onClickProps}
    >
      {children}
    </div>
  );
};

Timeslot.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  extraStyle: PropTypes.object,
  left: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onClickProps: PropTypes.object,
  width: PropTypes.number.isRequired,
};

Timeslot.defaultProps = {
  className: '',
  onClickProps: {},
  onClick: null,
  extraStyle: {},
};

export default Timeslot;
