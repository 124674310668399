// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  threadInputContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingRight: 4,
    flex: '0 0 auto',
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  messageInput: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: '18px 16px 16px',
    border: 0,
    fontFamily: 'inherit',
    fontSize: 14,
    resize: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      /* WebKit, Blink, Edge */
      color: 'rgba(0, 0, 0, .38)',
    },
    '&:-moz-placeholder': {
      /* Mozilla Firefox 4 to 18 */
      color: 'rgba(0, 0, 0, .38)',
      opacity: 1,
    },
    '&::-moz-placeholder': {
      /* Mozilla Firefox 19+ */
      color: 'rgba(0, 0, 0, .38)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': {
      /* Internet Explorer 10-11 */
      color: 'rgba(0, 0, 0, .38)',
    },
  },
  sendButton: {
    marginTop: 2,
    color: theme.palette.primary[500],
  },
  [theme.breakpoints.up('sm')]: {
    threadInputContainer: {
      height: 72,
      borderTop: 'none',
      boxShadow: theme.shadows[1],
      alignItems: 'flex-start',
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const ThreadInput = ({
  placeholder,
  sendTitle,
  mobile,
  onSubmit,
  handleInput,
  input,
  inputContainerClassName,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <form onSubmit={onSubmit} action="">
      <div className={classNames(classes.threadInputContainer, inputContainerClassName)}>
        <textarea
          placeholder={placeholder}
          autoFocus={!mobile}
          tabIndex="0"
          className={classes.messageInput}
          onChange={handleInput}
          onKeyDown={onSubmit}
          value={input}
        />
        <IconButton className={classes.sendButton} type="submit" title={sendTitle}>
          <SendIcon />
        </IconButton>
      </div>
    </form>
  );
};

ThreadInput.propTypes = {
  handleInput: PropTypes.func.isRequired,
  input: PropTypes.string.isRequired,
  inputContainerClassName: PropTypes.string,
  mobile: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  sendTitle: PropTypes.string,
};

ThreadInput.defaultProps = {
  inputContainerClassName: '',
  mobile: false,
  sendTitle: 'Send message',
  placeholder: 'Write a message...',
};

export default compose(
  withState('input', 'updateInput', ''),
  withHandlers({
    handleInput: ({ updateInput }) => event => {
      if (event.keyCode && event.keyCode === 13 && event.ctrlKey) {
        return;
      }
      updateInput(event.currentTarget.value);
    },
    onSubmit: ({ onSend, input, updateInput }) => event => {
      if (event.keyCode && !(event.keyCode === 13 && event.ctrlKey)) {
        return;
      }

      event.preventDefault();
      updateInput('');
      onSend(input);
    },
  }),
)(ThreadInput);
