import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { isWidthUp } from '@material-ui/core/withWidth';
import { useWidth } from '../Hooks';
import ShimAppBar from '../ShimAppBar';
import DetailView from './DetailView';
import DetailViewCloseButton from './DetailViewCloseButton';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    height: '100%',
  },
  // someState: {},
  shimAppBar: {
    backgroundColor: theme.palette.background.appBar,
  },
  shimAppBarSmall: {
    height: 64,
  },
}));

const actionBarContainerNodes = {};

const DetailViews = ({
  CloseButton,
  components,
  history,
  onClose,
  panels,
  maxPanels,
  maxVisiblePanels,
  panelWidth,
  panelProps,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const theme = useTheme();
  const [panelAvailable, setPanelAvailable] = useState({});
  useEffect(() => {
    setTimeout(() => {
      setPanelAvailable(panels.map(() => true));
    }, theme.transitions.duration.shorter);
  });
  const isTabletUp = isWidthUp('sm', width);
  const visiblePanels = Math.min(maxVisiblePanels, panels.length);

  const all = [];
  for (let i = 0; i < maxPanels; i += 1) {
    all.push(i);
  }

  return (
    <div className={classNames(classes.root)} style={{ width: visiblePanels * panelWidth }}>
      {all.map(index => {
        const DetailComponent = panels[index]
          ? components[panels[index].context].Component
          : () => null;
        let shift = 0;

        if (isTabletUp && index < panels.length - 1) {
          // invisible Panels
          if (index < panels.length - visiblePanels) {
            shift = (visiblePanels - 1) * panelWidth + (panels.length - visiblePanels - index) * 80;
          } else {
            // visiblePanels
            shift = (panels.length - 1 - index) * panelWidth;
          }
        }
        return (
          <DetailView
            setActionBarContainerNode={node => {
              actionBarContainerNodes[index] = node;
            }}
            key={index} // panels[index] ? panels[index].id :
            open={!!panels[index]}
            docked={false}
            width={isTabletUp ? panelWidth : '100vw'}
            shift={shift}
          >
            {panelAvailable[index] && (
              <DetailComponent
                id={panels[index] && panels[index].id}
                history={history}
                queryParams={panels[index]}
                getActionBarContainer={() => actionBarContainerNodes[index]}
                {...panelProps}
              />
            )}
            {CloseButton ? (
              <CloseButton
                onClick={() => {
                  onClose(panels[index] && panels[index].context);
                }}
              />
            ) : (
              <DetailViewCloseButton
                onClick={() => {
                  onClose(panels[index] && panels[index].context);
                }}
              />
            )}
            <ShimAppBar
              position="static"
              className={classNames(classes.shimAppBar, !isTabletUp ? classes.shimAppBarSmall : '')}
            />
          </DetailView>
        );
      })}
    </div>
  );
};
DetailViews.propTypes = {
  className: PropTypes.string,
  CloseButton: PropTypes.func,
  components: PropTypes.object.isRequired,
  history: PropTypes.object,
  maxPanels: PropTypes.number,
  maxVisiblePanels: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  panelProps: PropTypes.object,
  panels: PropTypes.array.isRequired,
  panelWidth: PropTypes.number,
};

DetailViews.defaultProps = {
  className: '',
  panelWidth: 72 * 7,
  maxPanels: 3,
  maxVisiblePanels: 2,
};

export default DetailViews;
