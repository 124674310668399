import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import ChatText from './ChatText';
// TODO: add link with link props (more accessible) or click handler to open image
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  text: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: 0,
    width: '100%',
  },
}));

const ChatImage = ({
  align,
  children,
  className: classNameProp,
  component: componentProp,
  src,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const Component = componentProp || 'div';
  const className = classNames(
    classes.root,
    {
      [classes.alignLeft]: align === 'left',
      [classes.alignRight]: align === 'right',
    },
    classNameProp,
  );

  return (
    <Component className={className} {...other}>
      {children && (
        <ChatText align={align} className={classes.text} {...other}>
          {children}
        </ChatText>
      )}
      <img className={classes.img} src={src} alt="" />
    </Component>
  );
};

ChatImage.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  src: PropTypes.string,
};

ChatImage.defaultProps = {
  align: 'left',
  children: null,
  className: '',
  src: '',
};

export default ChatImage;
