import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'block',
      borderTop: `solid 1px ${theme.palette.divider}`,
      marginTop: 0,
      padding: 12,
      paddingLeft: 24,
      paddingRight: 24,
      backgroundColor: grey[200],
    },
  };
});

const Header = ({ children, classes: eClasses, ...props }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Typography variant="subtitle1" color="inherit" className={classes.root} {...props}>
      {children}
    </Typography>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
