import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ListItem, Typography, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    padding: 0,
    backgroundColor: ({ color }) => color.dark,
    color: theme.palette.common.white,

    '&:not(:last-child)': {
      borderColor: ({ color }) => color.main,
      borderStyle: 'solid',
      borderWidth: 0,
      borderRightWidth: 2,
    },
  },
  small: {
    '&:not(:last-child)': {
      borderWidth: 0,
      borderBottomWidth: 2,
    },
  },
  timing: {
    paddingRight: theme.spacing(2),
    minWidth: 92,
  },
  contrastColor: {
    color: theme.palette.common.white,
  },
  smallFont: {
    fontSize: 12,
  },
  worked: {
    backgroundColor: theme.palette.secondary.A200,
    color: theme.palette.text.secondary,
  },
  cancelled: {
    background: `repeating-linear-gradient(
      45deg,
      rgba(255,255,255,0.2),
      rgba(255,255,255,0.2) 5px,
      rgba(255,255,255,0.3) 5px,
      rgba(255,255,255,0.3) 10px
    )`,
  },
}));

const SlotItemBlock = ({
  color,
  text,
  start,
  end,
  flexGrow = 1,
  small,
  active,
  classes: eClasses,
}) => {
  const classes = useStyles({ color, classes: eClasses });
  const tooltip = (
    <Fragment>
      <Typography
        variant="body2"
        className={classNames(classes.smallFont, classes.timing, classes.contrastColor)}
      >
        {`${start} - ${end}`}
      </Typography>
      <Typography variant="body1" className={classNames(classes.smallFont, classes.contrastColor)}>
        {text}
      </Typography>
    </Fragment>
  );

  return (
    <Tooltip title={tooltip}>
      <ListItem
        className={classNames(classes.root, small && classes.small, !active && classes.cancelled)}
        style={{ flexGrow }}
      />
    </Tooltip>
  );
};

SlotItemBlock.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string.isRequired,
  end: PropTypes.number.isRequired,
  flexGrow: PropTypes.number,
  small: PropTypes.bool.isRequired,
  start: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

SlotItemBlock.defaultValues = {
  active: true,
  small: false,
};

export default SlotItemBlock;
