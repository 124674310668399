import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    width: theme.widths.schedulingSideColumn || 360,
    maxWidth: theme.widths.schedulingSideColumn || 360,
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflowY: 'hidden',
  },
}));

const TimelineRowHeader = ({ className, children, classes: eClasses, ...props }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <header className={classNames(className, classes.root)} {...props}>
      {children}
    </header>
  );
};

TimelineRowHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TimelineRowHeader.defaultProps = {
  className: '',
};

export default TimelineRowHeader;
