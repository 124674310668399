import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    flex: '0 0 auto',
  },
}));

const TimelineHeadings = ({ children, className, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return <div className={classNames(classes.root, className)}>{children}</div>;
};

TimelineHeadings.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

TimelineHeadings.defaultProps = {
  className: '',
};

export default TimelineHeadings;
