import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import * as colors from '@material-ui/core/colors';

const useStyles = makeStyles(theme => {
  const { palette } = theme;
  const transitionDuration = 4; // 400ms

  return {
    root: {
      position: 'relative',
      overflow: 'hidden',
      height: 5,
      backgroundColor: palette.primary[100],
    },
    rootBuffer: {
      backgroundColor: 'transparent',
    },
    rootQuery: {
      transform: 'rotate(180deg)',
    },
    bar: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      top: 0,
      transition: 'transform 0.2s linear',
      backgroundColor: palette.primary[500],
    },
    dashed: {
      position: 'absolute',
      marginTop: 0,
      height: '100%',
      width: '100%',
      background: `radial-gradient(${palette.primary[100]} 0%, ${
        palette.primary[100]
      } 16%, transparent 42%)`,
      backgroundSize: '10px 10px',
      backgroundPosition: '0px -23px',
      animation: 'buffer 3s infinite linear',
    },
    indeterminateBar1: {
      willChange: 'left, right',
      animation: 'indeterminate-1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite',
    },
    indeterminateBar2: {
      willChange: 'left, right',
      animation: 'indeterminate-2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite',
      animationDelay: '1.15s',
    },
    determinateBar1: {
      willChange: 'width',
      transition: `width .${transitionDuration}s linear`,
    },
    determinateBar2: {
      display: 'none',
    },
    bufferBar1: {
      transition: `width .${transitionDuration}s linear`,
      backgroundColor: palette.primary[100],
    },
    bufferBar2: {
      transition: `width .${transitionDuration}s linear`,
    },
    '@keyframes indeterminate-1': {
      '0%': {
        left: '-35%',
        right: '100%',
      },
      '60%': {
        left: '100%',
        right: '-90%',
      },
      '100%': {
        left: '100%',
        right: '-90%',
      },
    },
    '@keyframes indeterminate-2': {
      '0%': {
        left: '-200%',
        right: '100%',
      },
      '60%': {
        left: '107%',
        right: '-8%',
      },
      '100%': {
        left: '107%',
        right: '-8%',
      },
    },
    '@keyframes buffer': {
      '0%': {
        opacity: 1,
        backgroundPosition: '0px -23px',
      },
      '50%': {
        opacity: 0,
        backgroundPosition: '0px -23px',
      },
      '100%': {
        opacity: 1,
        backgroundPosition: '-200px -23px',
      },
    },
    '@keyframes query': {
      '0%': {
        opacity: 1,
        transform: 'translateX(35%) scale(.3, 1)',
      },
      '100%': {
        opacity: 0,
        transform: 'translateX(-50%) scale(0, 1)',
      },
    },
  };
});

const ColoredLinearProgress = ({
  className,
  variant,
  value,
  valueBuffer,
  color,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });

  const rootClasses = classNames(
    classes.root,
    {
      [classes.rootBuffer]: variant === 'buffer',
      [classes.rootQuery]: variant === 'query',
    },
    className,
  );
  const dashedClasses = classNames({
    [classes.dashed]: variant === 'buffer',
  });
  const bar1Classes = classNames(classes.bar, {
    [classes.indeterminateBar1]: variant === 'indeterminate' || variant === 'query',
    [classes.determinateBar1]: variant === 'determinate',
    [classes.bufferBar1]: variant === 'buffer',
  });
  const bar2Classes = classNames(classes.bar, {
    [classes.indeterminateBar2]: variant === 'indeterminate' || variant === 'query',
    [classes.determinateBar2]: variant === 'determinate',
    [classes.bufferBar2]: variant === 'buffer',
  });

  let colorMain = '';
  let colorFaded = '';

  if (color) {
    colorMain = colors[color][500];
    colorFaded = colors[color][100];
  }

  const inlineStyles = {
    root: {
      background: colorFaded,
    },
    bar1: {
      background: colorMain,
      width: '',
    },
    bar2: {},
  };
  const rootProps = {};

  if (variant === 'determinate') {
    inlineStyles.bar1.width = `${value}%`;
    rootProps['aria-valuenow'] = Math.round(value);
  } else if (variant === 'buffer') {
    inlineStyles.bar1.width = `${valueBuffer}%`;
    inlineStyles.bar2.width = `${value}%`;
  }

  return (
    <div className={rootClasses} style={inlineStyles.root} {...rootProps} {...other}>
      <div className={dashedClasses} />
      <div className={bar1Classes} style={inlineStyles.bar1} />
      <div className={bar2Classes} style={inlineStyles.bar2} />
    </div>
  );
};
ColoredLinearProgress.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'buffer', 'query']),
  /**
   * The value of progress, only works in determinate and buffer variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number,
  /**
   * The value of buffer, only works in buffer variant.
   * Value between 0 and 100.
   */
  valueBuffer: PropTypes.number,
};

ColoredLinearProgress.defaultProps = {
  className: '',
  variant: 'indeterminate',
  value: 0,
  valueBuffer: 0,
  color: '',
};
export default ColoredLinearProgress;
