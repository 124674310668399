import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// A scrolling container.
// Scrolls vertically and grows by default.
// Use inside a Flex container.

const useStyles = makeStyles(() => ({
  iOSMomentum: {
    '-webkit-overflow-scrolling': 'touch',
  },
  horizontal: {
    overflowX: 'auto',
  },
  vertical: {
    overflowY: 'auto',
  },
  grow: {
    flexGrow: 1,
  },
}));

const Scroll = ({ children, className, horizontal, noGrow, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div
      className={classNames(
        classes.iOSMomentum,
        horizontal ? classes.horizontal : classes.vertical,
        !noGrow && classes.grow,
        className,
      )}
      {...other}
    >
      {children}
    </div>
  );
};

Scroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  noGrow: PropTypes.bool,
};

Scroll.defaultProps = {
  className: '',
  horizontal: false,
  noGrow: false,
};

export default Scroll;
