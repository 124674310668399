import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Avatar as AvatarMaterial,
  Typography,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles(theme => ({
  root: {
    height: 64,
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  blocked: {
    opacity: 0.5,
  },
  avatarBlocked: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    color: theme.palette.common.white,
    left: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(1.5),
    zIndex: 100,
  },
  body: {
    minWidth: 0, // preserve noWrap
    paddingLeft: theme.spacing(2),
  },
  username: {
    paddingRight: theme.spacing(10),
  },
  lastMessage: {},
  timestamp: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  bold: {
    fontWeight: 500,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  [theme.breakpoints.up('sm')]: {
    timestamp: {
      right: 24,
    },
  },
}));

const ChatListItem = ({
  active,
  blocked,
  className,
  username,
  avatarUrl,
  lastMessage,
  timestamp,
  unread,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <ListItem
      button={!active}
      className={classNames(classes.root, active && classes.active, className)}
      {...other}
    >
      {blocked && (
        <AvatarMaterial className={classes.avatarBlocked}>
          <BlockIcon />
        </AvatarMaterial>
      )}
      <ListItemAvatar>
        <Avatar src={avatarUrl} />
      </ListItemAvatar>
      <div className={classes.body}>
        <Typography
          variant="subtitle1"
          noWrap
          className={classNames(classes.username, unread && classes.bold)}
        >
          {username}
        </Typography>
        <Typography
          variant="caption"
          className={classNames(
            classes.lastMessage,
            unread && classNames(classes.bold, classes.textPrimary),
          )}
          noWrap
        >
          {lastMessage}
        </Typography>
      </div>
      <Typography
        variant="caption"
        color={unread ? 'primary' : 'textSecondary'}
        className={classes.timestamp}
      >
        {timestamp}
      </Typography>
    </ListItem>
  );
};

ChatListItem.propTypes = {
  active: PropTypes.bool,
  avatarUrl: PropTypes.string.isRequired,
  blocked: PropTypes.bool,
  className: PropTypes.string,
  lastMessage: PropTypes.node.isRequired,
  timestamp: PropTypes.string.isRequired,
  unread: PropTypes.bool,
  username: PropTypes.string.isRequired,
};

ChatListItem.defaultProps = {
  active: false,
  blocked: false,
  className: '',
  unread: false,
};

// prettier-ignore
export default ChatListItem;
