// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import { Snackbar, IconButton } from '@material-ui/core';

// TODO: remove translate dep
// TODO: spread more props (autoHideDuration etc)

const SimpleSnackbar = ({ classes, onClose, open, ...other }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>,
      ]}
      {...other}
    />
  );
};
SimpleSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

SimpleSnackbar.defaultProps = {
  open: false,
};

/* prettier-ignore */
export default SimpleSnackbar;
