import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    borderRight: `1px solid ${theme.palette.divider}`,
    flexGrow: 1,
    width: 0, // makes columns not grow wider than flex parent
  },
  rows: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  row: {
    boxSizing: 'border-box',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  highlight: {
    backgroundColor: theme.palette.mediumDivider,
  },
  shaded: {
    background:
      'repeating-linear-gradient(315deg, rgb(255, 255, 255) 1px, rgba(0, 0, 0, 0.1) 3px, rgb(255, 255, 255) 4px)',
  },
}));

const CalendarColumn = ({
  children,
  className,
  highlight,
  rowHeight,
  rows: rowsAmount,
  shaded,
  classes: eClasses,
  ...other
}) => {
  const rows = [];
  const classes = useStyles({ classes: eClasses });
  for (let i = 0; i < rowsAmount; i += 1) {
    rows.push(<div key={i} className={classes.row} style={{ height: rowHeight + 1 }} />);
    // height + 1 for bottom border
  }

  return (
    <div
      className={classNames(
        classes.root,
        highlight && classes.highlight,
        shaded && classes.shaded,
        className,
      )}
      {...other}
    >
      <div className={classes.rows}>{rows}</div>
      {children}
    </div>
  );
};

CalendarColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  highlight: PropTypes.bool,
  rowHeight: PropTypes.number,
  rows: PropTypes.number,
  shaded: PropTypes.bool,
};

CalendarColumn.defaultProps = {
  children: '',
  className: '',
  highlight: false,
  rowHeight: 48,
  rows: 24,
  shaded: false,
};

export default CalendarColumn;
