import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, useGesture } from 'react-spring';
import { useDrag } from 'react-use-gesture';

const initialState = {
  XY: { x: 0, y: 0 },
  isDragging: false,
};

const PannerStateContext = React.createContext();
const PannerDispatchContext = React.createContext();

const usePannerDispatch = () => {
  const context = React.useContext(PannerDispatchContext);
  if (context === undefined) {
    throw new Error('usePannerDispatch must be used within a PannerProvider');
  }
  return context;
};

const usePannerState = () => {
  const context = React.useContext(PannerStateContext);
  if (context === undefined) {
    throw new Error('usePannerState must be used within a PannerProvider');
  }
  return context;
};

const dispatchXYWithInterpolatedXY = ({ xy }, dispatch) => {
  dispatch({ type: 'setXY', xy: { x: xy[0], y: xy[1] } });
};

const calculateSnap = (movement, border, snap) => {
  if ((border > 0 && movement > 0) || (border < 0 && movement < 0)) {
    return 0;
  }
  if ((border > 0 && Math.abs(movement) > border) || (border < 0 && movement > Math.abs(border))) {
    return -border;
  }
  if (snap) {
    const remainder = Math.abs(movement % snap);
    return remainder < snap / 2 ? movement + remainder : movement + remainder - snap;
  }
  return movement;
};

// movement is calculated starting from topleft corner of the draggable item
// bounds are the bounds of the container div (bound to the pan)
// childSize is the height and width of the draggable item
const calculateCorrection = (
  movement,
  childSize,
  { bounds = {}, snap = { x: undefined, y: undefined } },
) => {
  const { height, width } = bounds;
  const [mx, my] = movement;
  const [childW, childH] = childSize;
  const borderX = width ? childW - width : 0;
  const borderY = height ? childH - height : 0;
  // if movement is positive then we are dragging the left/top edge away from the parent container
  // if the negative movement is more than the border set to negative border, else set to movement
  const x = calculateSnap(mx, borderX, snap.x);
  const y = calculateSnap(my, borderY, snap.y);
  return [x, y];
};

const usePanDrag = () => {
  const dispatch = usePannerDispatch();
  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    onFrame: props => {
      dispatchXYWithInterpolatedXY(props, dispatch);
    },
  }));
  const panBind = useDrag(
    dragProps => {
      // console.log('dragProps:: ', dragProps);
      const { args, down, dragging, movement, tap } = dragProps;
      const [ref, { bounds, snap }] = args.length > 1 ? args : [...args, {}];
      const childSize = [ref.current.offsetWidth, ref.current.offsetHeight];
      set(() => {
        const calculatedXY =
          down || tap || !ref
            ? movement
            : calculateCorrection(movement, childSize, { bounds, snap });
        return { xy: calculatedXY };
      });
      dispatch({ type: 'dragging', dragging });
    },
    { initial: () => [...xy.getValue()], filterTaps: true },
  );
  return [{ xy }, { panBind, set }];
};

const XYReducer = (state, action) => {
  switch (action.type) {
    case 'dragging': {
      return { ...state, isDragging: action.dragging };
    }
    case 'setXY': {
      return { ...state, XY: action.xy };
    }
    case 'setWidth': {
      return { ...state, width: action.width };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const PannerProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(XYReducer, initialState);
  return (
    <PannerStateContext.Provider value={state}>
      <PannerDispatchContext.Provider value={dispatch}>{children}</PannerDispatchContext.Provider>
    </PannerStateContext.Provider>
  );
};

PannerProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export { PannerProvider, usePannerDispatch, usePannerState, usePanDrag };
