import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import PersonIcon from '@material-ui/icons/Person';

import { Avatar, Typography } from '@material-ui/core';

// NOTE: This component should probably be replaced with ListItem

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  avatar: {
    flexShrink: 0,
  },
  textWrapper: {
    padding: '2px 16px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: 40,
    overflow: 'hidden',
  },
}));

const resetLineHeight = { lineHeight: 1.2 };
const AvatarWithName = ({
  className,
  name,
  nameType,
  secondary,
  secondaryType,
  color,
  src,
  letter,
  classes: eClasses,
  ...props
}) => {
  const classes = useStyles({ classes: eClasses });
  let avatarChild = null;
  if (letter && !src) {
    avatarChild = name.substr(0, 1);
  } else if (!src) {
    avatarChild = <PersonIcon />;
  }

  const avatarStyle = color ? { backgroundColor: color } : {};
  const justifyContent = secondary ? 'space-between' : 'center';
  return (
    <div className={classNames(classes.root, className)} {...props}>
      <Avatar alt={name} className={classes.avatar} src={src} style={avatarStyle}>
        {avatarChild}
      </Avatar>
      <div className={classes.textWrapper} style={{ justifyContent }}>
        <Typography variant={nameType} style={resetLineHeight} noWrap>
          {name}
        </Typography>
        {secondary && (
          <Typography variant={secondaryType} color="textSecondary" style={resetLineHeight} noWrap>
            {secondary}
          </Typography>
        )}
      </div>
    </div>
  );
};

AvatarWithName.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  letter: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nameType: PropTypes.string,
  secondary: PropTypes.string,
  secondaryType: PropTypes.string,
  src: PropTypes.string,
};

AvatarWithName.defaultProps = {
  className: '',
  secondary: '',
  secondaryType: 'body1',
  nameType: 'subtitle1',
  color: '',
  src: '',
  letter: false,
};

export default AvatarWithName;
