import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
  },
  iconWrapper: {
    display: 'flex',
    color: theme.palette.icon,
    marginRight: 32,
  },
  iconFormFieldAlign: {
    marginTop: 20, // align with input
  },
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  marginDense: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  [theme.breakpoints.up('sm')]: {
    iconWrapper: {
      marginRight: 24,
    },
  },
}));

const FormIconLabel = ({
  children,
  className,
  icon,
  iconAlignment,
  margin,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.marginNormal]: margin === 'normal',
          [classes.marginDense]: margin === 'dense',
        },
        className,
      )}
      {...other}
    >
      <div
        className={classNames(classes.iconWrapper, {
          [classes.iconFormFieldAlign]: iconAlignment === 'formField',
        })}
      >
        {icon}
      </div>
      {children}
    </div>
  );
};
FormIconLabel.propTypes = {
  children: PropTypes.node.isRequired,

  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  iconAlignment: PropTypes.oneOf(['none', 'formField']),
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
};

FormIconLabel.defaultProps = {
  className: '',
  iconAlignment: 'formField',
  margin: 'none',
};

export default FormIconLabel;
