import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';

// Creates a column of hour sections based on start and end
// Currently uses hardcoded military time

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    minWidth: 56,
    flexShrink: 0,
    textAlign: 'center',
  },
  cell: {
    boxSizing: 'border-box',
    '&:first-child $label': {
      display: 'none',
    },
  },
  label: {
    position: 'relative',
    top: -10, // half of typo body1 total height
  },
}));

const CalendarHoursColumn = ({
  cellHeight,
  className,
  end,
  start,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const hours = [];

  for (let i = start; i < end; i += 1) {
    hours.push(`${i}:00`);
  }

  return (
    <div className={classNames(classes.root, className)} {...other}>
      {hours.map(hour => (
        <div key={hour} className={classes.cell} style={{ height: cellHeight + 1 }}>
          <Typography className={classes.label}>{hour}</Typography>
        </div>
      ))}
    </div>
  );
};

CalendarHoursColumn.propTypes = {
  cellHeight: PropTypes.number,
  className: PropTypes.string,
  end: PropTypes.number,
  start: PropTypes.number,
};

CalendarHoursColumn.defaultProps = {
  cellHeight: 48,
  className: '',
  end: 24,
  start: 0,
};

export default CalendarHoursColumn;
