import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ButtonBase, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    borderRadius: 2,
    padding: '4px 8px',
  },
  label: {
    marginRight: 8,
    textTransform: 'uppercase',
    opacity: 0.7,
  },
  labelOn: {
    fontWeight: 500,
    opacity: 1,
  },
  accentOn: {
    color: theme.palette.secondary[300],
  },
}));

// For icons that do not have two state versions and require an extra on/off label

const LabeledIconToggle = ({
  accent,
  children,
  labelProps,
  labelOff,
  on,
  labelOn,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });

  return (
    <ButtonBase className={classes.root} {...other}>
      <Typography
        variant="caption"
        color="inherit"
        className={classNames(
          classes.label,
          on && classes.labelOn,
          on && accent && classes.accentOn,
        )}
        {...labelProps}
      >
        {on ? labelOn : labelOff}
      </Typography>
      {children}
    </ButtonBase>
  );
};
LabeledIconToggle.propTypes = {
  accent: PropTypes.bool,
  children: PropTypes.node.isRequired,
  labelProps: PropTypes.object,
  labelOff: PropTypes.string.isRequired,
  labelOn: PropTypes.string.isRequired,
  on: PropTypes.bool,
};

LabeledIconToggle.defaultProps = {
  accent: false,
  labelProps: {},
  on: false,
};
export default LabeledIconToggle;
