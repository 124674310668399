import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  alignLeft: {
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  alignCenter: {
    alignItems: 'center',
    alignSelf: 'center',
  },
  alignRight: {
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  },
}));
// Groups messages visually

/* 
  Configuration:

    - align: horizontal alignment of children
    - classes.root: flex column
    - component: root element, accepts a string for DOM node or a Component
*/

const ChatMessageGroup = ({
  align,
  children,
  className: classNameProp,
  component: componentProp,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.alignLeft]: align === 'left',
      [classes.alignCenter]: align === 'center',
      [classes.alignRight]: align === 'right',
    },
    classNameProp,
  );

  const Component = componentProp || 'div';

  return (
    <Component className={className} {...other}>
      {children}
    </Component>
  );
};

ChatMessageGroup.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,

  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

ChatMessageGroup.defaultProps = {
  align: 'left',
  className: '',
};

export default ChatMessageGroup;
