import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%',
    minWidth: 0,
    flexGrow: 1,
  },
}));

const MainView = ({ children, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classes.root} {...other}>
      {children}
    </div>
  );
};

MainView.propTypes = {
  children: PropTypes.node.isRequired,
};
export default MainView;
