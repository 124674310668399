import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Toolbar, IconButton, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.increments.mobile,
    // Special alignment for IconButtons:
    paddingLeft: 12,
    paddingRight: 12,
    justifyContent: 'space-between',
  },
  dateButton: {
    borderRadius: 2,
  },
  titleInput: {
    color: 'inherit',
    fontWeight: 500,
    letterSpacing: 1.2,
    margin: 0,
  },
  titleInput__native: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    root: {
      boxShadow: 'none',
    },
    toolbar: {
      height: theme.increments.tablet,
      // Special alignment for IconButtons:
      paddingRight: 12,
      paddingLeft: 12,
    },
  },
}));

const DateNav = ({
  dateLabel,
  onChange,
  onClickNext,
  nextDisabled,
  previousDisabled,
  onClickPrevious,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Toolbar className={classes.root}>
      <IconButton disabled={previousDisabled} color="inherit" onClick={onClickPrevious}>
        <ChevronLeftIcon />
      </IconButton>

      {onChange ? (
        <Button
          className={classes.dateButton}
          color="inherit"
          component={() => <DatePicker variant="inline" />}
          format="dd/MM/yyyy"
          onChange={onChange}
          value={dateLabel}
          InputProps={{
            className: classes.titleInput,
            disableUnderline: true,
            inputProps: {
              className: classes.titleInput__native,
            },
            readOnly: true,
          }}
        />
      ) : (
        <Typography variant="button" color="inherit" className={classes.dateLabel}>
          {dateLabel}
        </Typography>
      )}

      <IconButton disabled={nextDisabled} color="inherit" onClick={onClickNext}>
        <ChevronRightIcon />
      </IconButton>
    </Toolbar>
  );
};

DateNav.propTypes = {
  className: PropTypes.string,
  dateLabel: PropTypes.node.isRequired,
  nextDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func,
  previousDisabled: PropTypes.bool.isRequired,
};

DateNav.defaultProps = {
  className: '',
};

export default DateNav;
