// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ListItemIcon as MuiListItemIcon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // Reset gutter when it is the last icon in the ListItem:
    // (Not using last-child because TouchRipple will be the last child if ListItem is a button)
    // TODO: battle test this
    '&:last-of-type:not(:first-child)': {
      marginRight: 0,
      minWidth: 0,
    },
  },
  alignStart: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1),
  },
}));

// Simple wrapper to allow ListItemIcon to vertically align itself at the start
// when using inside a ListItem with three lines or custom content
//
// See https://github.com/mui-org/material-ui/issues/6098

const ListItemIcon = ({
  alignStart,
  children,
  className: classNameProp,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.alignStart]: alignStart,
    },
    classNameProp,
  );

  return (
    <MuiListItemIcon className={className} {...other}>
      {children}
    </MuiListItemIcon>
  );
};

ListItemIcon.propTypes = {
  alignStart: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

ListItemIcon.defaultProps = {
  alignStart: false,
  children: '',
  className: '',
};

export default ListItemIcon;
