import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
  },
  rootBox: {
    padding: '4px 8px',
    backgroundColor: 'rgba(255, 255, 255, .1)',
    borderRadius: 4,
    overflow: 'hidden',
  },
  rootBoxSelect: {
    paddingRight: 2,
  },
  squareRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  squareLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  underline: {
    '&:hover:not($disabled):before': {
      backgroundColor: theme.palette.common.white,
    },
    '&:before': {
      backgroundColor: 'rgba(255, 255, 255, .7)',
    },
    '&$disabled:before': {
      backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, .7) 33%, transparent 0%)',
    },
    '&:after': {
      backgroundColor: theme.palette.common.white,
    },
  },
  underlineBox: {
    '&$disabled:before': {
      display: 'none',
    },
  },
  disabled: {
    color: 'rgba(255, 255, 255, .5)',
  },
  focusedLabel: {
    color: theme.palette.primary[900],
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    color: theme.palette.common.white,
  },
}));

function LightTextField({
  box,
  InputProps = {},
  select,
  SelectProps = {},
  square,
  classes: eClasses,
  ...other
}) {
  const classes = useStyles({ classes: eClasses });
  const moreProps = {
    FormHelperTextProps: {},
    SelectProps: {
      classes: { select: classes.select, icon: classes.selectIcon },
      ...SelectProps,
    },
    InputProps: {
      classes: {
        root: classNames(
          classes.root,
          box && classes.rootBox,
          select && box && classes.rootBoxSelect,
          square === 'right' && classes.squareRight,
          square === 'left' && classes.squareLeft,
        ),
        underline: classNames(classes.underline, box && classes.underlineBox),
        disabled: classes.disabled,
      },
      ...InputProps,
    },
    ...other,
  };

  if (!moreProps.FormHelperTextProps.id && moreProps.id) {
    moreProps.FormHelperTextProps.id = `${other.id}Helper`;
  }

  return (
    <TextField
      InputLabelProps={{
        FormControlClasses: { focused: classes.focusedLabel },
      }}
      select={select}
      {...moreProps}
    />
  );
}

LightTextField.propTypes = {
  box: PropTypes.bool,
  InputProps: PropTypes.object,
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  square: PropTypes.oneOf(['none', 'right', 'left']),
};

LightTextField.defaultProps = {
  box: false,
  select: false,
  square: 'none',
};

export default LightTextField;
