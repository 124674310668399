import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import SearchField from './SearchField';

const ResponsiveSearchField = ({
  className: classNameProp,
  InputProps,
  onChange,
  onWidthChange,
  value,
  ...other
}) => {
  const mobileVersion = useMediaQuery('(max-width:600px)');
  const [fullWidth, setFullWidth] = useState(mobileVersion);

  const toggleWidth = () => {
    if (mobileVersion) {
      setFullWidth(!fullWidth);
      if (onWidthChange) {
        onWidthChange(fullWidth);
      }
    }
  };
  useEffect(toggleWidth, []);
  return (
    <SearchField
      className={classNameProp}
      value={value}
      onChange={onChange}
      fullWidth={mobileVersion ? fullWidth : true}
      InputProps={InputProps}
      toggleWidth={toggleWidth}
      mobileVersion={mobileVersion}
      {...other}
    />
  );
};

ResponsiveSearchField.propTypes = {
  className: PropTypes.string,
  InputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onWidthChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default ResponsiveSearchField;
