// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

export const Page = ({ value, isActive, onClick, ...props }) => (
  <Button
    color={isActive ? 'primary' : 'default'}
    onTouchTap={onClick}
    onClick={onClick}
    {...props}
  >
    {value.toString()}
  </Button>
);

Page.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.number,
};

export default Page;
