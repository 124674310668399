// @flow
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

// NOTE:
// This does not take into account adding content to the top yet.
// We can probably track events that will trigger updates to the top

class ScrollerController extends React.Component {
  componentDidMount() {
    this.shouldScrollBottom = true;
    this.performScrollToBottom();
  }

  componentWillUpdate(nextProps) {
    const node = this.scrollable;
    if (this.props.handle !== nextProps.handle) {
      this.shouldScrollBottom = true;
    } else {
      this.shouldScrollBottom = Math.floor(node.scrollTop) + node.clientHeight === node.scrollHeight;
    }
  }

  componentDidUpdate() {
    this.performScrollToBottom();
  }

  performScrollToBottom() {
    if (this.shouldScrollBottom) {
      const node = this.scrollable;
      node.scrollTop = node.scrollHeight;
    }
  }

  render() {
    const { children, classes, className } = this.props;
    return (
      <div
        className={classnames(classes.root, className)}
        ref={scrollable => {
          this.scrollable = scrollable;
        }}
      >
        {children}
      </div>
    );
  }
}

ScrollerController.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  handle: PropTypes.string,
};

ScrollerController.defaultProps = {
  className: null,
  handle: null,
};

const styles = {
  root: {
    height: '100%',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
};

export default withStyles(styles)(ScrollerController);
