import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ButtonBase } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.button,
    boxShadow: 'none !important',
    borderRadius: 0,
    width: '100%',
    height: '100%',
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.divider,
    },
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
}));

// TODO: implement disabled state

const FullBleedButton = ({ className, children, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <ButtonBase
      className={className}
      classes={{ root: classNames(classes.root, className), disabled: classes.disabled }}
      focusRipple
      {...other}
    >
      {children}
    </ButtonBase>
  );
};
FullBleedButton.propTypes = {
  children: PropTypes.node.isRequired,

  className: PropTypes.string,
};

FullBleedButton.defaultProps = {
  className: '',
};

// prettier-ignore
export default FullBleedButton;
