import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Slide,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { useWidth } from '../Hooks';

import Flex from '../Flex';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// Fullscreen dialog below `sm` breakpoint, small dialog above breakpoint
// TODO (future): make breakpoint configurable (not necessary now)

// TODO: replace className with classes

const phoneSpacing = 16;
const tabletSpacing = 24;

const useStyles = makeStyles(theme => ({
  phoneDialog: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transform: 'translate3D(0, 0, 0)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    position: 'relative',
    flexShrink: '0',
    color: theme.palette.common.white,
  },
  affirmativeAction: {
    color: theme.palette.common.white,
  },
  toolbar: {
    padding: '0 4px',
  },
  title: {
    // mobile only
    flexGrow: 1,
    marginLeft: 16,
    paddingRight: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dialogActions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dialogTitle: {
    // tablet and up
    backgroundColor: theme.palette.primary[500],
    '& > *': {
      color: theme.palette.common.white,
    },
  },
  mobileContent: {
    padding: phoneSpacing,
    overflowY: 'auto',
  },
  noPadding: {
    padding: 0,
  },
  mobileSpacer: {
    flexGrow: 1,
  },
  [theme.breakpoints.up('xs')]: {
    toolbar: {
      padding: `0 ${theme.spacing(1)}px`,
    },
    mobileContent: {
      padding: tabletSpacing,
    },
    noPadding: {
      padding: 0,
    },
  },
  [theme.breakpoints.up('sm')]: {
    toolbar: {
      padding: `0 ${theme.spacing(2)}px`,
    },
    dialogPaper: {
      background: 'transparent', // makes rounded corners look better with colored DialogTitle
      minWidth: theme.widths.creationDialog,
    },
    paper: {
      background: theme.palette.background.paper, // reinstate paper on rest of dialog
      overflowY: 'auto',
    },
    affirmativeAction: {
      color: theme.palette.primary[500],
    },
  },
}));

const ResponsiveDialog = ({
  actions,
  children,
  contentClassName,
  open,
  onDismiss,
  affirmativeAction,
  dismissiveActionText,
  title,
  noPadding,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const isWidthUpThreshold = isWidthUp('sm', width);

  const dialogInner = isWidthUpThreshold ? (
    <Flex flexDirection="column">
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent
        className={classNames(classes.paper, contentClassName, noPadding && classes.noPadding)}
      >
        {children}
      </DialogContent>
      <DialogActions className={classNames(classes.dialogActions, classes.paper)}>
        {actions || (
          <Fragment>
            <Button onClick={onDismiss}>{dismissiveActionText}</Button>
            {affirmativeAction && (
              <div className={classes.affirmativeAction}>{affirmativeAction}</div>
            )}
          </Fragment>
        )}
      </DialogActions>
    </Flex>
  ) : (
    <div className={classes.phoneDialog}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton color="inherit" onClick={onDismiss}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classNames(classes.mobileContent, noPadding && classes.noPadding)}>
        {children}
      </div>
      <div className={classes.mobileSpacer} />
      <DialogActions className={classNames(classes.dialogActions, classes.paper)}>
        {actions || (
          <Fragment>
            <Button onClick={onDismiss}>{dismissiveActionText}</Button>
            {affirmativeAction && (
              <div className={classes.affirmativeAction}>{affirmativeAction}</div>
            )}
          </Fragment>
        )}
      </DialogActions>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={onDismiss}
      {...(isWidthUpThreshold ? {} : { TransitionComponent: Transition })}
      fullScreen={!isWidthUpThreshold}
      scroll="body"
      classes={{ paper: classes.dialogPaper }}
      {...other}
    >
      {dialogInner}
    </Dialog>
  );
};

ResponsiveDialog.propTypes = {
  actions: PropTypes.node,
  affirmativeAction: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
  dismissiveActionText: PropTypes.string.isRequired,
  noPadding: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

ResponsiveDialog.defaultProps = {
  contentClassName: '',
  noPadding: false,
  open: false,
};

export default ResponsiveDialog;
