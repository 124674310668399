// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { List, ListSubheader } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [theme.breakpoints.up('sm')]: {
    subheader: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
}));

const NavItemGroup = ({ children, className, subheader, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <List
      className={classNames(classes.root, className)}
      subheader={
        subheader && (
          <ListSubheader disableSticky className={classes.subheader}>
            {subheader}
          </ListSubheader>
        )
      }
      {...other}
    >
      {children}
    </List>
  );
};

NavItemGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  subheader: PropTypes.string,
};

NavItemGroup.defaultProps = {
  className: '',
  subheader: '',
};

// prettier-ignore
export default NavItemGroup;
