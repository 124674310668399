import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SwitchBaseButton from './SwitchBaseButton';

// TODO: add ellipsis support + tooltips if too small?

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1 0 auto',
    borderBottom: `2px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
  },
  checked: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    pointerEvents: 'none',
  },
  disabled: {},
  colorPrimary: {
    '&$checked': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
      borderColor: theme.palette.action.disabled,
    },
  },
  colorSecondary: {
    '&$checked': {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
      borderColor: theme.palette.action.disabled,
    },
  },
  label: {
    lineHeight: 1,
  },
}));

const RadioButton = ({ color, label, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <SwitchBaseButton
      type="radio"
      label={label}
      classes={{
        root: classNames(
          classes.root,
          classes[`color${color.charAt(0).toUpperCase() + color.slice(1)}`],
        ),
        checked: classes.checked,
        disabled: classes.disabled,
        label: classes.label,
      }}
      {...other}
    />
  );
};

/* eslint-disable */
RadioButton.propTypes = {
  /**
   * If `true`, the component appears selected.
   */
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * The CSS class name of the root element when selected.
   */
  checkedClassName: PropTypes.string,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  /**
   * If `true`, the component disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The CSS class name of the root element when disabled.
   */
  disabledClassName: PropTypes.string,
  /**
   * If `true`, the ripple effect will be disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * Properties applied to the `input` element.
   */
  inputProps: PropTypes.object,
  /**
   * Use that property to pass a ref callback to the native input component.
   */
  inputRef: PropTypes.func,
  /**
   * The label to display inside the button
   */
  label: PropTypes.node.isRequired,
  /*
   * @ignore
   */
  name: PropTypes.string,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event `change` event
   * @param {boolean} checked The `checked` value of the switch
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  tabIndex: PropTypes.string,
  /**
   * The value of the component.
   */
  value: PropTypes.string,
};

RadioButton.defaultProps = {
  color: 'secondary',
};

export default RadioButton;
