const window = global;
const defaultImgOptions = {
  width: 1920,
  height: 1080,
  transform: 'fit',
  dpr: (window && window.devicePixelRatio) || 2,
};

const weserv = {
  host: 'images.weserv.nl',
};

export const img = (url, options = {}) => {
  if (!url) {
    return null;
  }
  const o = {
    ...defaultImgOptions,
    ...options,
  };

  const { width, height, transform, dpr, crop } = o;

  let finalUrl = url;

  if (crop) {
    finalUrl = encodeURIComponent(`${weserv.host}/?url=${url}&crop=${crop}`);
  }

  return `${weserv.host}/?url=${finalUrl}${width ? `&w=${width}` : ''}${
    height ? `&h=${height}` : ''
  }&t=${transform}&dpr=${dpr}`;
};

export const getImageUrl = fileImg =>
  img(fileImg.url, {
    crop:
      fileImg.data && fileImg.data.width
        ? `${fileImg.data.width},${fileImg.data.height},${fileImg.data.x},${fileImg.data.y}`
        : null,
  });
