// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { AppBar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.heights.toolbarTopMobile + theme.heights.toolbarBottomMobile,
    boxShadow: 'none',
    zIndex: 0,
  },
  [theme.breakpoints.up('sm')]: {
    root: {
      height: theme.heights.toolbarTop + theme.heights.toolbarBottom,
    },
  },
}));

const ShimAppBar = ({ className, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <AppBar className={classNames(classes.root, className)} {...other}>
      <div />
    </AppBar>
  );
};

ShimAppBar.propTypes = {
  className: PropTypes.string,
};

ShimAppBar.defaultProps = {
  className: '',
};

export default ShimAppBar;
