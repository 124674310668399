// @flow
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { menuButton, coverButton } from '../styling';

const useStyles = makeStyles(theme => ({
  root: {
    ...menuButton(theme),
    ...coverButton(theme),
  },
  // someState: {},
}));

const DetailViewCloseButton = ({ className: classNameProp, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      // [classes.someState]: prop_expression_here,
    },
    classNameProp,
  );

  return (
    <IconButton className={className} {...other}>
      <CloseIcon />
    </IconButton>
  );
};

DetailViewCloseButton.propTypes = {
  className: PropTypes.string,
};

DetailViewCloseButton.defaultProps = {
  className: '',
};

export default DetailViewCloseButton;
