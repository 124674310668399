import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'hidden',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'hidden',
  },
}));

const TimelineBody = ({
  children,
  currentUnit,
  height,
  onUpdate,
  scrollbarsRef,
  scrollLeft,
  timeline,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  const [isScrolling, setIsScrolling] = useState(false);

  const onScroll = _ => {
    if (isScrolling) {
      onUpdate(scrollbarsRef.current.getScrollLeft());
    }
  };

  const scrollToLeft = scrollLeftNew => {
    scrollbarsRef.current.scrollLeft(scrollLeftNew);
  };

  const scrollToUnit = index => {
    const left = (index - 2) * Math.floor(timeline.columnWidth);
    scrollToLeft(left);
  };

  const scrollToDate = date => {
    const diff = timeline.differenceFunction(date, timeline.start);
    scrollToUnit(diff);
  };

  useEffect(() => {
    scrollToLeft(scrollLeft);
  }, [scrollLeft]);

  useEffect(() => {
    scrollToDate(currentUnit);
  }, [currentUnit]);

  return (
    <Scrollbars
      autoHeight
      autoHeightMax="initial"
      className={classes.root}
      onScrollStart={() => {
        setIsScrolling(true);
      }}
      onScrollStop={() => {
        onScroll();
        setIsScrolling(false);
      }}
      onScroll={onScroll}
      ref={scrollbarsRef}
      renderView={props => <div {...props} className={classes.content} />}
    >
      {children}
    </Scrollbars>
  );
};

TimelineBody.propTypes = {
  children: PropTypes.node,
  currentUnit: PropTypes.any,
  height: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  scrollbarsRef: PropTypes.instanceOf(Scrollbars).isRequired,
  scrollLeft: PropTypes.number,
  timeline: PropTypes.object.isRequired,
};

TimelineBody.defaultProps = {
  className: '',
  height: 64,
  scrollLeft: 0,
};

export default TimelineBody;
