import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Draggable } from 'react-beautiful-dnd';

// A slot to put an item on a Board Lane

const useStyles = makeStyles(() => ({
  root: {
    margin: 8,
    '& > *': {
      cursor: 'pointer',
      '&:hover': {
        marginLeft: 4,
        marginRight: -4,
      },
    },
  },
  dragging: {},
}));

const BoardSlot = ({ children, index, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Draggable key={index} draggableId={index} index={index}>
      {(dragProvided, dragSnapshot) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
          className={classNames(classes.root, dragSnapshot.isDragging && classes.dragging)}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

BoardSlot.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  index: PropTypes.number,
};

BoardSlot.defaultProps = {
  children: '',
  className: '',
};

export default BoardSlot;
