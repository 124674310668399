import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import Flex from '../Flex';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 10,
    marginLeft: 8,
  },
  inline: {
    display: 'inline',
  },
  icon: {
    display: 'block',
    width: 20,
    height: 20,
    color: theme.palette.icon,
    marginRight: 18,
    '& > *': {
      width: 20,
      height: 20,
    },
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary[500],
    },
  },
  timestamp: {
    display: 'inline-block',
    color: theme.palette.text.hint,
    cursor: 'default',
  },
  bold: {
    fontWeight: 500,
  },
}));

const SystemMessage = ({
  message,
  timestampString,
  icon,
  actionLabel,
  onActionClick,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Flex alignItems="flex-start" className={classes.root}>
      <span className={classes.icon}>{icon}</span>
      <span>
        <Typography variant="caption" className={classes.inline}>
          {message}&nbsp;
        </Typography>
        {actionLabel && (
          <Typography
            variant="caption"
            className={classNames(classes.inline, classes.link, classes.bold)}
            onClick={onActionClick}
          >
            {actionLabel}&nbsp;
          </Typography>
        )}
        <Typography
          variant="caption"
          className={classNames(classes.inline, classes.timestamp)}
          title={timestampString}
        >
          {timestampString}
        </Typography>
      </span>
    </Flex>
  );
};

SystemMessage.propTypes = {
  actionLabel: PropTypes.string,

  message: PropTypes.string,
  onActionClick: PropTypes.func,
  timestampString: PropTypes.string,
  icon: PropTypes.node,
};

SystemMessage.defaultProps = {
  allowUndo: false,
  message: '',
  actionLabel: null,
  onActionClick: () => null,
  icon: null,
  timestampString: new Date(),
};

export default SystemMessage;
