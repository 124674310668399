// @flow
import React from 'react';

import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import TimeslotDragPreview from './TimeslotDragPreview';
import { getMovedPixels, getDragHandlePosition } from './dnd-helpers';

const layerStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 5,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

// This function positions / moves the rendered preview.
function getPositionStyles(initialNodeOffset, dragHandle, movedPixels, item, containerNode) {
  if (!movedPixels) {
    return {
      display: 'none',
    };
  }

  const { y: initialY } = initialNodeOffset;
  const { top } = containerNode.getBoundingClientRect();
  const scrollTop = containerNode.scrollTop;

  let newX = item.startPosition;
  if (dragHandle !== 'right') {
    newX += movedPixels;
  }

  const transform = `translate(${newX}px, ${initialY - top + scrollTop}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

// This function renders the item (and handles resize)
function renderItem(item, dragHandle, movedPixels) {
  let width = item.size;

  if (dragHandle === 'right') {
    width += movedPixels;
  } else if (dragHandle === 'left') {
    width -= movedPixels;
  }

  return (
    <TimeslotDragPreview width={width} dragHandle={dragHandle} className={item.previewClassName}>
      {item.children}
    </TimeslotDragPreview>
  );
}

class TimeslotDragLayer extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (!this.props.isDragging && nextProps.isDragging) {
      this.containerNode = this.props.getContainerNode();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isDragging !== nextProps.isDragging) {
      this.lastDiffX = 0;
      return true;
    }

    if (this.props.offsetDifference !== nextProps.offsetDifference) {
      const { x } = nextProps.offsetDifference;
      if (Math.abs(x - this.lastDiffX) >= 1) {
        this.lastDiffX = x;

        return true;
      }
    }
    return false;
  }

  render() {
    const {
      item,
      isDragging,
      offsetDifference,
      initialNodeOffset,
      position,
      initialPointerOffset,
    } = this.props;
    if (!isDragging) {
      return null;
    }

    const dragHandle = getDragHandlePosition(item.size, initialNodeOffset, initialPointerOffset);
    let movedPixels = 0;
    if (offsetDifference) {
      movedPixels = getMovedPixels(offsetDifference, item.timeline.scale, dragHandle, item.size);
    }

    const { left } = position;
    return (
      <div style={{ ...layerStyles, left, height: this.containerNode.scrollHeight }}>
        <div
          style={getPositionStyles(
            initialNodeOffset,
            dragHandle,
            movedPixels,
            item,
            this.containerNode,
          )}
        >
          {renderItem(item, dragHandle, movedPixels)}
        </div>
      </div>
    );
  }
}

TimeslotDragLayer.propTypes = {
  getContainerNode: PropTypes.func.isRequired,
  initialNodeOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  initialPointerOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object,
  offsetDifference: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  position: PropTypes.object.isRequired,
};

export default DragLayer(monitor => ({
  item: monitor.getItem(),
  offsetDifference: monitor.getDifferenceFromInitialOffset(),
  initialNodeOffset: monitor.getInitialSourceClientOffset(),
  initialPointerOffset: monitor.getInitialClientOffset(),
  isDragging: monitor.isDragging(),
}))(TimeslotDragLayer);
