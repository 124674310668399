import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Avatar as MuiAvatar, ListItemAvatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  alignStart: {
    alignSelf: 'flex-start',
    marginTop: 8, // not using theme as MUI has no spacing unit for typography
  },
}));

// Simple wrapper to allow Avatar to vertically align itself at the start
// when using inside a ListItem with three lines or custom content
//
// See https://github.com/mui-org/material-ui/issues/6098

const Avatar = ({
  alignStart,
  children,
  className: classNameProp,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.alignStart]: alignStart,
    },
    classNameProp,
  );

  return (
    <ListItemAvatar>
      <MuiAvatar className={className} {...other}>
        {children}
      </MuiAvatar>
    </ListItemAvatar>
  );
};

Avatar.propTypes = {
  alignStart: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  alignStart: false,
  children: '',
  className: '',
};

export default Avatar;
