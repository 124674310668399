// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ListItemSecondaryAction as MuiListItemSecondaryAction } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: {
    root: {
      right: 12,
    },
  },
}));

// Simple wrapper to keep ListItemSecondaryAction position in sync with gutter mixin
// that ListItem uses for its keylining
//
// See https://github.com/mui-org/material-ui/issues/6098

const ListItemSecondaryAction = ({
  children,
  className: classNameProp,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      // [classes.someState]: prop_expression_here,
    },
    classNameProp,
  );

  return (
    <MuiListItemSecondaryAction className={className} {...other}>
      {children}
    </MuiListItemSecondaryAction>
  );
};

ListItemSecondaryAction.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ListItemSecondaryAction.defaultProps = {
  children: '',
  className: '',
};

export default ListItemSecondaryAction;
