import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';
import Fuse from 'fuse.js';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { Input, InputLabel } from '@material-ui/core';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  Avatar,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  chipContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 8,
    marginBottom: 2,
  },
  chip: {
    flexShrink: 0,
    margin: '0 8px 8px 0',
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&$disabled': {
      borderBottomStyle: 'dotted',
    },
  },
  inkbar: {
    '&:after': {
      backgroundColor: theme.palette.primary.A200,
      left: 0,
      bottom: -2,
      // Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      height: 2,
      position: 'absolute',
      right: 0,
      transform: 'scaleX(0)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
    },
    '&$focused:after': {
      transform: 'scaleX(1)',
    },
  },
  focused: {},
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
  contactList: {
    // Set max height to:
    // SelectionContainer Toolbar with border height
    // + List top and bottom padding
    // + ListItem height * items to show
    maxHeight: 57 + 16 + 56 * 3,
    position: 'relative',
    top: 0,
    zIndex: 2,
    width: '100%',
    overflow: 'hidden', // preserve border radius
    display: 'flex',
    flexDirection: 'column',
  },
  innerList: {
    padding: 0,
  },
  activeListItem: {
    background: theme.palette.divider,
  },
  input: {
    width: 100,
    flexGrow: 1,
  },
  emptyStateLabel: {
    padding: '8px 16px',
  },
}));

const focusButton = item => {
  const button =
    item && item._reactInternalInstance && item._reactInternalInstance._renderedComponent;
  if (button && button._instance) {
    button._instance.focus();
  }
};

const ContactSelect = ({
  avatarFn,
  contacts,
  disableUnderline,
  disabled,
  labelFn,
  label,
  labelClassName,
  maxResults,
  onChange,
  required, // shows an asterisk after the label
  secondaryFn,
  searchKeys,
  single,
  classes: eClasses,
  // disabledReasonText,
  // error,
}) => {
  const classes = useStyles({ classes: eClasses });
  const [input, setInput] = useState('');
  const [chips, setChips] = useState([]);
  const [focused, setFocused] = useState(false);
  const [focusedContact, setFocusedContact] = useState(-1);
  const [searchHandle, setSearchHandle] = useState(
    new Fuse(contacts, {
      keys: searchKeys,
    }),
  );

  useEffect(() => {
    if (contacts || !searchHandle) {
      setSearchHandle(new Fuse(contacts, { keys: searchKeys }));
    }
  }, [contacts]);

  const updateInput = input => {
    setInput(input);
    if (input.length < 1) {
      setFocusedContact(-1);
    }
  };

  const updateChips = chips => {
    setChips(chips);
    setFocused(true);
    if (onChange) {
      onChange(chips);
    }
  };

  const addChip = contactId => {
    const newChips = [contacts.find(contact => contact._id === contactId)];

    updateChips(single ? newChips : [...chips, ...newChips]);
    updateInput('');
  };

  const deleteChip = contactId => {
    const clonedChips = [...chips];
    if (contactId) {
      const index = chips.findIndex(contact => contact._id === contactId);
      clonedChips.splice(index, 1);
    } else {
      clonedChips.pop();
    }
    updateChips(clonedChips);
  };

  const handleFocus = () => {
    setFocused(true);
    setFocusedContact(-1);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleInputChange = event => {
    const { value } = event.currentTarget;
    if (input !== value) {
      setInput(value);
    }
  };

  const handleDelete = contactId => {
    return () => {
      deleteChip(contactId);
    };
  };

  const handleAddContact = event => {
    const { contactId } = event.currentTarget.dataset;
    addChip(contactId);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 8 && input.length === 0) {
      deleteChip();
    } else if (event.keyCode === 40) {
      setFocusedContact(
        focusedContact < contacts.length - 1 ? focusedContact + 1 : contacts.length - 1,
      );
    } else if (event.keyCode === 38) {
      setFocusedContact(focusedContact > 0 ? focusedContact - 1 : -1);
      setFocused(focusedContact <= 0);
    } else if (event.keyCode === 27) {
      setInput('');
    }
  };

  const searchResults = R.differenceWith(
    (a, b) => a._id === b._id,
    searchHandle.search(input),
    chips,
  );

  return (
    <div className={classes.root} role="menu" onKeyDown={handleKeyDown}>
      {label && (
        <InputLabel className={labelClassName}>
          {label} {required && '*'}
        </InputLabel>
      )}
      <div
        className={classNames(classes.chipContainer, {
          [classes.focused]: focused,
          [classes.inkbar]: !disableUnderline,
          [classes.underline]: !disableUnderline,
          [classes.disabled]: disabled,
        })}
      >
        {chips.map(contact => (
          <Chip
            key={contact._id}
            avatar={
              <Avatar src={avatarFn && avatarFn(contact)}>{labelFn(contact).substr(0, 1)}</Avatar>
            }
            label={`${labelFn(contact)}`}
            className={classes.chip}
            onRequestDelete={handleDelete(contact._id)}
          />
        ))}
        <Input
          className={classes.input}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          value={input}
          disabled={disabled}
          disableUnderline
          inputRef={inputEl => focused && inputEl && inputEl.focus()}
        />
      </div>
      {input.length > 0 && (
        <Paper className={classes.contactList} elevation={4}>
          <List className={classes.innerList}>
            {searchResults.length > 0 ? (
              searchResults.slice(0, maxResults).map((contact, index) => (
                <ListItem
                  key={contact._id}
                  data-contact-id={contact._id}
                  button
                  onClick={handleAddContact}
                  ref={item => index === focusedContact && focusButton(item)}
                >
                  <ListItemAvatar>
                    <Avatar src={avatarFn && avatarFn(contact)}>
                      {labelFn(contact).substr(0, 1)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={labelFn(contact)}
                    secondary={secondaryFn && secondaryFn(contact)}
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant="body1" className={classes.emptyStateLabel}>
                {'No contacts found'}
              </Typography>
            )}
          </List>
        </Paper>
      )}
    </div>
  );
};

ContactSelect.propTypes = {
  avatarFn: PropTypes.func,
  contacts: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelFn: PropTypes.func,
  maxResults: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  searchKeys: PropTypes.array,
  secondaryFn: PropTypes.func,
  single: PropTypes.bool,
  // error: PropTypes.string,
  // disabledReasonText: PropTypes.string,
};

ContactSelect.defaultProps = {
  single: false,
  searchKeys: ['name'],
  labelFn: contact => contact.name,
  avatarFn: contact => contact.avatarUrl,
  secondaryFn: contact => contact.role,
  onChange: null,
  label: '',
  labelClassName: '',
  maxResults: 10,
  required: false,
  disabled: false,
  disableUnderline: false,
  // error: '',
  // disabledReasonText: '',
};
export default ContactSelect;
