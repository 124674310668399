import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import ScoreItem from './ScoreItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // borderBottom: `solid 1px ${theme.palette.divider}`,
  },
}));

const ScoringRemainder = ({ totalPoints, pointsLeft, invert, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const value = invert ? totalPoints - pointsLeft : pointsLeft;
  return (
    <Paper elevation={2} className={classes.root}>
      <ScoreItem value={value} max={totalPoints} {...other} />
    </Paper>
  );
};

ScoringRemainder.propTypes = {
  invert: PropTypes.bool,
  pointsLeft: PropTypes.number,
  totalPoints: PropTypes.number.isRequired,
};

ScoringRemainder.defaultProps = {
  invert: false,
  pointsLeft: 0,
};

export default ScoringRemainder;
