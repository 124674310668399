// @flow

export function isOfTypeImage(type) {
  return (
    type === 'image/jpeg' ||
    type === 'image/png' ||
    type.indexOf('.jpg') > -1 ||
    type.indexOf('.jpg') > -1 ||
    type.indexOf('.jpeg') > -1 ||
    type.indexOf('.png') > -1 ||
    type.indexOf('.gif') > -1
  );
}

export function getResizedImage(url, query) {
  const newUrl = url.replace(/https?:\/\//i, '');

  return `//images.weserv.nl/?url=${newUrl}&${query}`;
}
