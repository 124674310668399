import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import LightTextField from '../LightTextField/LightTextField';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.increments.tablet * 6,
    marginRight: 8,
    marginLeft: 8,
  },
  mobileSearchField: {
    width: '100%',
  },
  searchIcon: {
    float: 'right',
  },
}));

const SearchField = ({
  className: classNameProp,
  value,
  fullWidth,
  InputProps,
  onChange,
  mobileVersion,
  placeholder,
  toggleWidth,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    classNameProp,
    mobileVersion ? classes.mobileSearchField : null,
  );
  return (
    <Fragment>
      {fullWidth ? (
        <LightTextField
          fullWidth
          box
          placeholder={placeholder || 'zoeken...'}
          value={value}
          onChange={event => onChange(event.target.value)}
          className={className}
          InputProps={{
            ...InputProps,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start" onClick={toggleWidth}>
                <SearchIcon color="inherit" />
              </InputAdornment>
            ),
            ...(value.length > 0
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton color="inherit" onClick={() => onChange('')}>
                        <CloseIcon color="inherit" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
          {...other}
        />
      ) : (
        <IconButton color="inherit" onClick={toggleWidth} classes={{ root: classes.searchIcon }}>
          <SearchIcon color="inherit" />
        </IconButton>
      )}{' '}
    </Fragment>
  );
};

SearchField.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  InputProps: PropTypes.object,
  mobileVersion: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  toggleWidth: PropTypes.func,
  value: PropTypes.string,
};

SearchField.defaultProps = {
  className: '',
  value: '',
};

export default SearchField;
