// @flow
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import branch from 'recompose/branch';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

import TimeslotDragLayer from './TimeslotDragLayer';

import TimelineMarkings from './TimelineMarkings';
// NOTE: TimeslotDragLayer position negative top offset
// We do this because we do not know the Y coordinate of our items
// inside the parent. Since the mouse events return the position
// within the window (not within a certain element).
// When doing Y operations this might become a problem.

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  scroll: {
    position: 'relative',
    height: 'calc(100% - 58px)',

    // flexGrow: 1,
    // overflowY: 'auto',
    // '-webkit-overflow-scrolling': 'touch',
    // overflowX: 'hidden',
  },
  filterContainer: {
    width: theme.widths.schedulingSideColumn || schedulingSideColumn,
    height: timelineHeaderHeight,
    flexShrink: 0,
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
const position = { left: 320 };
// TODO: position is hardwired

const Timeline = ({ className, timeline, children, showHeader, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  const scrollNode = useRef(null);

  const getContainerNode = () => scrollNode;

  return (
    <div className={classNames(classes.root, className)}>
      {showHeader && (
        <TimelineMarkings
          start={timeline.start.hour()}
          end={timeline.end.hour()}
          scale={timeline.scale}
        />
      )}
      <div className={classes.overlay}>
        <div className={classes.scroll} ref={scrollNode}>
          <TimeslotDragLayer getContainerNode={getContainerNode} position={position} />
          {children}
        </div>
      </div>
    </div>
  );
};

Timeline.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
  timeline: PropTypes.object.isRequired,
  vacationDays: PropTypes.array,
  weeklyVacationDays: PropTypes.array,
};

Timeline.defaultProps = {
  className: '',
  showHeader: false,
  vacationDays: [],
  weeklyVacationDays: [],
  filter: '',
};

const timelineHeaderHeight = 56;
const schedulingSideColumn = 320;

// prettier-ignore

const isBrowser = typeof window !== `undefined`;

const isSafari =
  isBrowser &&
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  !navigator.userAgent.match('CriOS');
export default compose(
  branch(
    () => isSafari,
    DragDropContext(HTML5Backend),
    DragDropContext(TouchBackend({ enableMouseEvents: true })),
  ),
)(Timeline);
