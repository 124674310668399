import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      position: 'absolute',
      zIndex: 0,
    },
  };
});

const Scrim = ({ cover = false, className, classes: eClasses, ...rest }) => {
  const classes = useStyles({ classes: eClasses });

  const background = {
    backgroundImage: `linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%),
      url(${cover}
    )`,
  };

  return <div className={classNames(classes.root, className)} style={background} {...rest} />;
};

Scrim.propTypes = {
  cover: PropTypes.string,
};

Scrim.defaultProps = {
  cover: false,
};

export default Scrim;
