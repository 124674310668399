// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    flex: '1 1 auto',
    minWidth: 0,
    padding: `${theme.spacing(1)}px 0px`,
  },
  inset: {
    '&:first-child': {
      paddingLeft: theme.spacing(7),
    },
  },
  dense: {
    fontSize: theme.typography.pxToRem(13),
  },
  primary: {
    lineHeight: '1rem', // TODO: use pxToRem for clarity
    '&$textDense': {
      fontSize: 'inherit',
    },
  },
  primaryTwoLines: {
    paddingTop: 2,
    paddingBottom: 4,
  },
  primaryThreeLines: {
    paddingBottom: 4,
  },
  secondary: {
    lineHeight: '1.125rem', // TODO: use pxToRem for clarity
    '&$textDense': {
      fontSize: 'inherit',
    },
  },
  textDense: {},
}));

// Custom implementation of MUI ListItemText to fix vertical rhythm
// and add two/three-line support

function ListItemText(props, context) {
  const {
    children,
    className: classNameProp,
    disableTypography,
    inset,
    primary: primaryProp,
    secondary: secondaryProp,
    lines,
    classes: eClasses,
    ...other
  } = props;
  const classes = useStyles({ classes: eClasses });
  const { dense } = context;

  let primary = primaryProp || children;
  if (primary && !disableTypography) {
    primary = (
      <Typography
        variant="body1"
        component="span"
        display="block"
        noWrap
        className={classNames(classes.primary, {
          [classes.primaryTwoLines]: lines === 'two',
          [classes.primaryThreeLines]: lines === 'three',
          [classes.textDense]: dense,
        })}
      >
        {primary}
      </Typography>
    );
  }

  let secondary = secondaryProp;
  if (secondary && !disableTypography) {
    secondary = (
      <Typography
        variant="body2"
        component="span"
        display="block"
        className={classNames(classes.secondary, {
          [classes.textDense]: dense,
        })}
        color="textSecondary"
      >
        {secondary}
      </Typography>
    );
  }

  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.dense]: dense,
          [classes.inset]: inset,
        },
        classNameProp,
      )}
      {...other}
    >
      {primary}
      {secondary}
    </div>
  );
}

ListItemText.propTypes = {
  /**
   * Alias for the `primary` property.
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * If `true`, the children won't be wrapped by a typography component.
   * For instance, that can be useful to can render an h4 instead of a
   */
  disableTypography: PropTypes.bool,
  /**
   * If `true`, the children will be indented.
   * This should be used if there is no left avatar or left icon.
   */
  inset: PropTypes.bool,
  /**
   * Using terminology from Material Design guidelines instead of numbers.
   * TODO: check old MUI implementation and use that instead? (more familiar)
   */
  lines: PropTypes.oneOf(['single', 'two', 'three']),
  primary: PropTypes.node,
  secondary: PropTypes.node,
};

ListItemText.defaultProps = {
  disableTypography: false,
  inset: false,
  lines: 'single',
};

ListItemText.contextTypes = {
  dense: PropTypes.bool,
};

export default ListItemText;
