import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.caption,
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    margin: `${theme.increments.typography * 3}px ${theme.increments.typography * 4}px`,
  },
}));

const ChatDivider = ({ children, className, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(classes.root, className)} {...other}>
      {children}
    </div>
  );
};

ChatDivider.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ChatDivider.defaultProps = {
  children: null,
  className: '',
};

export default ChatDivider;
