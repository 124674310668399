import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { RadioGroup } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    '& > *': {
      borderRadius: 0,
      '&:first-child': {
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
      },
      '&:last-child': {
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
      },
    },
  },
}));
const RadioButtonGroup = ({ children, className, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <RadioGroup className={classNames(classes.root, className)} {...other}>
      {children}
    </RadioGroup>
  );
};

RadioButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,

  className: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  className: '',
};

export default RadioButtonGroup;
