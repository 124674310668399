import React, { useEffect } from 'react';

import { Buffer } from 'buffer';

/** ****************************************************************************
 * Component
 **************************************************************************** */
const Camera = ({ onChange }) => {
  useEffect(() => {
    async function onSuccess(cameraData) {
      const base64Data = Buffer.from(cameraData.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      onChange(base64Data);
    }

    function onFail(message) {
      alert(`Failed because: ${message}`);
    }

    navigator.camera.getPicture(onSuccess, onFail, {
      destinationType: 0,
      quality: 25,
      correctOrientation: true,
    });
  }, []);

  return <div>Loading</div>;
};

export default Camera;
