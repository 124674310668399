import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Typography } from '@material-ui/core';

import Flex from '../Flex';
import ResponsiveAppBar from '../AppBar/ResponsiveAppBar';
import ScrollableContent from '../ScrollableContent';
import ResponsiveSheet from '../ResponsiveSheet';

const useStyles = makeStyles(theme => ({
  root: {},
  componentStack: {
    fontSize: '0.5em',
  },
  button: {
    marginTop: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
  },
}));

const ErrorPage = ({
  onGoBack,
  onClearCache,
  errorPrefix,
  error,
  info,
  title,
  labelGoBack,
  labelClearCache,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });

  return (
    <Flex fullColumn className={classes.root} {...other}>
      <ResponsiveAppBar title={title} disableShadow />
      <ScrollableContent classes={{ content: classes.content }}>
        <ResponsiveSheet classes={{ root: classes.sheet }}>
          <Typography variant="body1" noWrap>
            {errorPrefix}
          </Typography>
          <Typography variant="caption">{error}</Typography>
          <pre className={classes.componentStack}>{info}</pre>
          <Button
            onClick={onGoBack}
            title={labelGoBack}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            {labelGoBack}
          </Button>
          <Button
            onClick={onClearCache}
            title={labelClearCache}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            {labelClearCache}
          </Button>
        </ResponsiveSheet>
      </ScrollableContent>
    </Flex>
  );
};

ErrorPage.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.string.isRequired,
  errorPrefix: PropTypes.string,
  info: PropTypes.string.isRequired,
  labelClearCache: PropTypes.string,
  labelGoBack: PropTypes.string,
  onClearCache: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ErrorPage.defaultProps = {
  labelGoBack: 'Terug',
  labelClearCache: 'Clear cache',
  errorPrefix: 'Error:',
  title: 'Something went wrong...',
};

export default ErrorPage;
