import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'hidden',
    pointerEvents: 'none',
    height: 64,
  },
  padded: {
    position: 'relative',
    flexGrow: 1,
  },
}));

const TimelineRowBody = ({ className, children, gutter, classes: eClasses, ...props }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(className, classes.root)} style={{ padding: `${gutter}px 0` }}>
      <div
        className={classes.padded}
        // style={{ height: height - gutter * 2 }}
        {...props}
      >
        {children}
      </div>
    </div>
  );
};

TimelineRowBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gutter: PropTypes.number,
};

TimelineRowBody.defaultProps = {
  className: '',
  // height: 64,
  gutter: 8,
};

export default TimelineRowBody;
