import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    '&:before, &:after': {
      display: 'block',
      content: '""',
      height: 1,
      flexGrow: 1,
      backgroundColor: theme.palette.divider,
    },
  },
  text: {
    padding: '0 8px',
  },
}));

const MessageDivider = ({ text, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Typography variant="caption" className={classes.root}>
      <span className={classes.text}>{text}</span>
    </Typography>
  );
};

MessageDivider.propTypes = {
  text: PropTypes.string.isRequired,
};

export default MessageDivider;
