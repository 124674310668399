// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0, // preserve noWrap of children
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  gutterLeft: {
    paddingLeft: theme.spacing(2),
  },
  gutterRight: {
    paddingRight: theme.spacing(2),
  },
  someState: {},
}));

const ListItemContent = ({
  children,
  className: classNameProp,
  gutter,
  row, // use if you want more columns inside the ListItem, vertically aligned at the start
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.row]: row,
      [classes.gutterLeft]: gutter === 'left' || gutter === 'both',
      [classes.gutterRight]: gutter === 'right' || gutter === 'both',
    },
    classNameProp,
  );

  return (
    <div className={className} {...other}>
      {children}
    </div>
  );
};

ListItemContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gutter: PropTypes.oneOf(['none', 'left', 'right', 'both']),
  row: PropTypes.bool,
};

ListItemContent.defaultProps = {
  className: '',
  gutter: 'none',
  row: false,
};

export default ListItemContent;
