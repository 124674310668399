import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// Styling wrapper for typography and color of a text message

// TODO: tooltap (and tooltip):
//
//        - reveal datetimestamp above
//        - reveal message status below
//        - reveal transition: use Collapse?

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.subheading,
    display: 'block',
    padding: `${theme.increments.typography}px ${theme.increments.typography * 3}px`,
    backgroundColor: theme.palette.background.default,
    whiteSpace: 'pre-wrap',
  },
  colorInherit: {
    color: 'inherit',
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
}));
const ChatText = ({
  children,
  className: classNameProp,
  color,
  component: componentProp,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.colorInherit]: color === 'inherit',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorSecondary]: color === 'secondary',
    },
    classNameProp,
  );

  const Component = componentProp || 'div';

  return (
    <Component className={className} {...other}>
      {children}
    </Component>
  );
};

ChatText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

ChatText.defaultProps = {
  children: null,
  className: '',
  color: 'default',
};

export default ChatText;
