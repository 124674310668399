import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';

import { Card, CardActionArea, IconButton, Slide, Typography } from '@material-ui/core';

import UploadIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { CircularProgressWithLabel } from '../index';

const useStyles = makeStyles(theme => {
  return {
    root: ({ width, ratio }) => ({
      width: width,
      height: width * ratio,
      padding: 0,
      position: 'relative',
    }),
    paper: {
      borderRadius: 0,
    },
    buttonWrapper: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      flexDirection: 'row',

      width: '100%',
      // pointerEvents: 'none',
      justifyContent: 'center',
    },
    centeredContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    stretch: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    },
    overlay: {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
  };
});

const CertificatePDFEdit = ({
  classes: extraClasses,
  src,
  width,
  ratio,
  onOpen,
  onEdit,
  onRemove,
  uploading,
  progress,
  uploadLabel,
  children,
}) => {
  const classes = useStyles({ width, ratio, classes: extraClasses });

  const [showButtons, setShowButtons] = useState(false);

  const body = (
    <Fragment>
      {src && children({ src, width })}
      <div
        className={clsx(classes.centeredContent, classes.stretch, { [classes.overlay]: uploading })}
      >
        {!src && !uploading && (
          <Fragment>
            <Typography variant="caption">{uploadLabel}</Typography>
            <IconButton style={{ pointerEvents: 'none' }}>
              <UploadIcon />
            </IconButton>
          </Fragment>
        )}
        {uploading && <CircularProgressWithLabel value={progress} scale={2} centered />}
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Card
        elevation={8}
        classes={{ root: classes.root, paper: classes.paper }}
        {...(src
          ? {
              onMouseEnter: () => setShowButtons(true),
              onMouseLeave: () => setShowButtons(false),
            }
          : {})}
      >
        {uploading ? (
          <div className={clsx(classes.stretch, classes.centeredContent)}>{body}</div>
        ) : (
          <CardActionArea
            onClick={() => (src ? onOpen() : onEdit())}
            classes={{ root: classes.stretch }}
          >
            {body}
          </CardActionArea>
        )}
        <Slide in={!uploading && showButtons} direction="up">
          <div className={clsx(classes.buttonWrapper, classes.overlay)}>
            <IconButton
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onEdit();
                return false;
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onRemove()}>
              <DeleteIcon />
            </IconButton>
          </div>
        </Slide>
      </Card>
    </Fragment>
  );
};

CertificatePDFEdit.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  ratio: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  onOpen: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  uploadLabel: PropTypes.string.isRequired,
};

CertificatePDFEdit.defaultProps = {
  width: 200,
  ratio: 1.414285714,
  uploading: false,
  progress: 0,
  uploadLabel: 'Upload PDF here',
};

export default CertificatePDFEdit;
