import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ListItem as MuiListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
}));

// Handles keylines and selected state

const ListItem = ({
  children,
  className: classNameProp,
  selected,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      // [classes.colorDefault]: color === 'default',
      [classes.selected]: selected,
    },
    classNameProp,
  );

  return (
    <MuiListItem className={className} {...other}>
      {children}
    </MuiListItem>
  );
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // color: PropTypes.oneOf(['default', 'inherit', 'textSecondary', 'primary', 'secondary']),
  selected: PropTypes.bool,
};

ListItem.defaultProps = {
  className: '',
  selected: false,
};

export default ListItem;
