// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// TODO: figure out good naming and move to themeBase (extraColors)
const pink = '#ec1a8b';
// TODO: move to Gutter/Fill
// const cyan = '#35feff';
// const lilac = '#d4c0fa';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    position: 'absolute',
    pointerEvents: 'none',
  },
  line: {
    borderColor: pink,
    borderStyle: 'solid',
    borderWidth: 0, // we'll set borderWidth in render according to 'fromSide' prop
  },
  horizontal: {
    left: 0,
    right: 0,
  },
  vertical: {
    top: 0,
    bottom: 0,
  },
  blendMode: {
    mixBlendMode: 'overlay', // TODO: make configurable (JSS dynamic props)
  },
}));

// Keyline visual aid
// Similar to a guide in photoshop
// Replicates the look of keylines in the Material Design Guidelines

// NOTE: Use inside a container with position: relative

// TODO: investigate if it's better to have an explicit orientation prop
//       with two possible OneOf() values or if it's better to use
//       a boolean prop ('vertical' in this case) when there's only two options
//       Keep in mind ease of use / intuitive code and readability as usual
//       + keep in mind both futureproofing and backwards compatibility
//       (eg: what if we want to add more options and need to change the API)

// TODO: add a little triangle arrow (cfr MD Guidelines) with intuitive direction
//       (used to signify content start/end after/before a keyline)

// TODO: add a label with the at position? cfr Ruler

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Keyline = ({
  at: atProp,
  className: classNameProp,
  fromSide,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const orientation = fromSide === 'top' || fromSide === 'bottom' ? 'horizontal' : 'vertical';

  const className = classNames(
    classes.root,
    classes.line,
    classes.blendMode,
    {
      [classes.horizontal]: orientation === 'horizontal',
      [classes.vertical]: orientation === 'vertical',
    },
    classNameProp,
  );

  // Get the dimension to use as CSS property:
  const dimension = orientation === 'horizontal' ? 'height' : 'width';

  // Make sure the line is always ON the specified 'at' position:
  let at = 0;
  if (atProp !== 0) {
    at = fromSide === 'top' || fromSide === 'left' ? atProp - 1 : atProp;
  }

  // Enable border again, by side (see classes)
  // TODO: simplify to just top or left?
  const borderWidthBySide = `border${capitalizeFirstLetter(fromSide)}Width`;

  // TODO: Alias style prop and spread/merge into object (deepmerge?)
  //       (investigate how MUI handles this)
  const style = {
    [fromSide]: at,
    [dimension]: 1,
    [borderWidthBySide]: 1,
  };

  return (
    <div style={style} className={className} {...other}>
      <span />
    </div>
  );
};

Keyline.propTypes = {
  at: PropTypes.number,
  className: PropTypes.string,
  fromSide: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

Keyline.defaultProps = {
  at: 0,
  className: '',
  fromSide: 'top',
};

export default Keyline;
