import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    flexGrow: 1,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  highlight: {
    backgroundColor: theme.palette.mediumDivider,
  },
  colorDefault: {
    color: theme.palette.text.primary,
  },
  colorInherit: {
    color: 'inherit',
  },
  colorTextSecondary: {
    color: theme.palette.text.secondary,
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
}));
const CalendarDayHeader = ({
  className: classNameProp,
  color,
  dateLabel,
  weekdayLabel,
  highlight,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.colorDefault]: color === 'default',
      [classes.colorInherit]: color === 'inherit',
      [classes.colorTextSecondary]: color === 'textSecondary',
      [classes.colorPrimary]: color === 'primary',
      [classes.colorSecondary]: color === 'secondary',
      [classes.highlight]: highlight,
    },
    classNameProp,
  );

  return (
    <div className={className} {...other}>
      <Typography color="inherit" variant="body2">
        {weekdayLabel}
      </Typography>
      <Typography color="inherit" variant="2display2">
        {dateLabel}
      </Typography>
    </div>
  );
};

CalendarDayHeader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'textSecondary', 'primary', 'secondary']),
  dateLabel: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
  weekdayLabel: PropTypes.string.isRequired,
};

CalendarDayHeader.defaultProps = {
  className: '',
  color: 'default',
  dateLabel: '',
  highlight: false,
  weekDayLabel: '',
};

export default CalendarDayHeader;
