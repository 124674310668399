import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { isWidthDown } from '@material-ui/core/withWidth';
import { useWidth } from '../Hooks';
import DetailViewCloseButton from './DetailViewCloseButton';

import Flex from '../Flex';

const useStyles = makeStyles(theme => ({
  docked: {
    flex: '1 0 auto',
    width: 1,
  },
  rootOverlay: {
    backgroundColor: theme.palette.background.default,
    width: '100vw',
    position: 'fixed !important',
    zIndex: 1200,
    top: 0,
    right: 0,
  },
  openOverlay: {
    opacity: 1,
    transform: 'translate3D(0, 0, 0)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  closedOverlay: {
    opacity: 0,
    transform: 'translate3D(100%, 0, 0)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const SideBar = ({ children, className, open, onClose, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const isPhone = isWidthDown('sm', width);

  return (
    <Flex
      fullColumn
      className={classNames(
        className,
        isPhone ? classes.rootOverlay : classes.docked,
        isPhone && !open ? classes.closedOverlay : classes.openOverlay,
      )}
      {...other}
    >
      {children}
      {isPhone && open && <DetailViewCloseButton onClick={onClose} />}
    </Flex>
  );
};
SideBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

SideBar.defaultProps = {
  open: false,
  value: '',
};

/* prettier-ignore */
export default SideBar;
