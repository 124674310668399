import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Drawer } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    paper: {
      position: 'relative',
      width: theme.increments.tablet * 4,
      height: '100%',
      overflowX: 'visible',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      zIndex: 'auto',
      borderRight: 'none',
    },
    paperClosed: {
      width: 0,
    },
    inner: {
      width: 'inherit',
      height: 'inherit',
    },
    [theme.breakpoints.up('sm')]: {
      paper: {
        width: theme.increments.tablet * 5,
      },
      paperClosed: {
        width: 0,
      },
    },
  }),
  { withTheme: true },
);

const FilterDrawer = ({ children, className, open, overlay, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Drawer
      className={className}
      variant={overlay ? 'temporary' : 'permanent'}
      classes={{
        paper: classNames(classes.paper, !open && classes.paperClosed),
      }}
      open={open}
      {...other}
    >
      {children}
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FilterDrawer.defaultProps = {
  className: '',
};

export default FilterDrawer;
