// @flow weak

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Input } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 8px',
    backgroundColor: 'rgba(0, 0, 0, .06)',
    borderRadius: 4,
    overflow: 'hidden',
  },
}));

function InputBox({ className, classes: eClasses, ...other }) {
  const classes = useStyles({ classes: eClasses });
  return <Input className={classNames(classes.root, className)} {...other} />;
}

InputBox.propTypes = {
  className: PropTypes.string,
};

InputBox.defaultProps = {
  className: '',
};

export default InputBox;
