import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { isWidthDown } from '@material-ui/core/withWidth';
import { AppBar, Typography } from '@material-ui/core';
import { useWidth } from '../Hooks';

import Toolbar from '../Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.appBar || theme.palette.primary[500],
    // zIndex: 1, // TODO: check if ditching this breaks anything in household app
  },
  disableShadow: {
    boxShadow: 'none',
  },
  backgroundImage: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  skrim: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background:
      'linear-gradient(to top, hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.738) 19%, hsla(0, 0%, 0%, 0.541) 34%, hsla(0, 0%, 0%, 0.382) 47%, hsla(0, 0%, 0%, 0.278) 56.5%, hsla(0, 0%, 0%, 0.194) 65%, hsla(0, 0%, 0%, 0.126) 73%, hsla(0, 0%, 0%, 0.075) 80.2%, hsla(0, 0%, 0%, 0.042) 86.1%, hsla(0, 0%, 0%, 0.021) 91%, hsla(0, 0%, 0%, 0.008) 95.2%, hsla(0, 0%, 0%, 0.002) 98.2%, hsla(0, 0%, 0%, 0) 100%)',
  },
  extraInset: {
    marginLeft: 72,
    paddingLeft: 0,
  },
  indent: {
    paddingLeft: 54,
  },
  title: {
    flexGrow: 1,
  },
  spacer: {
    flexGrow: 0,
  },
  titleKeyline: {
    // marginLeft: 20, // keyline alignment for most use cases
  },
  toolbar: {},
  extraToolbar: {},
}));

// Simple AppBar for most use cases
// Sets the right keylines styling

// TODO: make functional
// TODO: remove disableShadow in favour of passing elevation

const ResponsiveAppBar = ({
  actions,
  frontActions,
  extraToolbar,
  extraHigh,
  extraInset,
  imgSrc,
  navIcon,
  onClickNav,
  title,
  titleOnTop,
  wide,
  disableShadow,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const isPhone = isWidthDown('xs', width);
  // Do not pass unnessecary props to AppBar. Also why disabledShadow + isPhone?
  return (
    <AppBar
      classes={{
        root: classNames(
          classes.root,
          !isPhone && classes.disableShadow,
          imgSrc && classes.backgroundImage,
        ),
      }}
      position={isPhone ? 'fixed' : 'static'}
      style={imgSrc ? { backgroundImage: `url(${imgSrc})` } : null}
      {...other}
    >
      {!isPhone && imgSrc && <div className={classes.skrim} />}
      {!isPhone && !titleOnTop && extraToolbar && (
        <Toolbar
          size="default"
          classes={{ root: classNames(classes.extraToolbar, extraInset && classes.extraInset) }}
        >
          {extraToolbar}
        </Toolbar>
      )}
      {!isPhone && !titleOnTop && !extraToolbar && <Toolbar size="default" />}
      {!isPhone && !titleOnTop && extraToolbar && extraHigh && <Toolbar size="large" />}

      <Toolbar
        size={isPhone || titleOnTop ? 'default' : 'large'}
        classes={{ root: classes.toolbar }}
      >
        {frontActions}

        {title && typeof title === 'string' && (
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classNames(
              classes.title,
              classes.titleKeyline,
              isPhone ? classes.indent : '',
              extraInset && titleOnTop && !isPhone && classes.extraInset,
            )}
          >
            {title}
          </Typography>
        )}

        {title && typeof title !== 'string' && (
          <div
            className={classNames(
              classes.title,
              classes.titleKeyline,
              isPhone ? classes.indent : '',
              extraInset && titleOnTop && !isPhone && classes.extraInset,
            )}
          >
            {title}
          </div>
        )}

        {!title && isPhone && <div className={classNames(classes.spacer, classes.indent)} />}
        {actions}
        {isPhone && extraToolbar}
      </Toolbar>
      {!isPhone && titleOnTop && extraToolbar && extraHigh && <Toolbar size="large" />}
      {!isPhone && titleOnTop && !extraToolbar && <Toolbar size="large" />}
      {!isPhone && titleOnTop && extraToolbar && (
        <Toolbar size="large" classes={{ root: classNames(classes.extraToolbar) }}>
          {extraToolbar}
        </Toolbar>
      )}
    </AppBar>
  );
};
ResponsiveAppBar.propTypes = {
  actions: PropTypes.node,
  disableShadow: PropTypes.bool,
  extraHigh: PropTypes.bool,
  extraInset: PropTypes.bool,
  extraToolbar: PropTypes.node,
  frontActions: PropTypes.node,
  imgSrc: PropTypes.string,
  navIcon: PropTypes.element,
  onClickNav: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleOnTop: PropTypes.bool,
  wide: PropTypes.bool, // Adjusts keylines for detail view AppBar on tablet and up
};

ResponsiveAppBar.defaultProps = {
  actions: '',
  extraToolbar: null,
  extraHigh: false,
  extraInset: false,
  titleOnTop: false,
  frontActions: '',
  disableShadow: false,
  title: '',
  wide: false,
};

export default ResponsiveAppBar;
