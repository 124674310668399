import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  inactive: {
    display: 'none',
    background: 'transparent',
  },
  active: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'radial-gradient(circle, rgba(255, 255, 255, 1), rgba(255, 255, 255, .7))',
    zIndex: theme.zIndex.appBlocker,
    transition: theme.transitions.create('background'),
  },
}));

const AppBlocker = ({ appBlockerLabel, active, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  const className = active ? classes.active : classes.inactive;
  return (
    <div className={className}>
      <Typography variant="body2">{appBlockerLabel}</Typography>
    </div>
  );
};

AppBlocker.propTypes = {
  active: PropTypes.bool,
  appBlockerLabel: PropTypes.string.isRequired,
};

AppBlocker.defaultProps = {
  active: false,
  appBlockerLabel: 'No connection',
};

// prettier-ignore
export default AppBlocker;
