// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    // zIndex: 1, // TODO: check if ditching this breaks anything in household app
  },
  disableShadow: {
    boxShadow: 'none',
  },
  toolbar: {
    height: theme.increments.mobile,
    // Special alignment for IconButtons:
    paddingLeft: 4,
    paddingRight: 4,
  },
  title: {
    flexGrow: 1,
  },
  titleKeyline: {
    marginLeft: 20, // keyline alignment for most use cases
  },
  [theme.breakpoints.up('sm')]: {
    toolbar: {
      height: theme.increments.tablet,
      // Special alignment for IconButtons:
      paddingRight: 12,
      paddingLeft: 12,
    },
    toolbarWide: {
      // Special alignment for IconButtons:
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
}));

// Simple AppBar for most use cases
// Sets the right keylines styling

// TODO: make functional
// TODO: remove disableShadow in favour of passing elevation

const SimpleAppBar = ({
  actions,
  disableShadow,
  navIcon,
  onClickNav,
  title,
  wide,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <AppBar className={classNames(classes.root, disableShadow && classes.disableShadow)} {...other}>
      <Toolbar className={classNames(classes.toolbar, wide && classes.toolbarWide)}>
        <IconButton color="inherit" onClick={onClickNav}>
          {navIcon}
        </IconButton>
        {typeof title === 'string' ? (
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classNames(classes.title, classes.titleKeyline)}
          >
            {title}
          </Typography>
        ) : (
          <div className={classes.title}>{title}</div>
        )}
        {actions}
      </Toolbar>
    </AppBar>
  );
};

SimpleAppBar.propTypes = {
  actions: PropTypes.node,

  disableShadow: PropTypes.bool,
  navIcon: PropTypes.element.isRequired,
  onClickNav: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  wide: PropTypes.bool, // Adjusts keylines for detail view AppBar on tablet and up
};

SimpleAppBar.defaultProps = {
  actions: '',
  disableShadow: false,
  title: '',
  wide: false,
};

export default SimpleAppBar;
