import React from 'react';
import clsx from 'clsx';

import { Typography, ButtonBase } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    boxSizing: 'content-box',
    alignItems: 'center',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(240,240,240,0.6)',
    border: 'solid 1px transparent',
    color: theme.palette.text.primary,
  },
  selected: {
    color: theme.palette.primary[500],
    borderColor: theme.palette.primary[500],
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.85)' : 'white',
  },
  label: {
    textTransform: 'uppercase',
    display: 'block',
    flexGrow: 1,
  },
}));
const SelectableButton = React.forwardRef(({ children, selected, className, ...other }, ref) => {
  const classes = useStyles();
  return (
    <ButtonBase
      className={clsx(className, classes.root, selected && classes.selected)}
      {...other}
      ref={ref}
    >
      <Typography align="center" variant="caption" className={classes.label} noWrap>
        {children}
      </Typography>
    </ButtonBase>
  );
});

export default SelectableButton;
