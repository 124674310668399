import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
    borderRight: `1px solid ${theme.palette.divider}`,
    flexGrow: 1,
    width: 0, // makes columns not grow wider than flex parent
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  rows: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  row: {
    boxSizing: 'border-box',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  highlight: {
    backgroundColor: theme.palette.mediumDivider,
  },
}));

const BoardLane = ({
  children,
  index,
  rowHeight,
  rows,
  droppableProps,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Droppable
      droppableId={index}
      type={'CARD'}
      {...droppableProps}
      // ignoreContainerClipping={ignoreContainerClipping}
      // isDropDisabled={isDropDisabled}
    >
      {(dropProvided, dropSnapshot) => (
        <div
          ref={dropProvided.innerRef}
          className={classNames(classes.root, dropSnapshot.isDraggingOver && classes.highlight)}
          {...other}
          {...dropProvided.droppableProps}
        >
          {!!rows && (
            <div className={classes.rows}>
              {Array(...Array(rows)).map((j, i) => (
                <div key={i} className={classes.row} style={{ height: rowHeight + 1 }} />
              ))}
            </div>
          )}
          {children}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

BoardLane.propTypes = {
  children: PropTypes.node,
  droppableProps: PropTypes.object,
  index: PropTypes.number,
  rowHeight: PropTypes.number,
  rows: PropTypes.number,
};

BoardLane.defaultProps = {
  children: '',
  rowHeight: 48,
  rows: 24,
};

export default BoardLane;
