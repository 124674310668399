import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

const ElementWidth = ({ children, noTresholds, upMap, ...other }) => {
  const [state, setState] = useState({ width: null, bound: null });

  const updateWidth = width => {
    const newBound = Object.keys(upMap)
      .map(key => parseInt(key, 10))
      .sort((a, b) => a - b)
      .reduce((acc, b) => {
        if (width > b) {
          return b;
        }
        return acc;
      }, 0);

    if (noTresholds || newBound !== state.bound) {
      setState({ width, bound: newBound });
    }
  };

  const { width, bound } = state;

  return (
    <Measure bounds onResize={contentRect => updateWidth(contentRect.bounds.width)}>
      {({ measureRef }) => (
        <div {...other} ref={measureRef}>
          {children({ measureRef, width, bound, ...upMap[bound] })}
        </div>
      )}
    </Measure>
  );
};

ElementWidth.propTypes = {
  children: PropTypes.node.isRequired,
  noTresholds: PropTypes.bool,
  upMap: PropTypes.object,
};

ElementWidth.defaultProps = {
  noTresholds: false,
  upMap: {
    1920: { className: 'wide' },
    1280: { className: 'desktop' },
    960: { className: 'laptop' },
    768: { className: 'tablet-wide' },
    600: { className: 'tablet' },
    384: { className: 'tablet-portrait' },
    0: { className: 'phone' },
  },
};

export default ElementWidth;
