import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '0 0 auto',
    display: 'flex',
    boxSizing: 'border-box',
    height: '100%',
    flexShrink: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  cell: {
    cursor: 'pointer',
    flex: '1 0 auto',
    boxSizing: 'border-box',
    padding: '4px 24px 4px 8px',
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  label: {
    // color: 'white', // TODO: dark theme?
  },
}));

const TimelineMarkings = ({
  className,
  height,
  onClickUnit,
  onUpdate,
  scrollbarsRef,
  scrollLeft,
  units,
  width,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    scrollbarsRef.current.scrollLeft(scrollLeft);
  }, [scrollLeft]);

  const onScroll = _ => {
    if (isScrolling) {
      onUpdate(scrollbarsRef.current.getScrollLeft());
    }
  };
  return (
    <Scrollbars
      className={classes.root}
      onScrollStart={() => {
        setIsScrolling(true);
      }}
      onScrollStop={() => {
        onScroll();
        setIsScrolling(false);
      }}
      onScroll={onScroll}
      ref={scrollbarsRef}
    >
      <div className={classNames(classes.root, className)} {...other} style={{ height }}>
        {units.map((unit, index) => (
          <div
            key={unit.label}
            className={classes.cell}
            style={{ width }}
            role="button"
            onClick={() => onClickUnit(unit)}
            onKeyPress={() => onClickUnit(unit)}
            tabIndex={index}
          >
            <Typography className={classes.label}>{unit.label}</Typography>
          </div>
        ))}
      </div>
    </Scrollbars>
  );
};

TimelineMarkings.propTypes = {
  cellWidth: PropTypes.number,
  className: PropTypes.string,
  height: PropTypes.number,
  onClickUnit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  scrollbarsRef: PropTypes.instanceOf(Scrollbars).isRequired,
  scrollLeft: PropTypes.number,
  units: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
};

TimelineMarkings.defaultProps = {
  cellWidth: 64,
  className: '',
  height: 64,
  scrollLeft: 0,
  width: 72,
};

export default TimelineMarkings;
