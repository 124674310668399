import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import  Scrollbars  from 'react-custom-scrollbars';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {}, // add height 100% as default? or remove childrenprop? :/ if so might as well remove this component
}));

const ScrollableContent = ({ children, scrollToBottom, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const scrollbarsRef = useRef(null);
  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottomFunc();
    }
  }, [scrollToBottom]);

  const getScrollHeight = () => {
    return scrollbarsRef.current.getScrollHeight();
  };

  const getClientHeight = () => {
    return scrollbarsRef.current.getClientHeight();
  };

  const scrollToBottomFunc = () => {
    const scr = getScrollHeight() - getClientHeight();
    scrollbarsRef.current.scrollToBottom();
  };

  return (
    <Scrollbars className={classes.root} ref={scrollbarsRef} {...other}>
      <div className={classes.content}>{children}</div>
    </Scrollbars>
  );
};

ScrollableContent.propTypes = {
  children: PropTypes.node.isRequired,
  scrollToBottom: PropTypes.bool.isRequired,
};

ScrollableContent.defaultProps = {
  scrollToBottom: false,
};

export default ScrollableContent;
