import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withProps } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Typography } from '@material-ui/core';
import Flex from '../Flex';

import ToolbarButton from '../ToolbarButton';

const useStyles = makeStyles(theme => ({
  dates: {
    textAlign: 'center',
    padding: '8px 0',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  colorTweak: {
    '&:not([disabled])': {
      color: theme.palette.action.active,
    },
  },
}));

const UNIT_FORMATS = {
  hour: 'LT',
  day: 'dddd',
  week: 'w',
  month: 'MMMM',
  year: 'YYYY',
};

function formatUnit(unit, range) {
  switch (unit) {
    case 'week':
      return `${range.start.format('L')} — ${range.end.format('L')}`;
    case 'hour':
    case 'day':
    case 'month':
    case 'year':
    default:
      return range.start.format(UNIT_FORMATS[unit]);
  }
}

const TimeCycler = ({
  className,
  unit,
  buttonLabel,
  showValue,
  range,
  goBack,
  goForward,
  reset,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  const disableShortcut = moment().isBetween(range.start, range.end);

  const formattedValue = (
    <Typography variant="body2" className={classes.dates}>
      {formatUnit(unit, range)}
    </Typography>
  );

  return (
    <div>
      <Flex className={classNames(classes.controls, className)}>
        <ToolbarButton icon onClick={goBack}>
          <ChevronLeftIcon />
        </ToolbarButton>
        <ToolbarButton onClick={reset} disabled={disableShortcut} className={classes.colorTweak}>
          {buttonLabel}
        </ToolbarButton>
        <ToolbarButton icon onClick={goForward}>
          <ChevronRightIcon />
        </ToolbarButton>
      </Flex>
      {showValue && formattedValue}
    </div>
  );
};

TimeCycler.propTypes = {
  className: PropTypes.string,
  buttonLabel: PropTypes.string,
  unit: PropTypes.oneOf(['hour', 'day', 'week', 'month', 'quarter', 'year']),
  showValue: PropTypes.bool,
  range: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

TimeCycler.defaultProps = {
  className: '',
  showValue: false,
  unit: 'week',
  buttonLabel: 'this week',
};

export default compose(
  withState('range', 'setRange', ({ unit, defaultValue }) => {
    const now = defaultValue || moment();
    return {
      start: moment(now).startOf(unit),
      end: moment(now).endOf(unit),
    };
  }),
  withProps(({ setRange, step, unit, onChange }) => ({
    goBack() {
      setRange(range => {
        range.start.subtract(step || 1, `${unit}s`);
        range.end.subtract(step || 1, `${unit}s`);
        onChange(range);
        return range;
      });
    },
    goForward() {
      setRange(range => {
        range.start.add(step || 1, `${unit}s`);
        range.end.add(step || 1, `${unit}s`);
        onChange(range);
        return range;
      });
    },
    reset() {
      setRange(() => {
        const now = moment();
        const range = {
          start: moment(now).startOf(unit),
          end: moment(now).endOf(unit),
        };
        onChange(range);
        return range;
      });
    },
  })),
)(TimeCycler);
