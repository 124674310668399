import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    paddingLeft: 56 - 1, // same default width as CalendarHoursColumn -1 for its border
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  noMargin: {
    paddingLeft: 0,
  },
}));

const BoardHeader = ({ children, noMargin, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(classes.root, noMargin && classes.noMargin)} {...other}>
      {children}
    </div>
  );
};

BoardHeader.propTypes = {
  children: PropTypes.node,
  noMargin: PropTypes.bool,
};

BoardHeader.defaultProps = {
  children: '',
  noMargin: true,
};

export default BoardHeader;
