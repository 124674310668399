import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import { Typography, SnackbarContent } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import AlignRight from '../AlignRight';

const variantIcon = {
  success: CheckCircleIcon,
  loading: SyncIcon,
};

const useStyles = makeStyles(theme => ({
  // '@keyframes spin': {
  //   from: { transform: 'rotate(0deg)' },
  //   to: { transform: 'rotate(-90deg)' },
  // },
  root: { background: 'none', boxShadow: 'none', padding: 0, margin: 0 },
  label: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    width: '100%',
  },
  busyWrapper: {
    // width: 48,
    // height: 48,
    // padding: 0,
    // fontSize: '1.5rem',
  },
  busyIcon: {
    animation: '1s linear 0s infinite reverse spin',
    // height: 48,
  },
}));

const StoringSnack = ({ className, message, variant, onClose, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      classes={{ message: classes.message }}
      className={classNames(classes.base, className)}
      aria-describedby="client-snackbar"
      message={
        <AlignRight>
          <Typography align="right" className={classNames(classes.label)}>
            {message}
          </Typography>
          <div
            className={classNames(
              classes.iconWrapper,
              variant === 'loading' && classes.busyWrapper,
            )}
          >
            <Icon
              className={classNames(
                classes.icon,
                classes.iconVariant,
                variant === 'loading' && classes.busyIcon,
              )}
            />
          </div>
        </AlignRight>
      }
      {...other}
    />
  );
};

StoringSnack.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'loading']).isRequired,
};

StoringSnack.defaultProps = {
  className: 'base',
};

export default StoringSnack;
