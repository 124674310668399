import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    // console.log(error, info);
    this.setState({ hasError: { error: error.toString(), info: info.componentStack } });
  }

  render() {
    const { children, history, errorPageProps } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <ErrorPage
          {...errorPageProps}
          error={hasError.error}
          info={hasError.info}
          onGoBack={() => {
            this.setState({ hasError: false });
            history.goBack();
          }}
          onClearCache={async () => {
            // await apolloClient.resetStore();
            this.setState({ hasError: false });
            history.goBack();
          }}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.func,
  errorPageProps: PropTypes.object,
};

ErrorBoundary.defaultProps = {
  children: () => null,
};

// prettier-ignore
export default ErrorBoundary;
