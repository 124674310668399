import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Measure from 'react-measure';

// TODO: highlight labels every xn child?
// TODO: (after JSS dynamic props or if decided to defer all to inline styles in render)
//       - make unit configurable (px, em, rem etc) also investigate hiDPI
//       - make interval configurable (marking height and labeling)

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    pointerEvents: 'none',
  },
  horizontal: {
    height: theme.spacing(4),
  },
  vertical: {
    flexDirection: 'column',
    height: '100%',
    width: theme.spacing(4),
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  // TODO: make marking height interval configurable:
  //       either make seperate classes and use in for loop or wait for JSS dynamic props
  marking: {
    position: 'relative',
    boxSizing: 'border-box',
  },
  horizontalMarking: {
    height: '30%',
    borderRight: '1px solid rgba(0, 0, 0, .3)',
    '&:nth-child(2n)': {
      height: '40%',
      borderRight: `1px solid ${theme.palette.text.hint}`,
    },
    '&:nth-child(4n)': {
      height: '100%',
      borderRight: `1px solid ${theme.palette.text.secondary}`,
    },
  },
  verticalMarking: {
    width: '30%',
    borderBottom: '1px solid rgba(0, 0, 0, .3)',
    '&:nth-child(2n)': {
      width: '40%',
      borderBottom: `1px solid ${theme.palette.text.hint}`,
    },
    '&:nth-child(4n)': {
      width: '100%',
      borderBottom: `1px solid ${theme.palette.text.secondary}`,
    },
  },
  label: {
    position: 'absolute',
    ...theme.typography.caption,
    fontSize: '10px',
  },
  topLabel: {
    top: 0,
    right: 2,
  },
  rightLabel: {
    right: 0,
    bottom: 2,
  },
  bottomLabel: {
    bottom: 0,
    right: 2,
  },
  leftLabel: {
    left: 0,
    bottom: 2,
  },
}));

// Optimizations:
// - Generate SVG for markings instead of array of elements? (except for labels)

const Ruler = ({ className: classNameProp, increment, side, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const [dimensions, setDimensions] = useState({
    width: -1,
    height: -1,
  });

  const { width, height } = dimensions;

  // console.log('width', width);
  // console.log('height', height);

  const orientation = side === 'top' || side === 'bottom' ? 'horizontal' : 'vertical';

  const className = classNames(
    classes.root,
    {
      [classes.horizontal]: orientation === 'horizontal',
      [classes.vertical]: orientation === 'vertical',
      [classes.alignEnd]: side === 'top' || side === 'left',
    },
    classNameProp,
  );

  const markingsAmount = Math.floor(
    orientation === 'horizontal' ? width / increment : height / increment,
  );

  const markings = [];

  for (let i = 0; i < markingsAmount; i += 1) {
    const remainder = (i + 1) % 4;
    // TODO: optimize code readability
    markings.push(
      <div
        key={i}
        style={orientation === 'horizontal' ? { width: increment } : { height: increment }}
        className={classNames(
          classes.marking,
          orientation === 'horizontal' ? classes.horizontalMarking : classes.verticalMarking,
        )}
      >
        {i !== 0 && remainder === 0 && (
          <div
            className={classNames(classes.label, {
              [classes.topLabel]: side === 'top',
              [classes.rightLabel]: side === 'right',
              [classes.bottomLabel]: side === 'bottom',
              [classes.leftLabel]: side === 'left',
            })}
          >
            {(i + 1) * increment}
          </div>
        )}
      </div>,
    );
  }

  return (
    <Measure
      bounds
      onResize={contentRect => {
        setDimensions(contentRect.bounds);
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} className={className} {...other}>
          {markings}
        </div>
      )}
    </Measure>
  );
};
Ruler.propTypes = {
  className: PropTypes.string,
  increment: PropTypes.number,
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

Ruler.defaultProps = {
  className: '',
  increment: 8,
  side: 'top',
};
export default Ruler;
