import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import ReactCropper from 'react-cropper';

/** ****************************************************************************
 * Component
 **************************************************************************** */
const Cropper = forwardRef(
  ({ aspectRatio, className, onCrop, src, data, viewMode, zoomable }, ref) => {
    const cropperReact = useRef();

    const getData = () => {
      const { cropper } = cropperReact.current;
      return cropper.getData(true);
    };

    useImperativeHandle(ref, () => ({
      getData,
    }));

    return (
      <ReactCropper
        ref={cropperReact}
        src={src}
        cropend={() => onCrop(getData())}
        aspectRatio={aspectRatio}
        data={data}
        viewMode={viewMode}
        zoomable={zoomable}
        className={className}
      />
    );
  },
);

Cropper.propTypes = {
  aspectRatio: PropTypes.number,
  classes: PropTypes.object,
  className: PropTypes.object,
  src: PropTypes.string.isRequired,
  data: PropTypes.object,
  onCrop: PropTypes.func.isRequired,
  viewMode: PropTypes.number.isRequired,
  zoomable: PropTypes.bool.isRequired,
};

Cropper.defaultProps = {
  aspectRatio: 16 / 9,
  viewMode: 2,
  zoomable: false,
};

export default Cropper;
