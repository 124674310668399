// @flow
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { coverButton } from '../styling';

const useStyles = makeStyles(theme => ({
  root: {
    ...coverButton(theme),
  },
  // someState: {},
}));

const CoverButton = ({
  className: classNameProp,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      // [classes.someState]: prop_expression_here,
    },
    classNameProp,
  );

  return <IconButton className={className} {...other} />;
};

CoverButton.propTypes = {
  className: PropTypes.string,
};

CoverButton.defaultProps = {
  className: '',
};

export default CoverButton;
