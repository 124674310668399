import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Toolbar as MuiToolbar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: theme.heights.toolbarTop,
  },
  large: {
    height: theme.heights.toolbarBottom,
  },
  fabKeyline: {
    paddingLeft: 104,
  },
  titleKeyline: {
    paddingLeft: 72,
  },
  wideGutters: {
    paddingLeft: 32,
    paddingRight: 32,
  },
}));

const Toolbar = ({
  children,
  className: classNameProp,
  keyline,
  gutters,
  size,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.large]: size === 'large',
      [classes.fabKeyline]: keyline === 'fab',
      [classes.titleKeyline]: keyline === 'title',
      [classes.wideGutters]: gutters === 'wide',
    },
    classNameProp,
  );

  return (
    <MuiToolbar className={className} {...other}>
      {children}
    </MuiToolbar>
  );
};

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gutters: PropTypes.oneOf(['default', 'wide']),
  keyline: PropTypes.oneOf(['default', 'title', 'fab']),
  size: PropTypes.oneOf(['default', 'large']),
};

Toolbar.defaultProps = {
  children: '',
  className: '',
  gutters: 'default',
  keyline: 'default',
  size: 'default',
};

export default Toolbar;
