import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import Message from './Message';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  avatar: {
    display: 'flex',
    flexShrink: 0,
    width: 36,
    height: 36,
    marginTop: 2,
    marginRight: 10,
  },
  container: {
    paddingRight: 8,
    paddingBottom: 4,
  },
}));

const MessageGroup = ({ messages, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  const messageGroup = messages.map(message => (
    <Message
      key={message._id}
      text={message.text}
      sender={message.sender.name}
      timestampString={message.timestampString}
      timestamp={message.timestamp}
      first={messages[0] === message}
    />
  ));
  return (
    <div className={classes.root}>
      {messages[0].sender && (
        <Avatar style={{ backgroundColor: messages[0].sender.color }} className={classes.avatar}>
          {messages[0].sender.name.substr(0, 1)}
        </Avatar>
      )}
      <div className={classes.container}>{messages[0].sender && messageGroup}</div>
    </div>
  );
};

MessageGroup.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default MessageGroup;
