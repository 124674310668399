import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { DragDropContext } from 'react-beautiful-dnd';

import { Scrollbars } from 'react-custom-scrollbars';
import Flex from '../Flex';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const BoardBody = ({ children, className, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Scrollbars>
      <DragDropContext {...other}>
        <Flex classes={{ root: classes.root }}>{children}</Flex>
      </DragDropContext>
    </Scrollbars>
  );
};

BoardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

BoardBody.defaultProps = {
  children: '',
  className: '',
};

export default BoardBody;
