import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Typography } from '@material-ui/core';

// NOTE: Needs refactoring (same treatment as SystemMessage)
// because no way to style/configure this in the app it's used in

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: 2,
  },
  sender: {
    display: 'inline',
    marginRight: 8,
  },
  timestamp: {
    display: 'inline',
    color: theme.palette.text.hint,
    cursor: 'default',
  },
  messageText: {
    color: 'rgba(0, 0, 0, .72)',
    lineHeight: 1.5,
    paddingBottom: 4,
  },
}));

const Message = ({ sender, text, timestampString, timestamp, first, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div>
      {first && (
        <div className={classes.heading}>
          <Typography variant="body2" className={classes.sender}>
            {sender}
          </Typography>
          <Typography
            variant="caption"
            className={classes.timestamp}
            title={moment(timestamp).format('DD/MM/YYYY HH:mm')}
          >
            {timestampString}
          </Typography>
        </div>
      )}
      <Typography variant="body1" className={classes.messageText}>
        {text}
      </Typography>
    </div>
  );
};

Message.propTypes = {
  first: PropTypes.bool,
  sender: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  timestamp: PropTypes.instanceOf(Date).isRequired,
  timestampString: PropTypes.string.isRequired,
};

Message.defaultProps = {
  first: false,
};

export default Message;
