import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('height', {
      duration: theme.transitions.duration.short,
    }),
  },
}));
const TimelineRow = ({ className, children, height, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(classes.row, className)} style={{ height }}>
      {children}
    </div>
  );
};

TimelineRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  height: PropTypes.node.isRequired,
};

TimelineRow.defaultProps = {
  className: '',
  height: 64,
};

export default TimelineRow;
