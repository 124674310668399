import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Grid } from '@material-ui/core';
import ElementWidth from '../ElementWidth';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(1600 + theme.spacing.unit * 3 * 2)]: {
        // width: 1600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    cardGrid: {
      padding: `${theme.spacing.unit * 8}px 0`,
    },
    gridContainer: {
      width: '100%',
      margin: 0,
    },
    gridItem: {
      [theme.breakpoints.down('sm')]: {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  };
});

const CardList = ({ upMap, children, items, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <ElementWidth upMap={upMap}>
      {({ measureRef, cols, width }) => {
        if (!width) {
          return '';
        }
        return (
          <div className={classNames(classes.layout, classes.cardGrid)}>
            <Grid container spacing={5} classes={{ container: classes.gridContainer }}>
              {items.map(item => {
                return (
                  <Grid item key={item._id} sm={12 / cols} className={classes.gridItem}>
                    {children(item)}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      }}
    </ElementWidth>
  );
};

CardList.propTypes = {
  children: PropTypes.func.isRequired,
  classes: PropTypes.object,
  items: PropTypes.array.isRequired,
  upMap: PropTypes.object,
};

CardList.defaultProps = {
  items: [],
  upMap: {
    0: { cols: 1 },
    600: { cols: 2 },
    1000: { cols: 3 },
    1400: { cols: 4 },
  },
};

export default CardList;
