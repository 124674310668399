import React from 'react';
import PropTypes from 'prop-types';

import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';

import ScrollerController from './ScrollerController';
import MessageGroup from './MessageGroup';
import SystemMessage from './SystemMessage';
import MessageDivider from './MessageDivider';

// The maximum time between messages
const MAX_TIME_DIFF = 5 * 60 * 1000; // 5 minutes

// Message groups aggregator
let groups = [];
let messagePool = [];

function needsNewPool(message) {
  const prevMessage = messagePool[messagePool.length - 1];
  const TIME_DIFF = prevMessage ? prevMessage.timestamp - message.timestamp : 0;
  const differentSender = prevMessage && message.sender._id !== prevMessage.sender._id;
  return differentSender || TIME_DIFF > MAX_TIME_DIFF;
}

function commitPool() {
  if (messagePool.length) {
    groups.unshift(<MessageGroup key={groups.length} messages={messagePool} />);
    messagePool = [];
  }
}

function addUserMessage(message) {
  if (needsNewPool(message)) {
    commitPool();
  }
  messagePool.unshift(message);
}

function addSystemMessage(message) {
  commitPool();
  groups.unshift(<SystemMessage message={message.text} key={groups.length} timestampString={message.timestampString} />);
}

function parseMessage(message) {
  if (React.isValidElement(message)) {
    commitPool();
    groups.unshift(message);
    return;
  }
  if (message.system) {
    addSystemMessage(message);
  } else {
    addUserMessage(message);
  }
}

function resetMessageGroups() {
  groups = [];
  messagePool = [];
}

const ThreadController = ({
  containerClassName,
  messages,
  handle,
  loading,
  formatMessage,
  newMessageText,
}) => {
  if (!loading && messages && messages.length) {
    resetMessageGroups();
    messages.forEach((rawMessage, index) => {
      const message = formatMessage(rawMessage);

      if (!message.system && !message.text && !React.isValidElement(message)) {
        return;
      }

      parseMessage(message);

      const nextMessage = messages[index + 1];
      if (!message.read && (!nextMessage || nextMessage.read)) {
        commitPool();
        groups.unshift(<MessageDivider key={groups.length} text={newMessageText} />);
      }
    });

    if (messagePool.length) {
      commitPool();
    }
  }

  return (
    <ScrollerController handle={handle} className={containerClassName}>
      {!loading && groups}
    </ScrollerController>
  );
};

ThreadController.propTypes = {
  containerClassName: PropTypes.string,
  formatMessage: PropTypes.func,
  handle: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  messages: PropTypes.array.isRequired,
  newMessageText: PropTypes.string.isRequired,
};

ThreadController.defaultProps = {
  containerClassName: '',
  loading: false,
  formatMessage: (message) => message,
};

export default compose(
  // TODO: React 16 would allow us to put ScrollerController around this component from its parent.
  // Removing the need for the "handle" prop in this component.
  shouldUpdate(({ messages, handle }, { messages: nextMessages, handle: nextHandle }) => {
    const shouldUpdateTest =
      handle !== nextHandle ||
      messages.length !== nextMessages.length ||
      messages[0] !== nextMessages[0];

    return shouldUpdateTest;
  }),
)(ThreadController);
