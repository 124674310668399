// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper, List, MenuItem } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
  suggestionsContainer: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 2, // TODO: higher number + move to theme? shouldn't clash with MUI stuff
  },
  list: {
    maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
    overflowY: 'auto',
  },
}));

const Menu = ({ items, containerProps, downshiftProps, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  const theme = useTheme();
  const { highlightedIndex, getItemProps, itemToString } = downshiftProps;
  return (
    <div className={classes.suggestionsContainer} {...containerProps}>
      <Paper elevation={2} className={classes.paper}>
        <List className={classes.list}>
          {items.map((item, index) => {
            // console.log('getItemProps', getItemProps({ item }));
            const isHighlighted = highlightedIndex === index;
            return (
              <MenuItem
                {...getItemProps({ item })}
                key={itemToString(item)}
                selected={isHighlighted}
                component="div"
                style={{
                  fontWeight: isHighlighted
                    ? theme.typography.fontWeightMedium
                    : theme.typography.fontWeightRegular,
                }}
              >
                {itemToString(item)}
              </MenuItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};
Menu.propTypes = {
  containerProps: PropTypes.object,
  downshiftProps: PropTypes.object,
  items: PropTypes.array,
  theme: PropTypes.object,
};

export default Menu;
