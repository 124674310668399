import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    root: ({ scale, centered }) => ({
      transform: `scale(${scale})`,
      transformOrigin: centered ? 'auto' : '0 0 0',
    }),
  };
});

function CircularProgressWithLabel(props) {
  const classes = useStyles(props);
  const { LabelProps, ...other } = props;
  return (
    <Box position="relative" display="inline-flex" classes={{ root: classes.root }}>
      <CircularProgress variant="determinate" {...other} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          {...LabelProps}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  centered: PropTypes.bool.isRequired,
};

CircularProgressWithLabel.defaultProps = {
  value: 0,
  scale: 1,
  centered: false,
};

export default CircularProgressWithLabel;
