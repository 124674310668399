import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

let lastTime = 'lg';

export default () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const w =
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || lastTime;
  lastTime = w;
  return w;
};
