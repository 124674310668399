import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// TODO: move to theme
const chevronSize = 16;
const chevronColor = 'e0e0e0';
const chevronOpacity = 0.38;

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='${chevronSize}' height='${chevronSize}' viewBox='0 0 ${chevronSize} ${chevronSize}' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23${chevronColor}' fill-opacity='${chevronOpacity}' fill-rule='evenodd'%3E%3Cpath d='M0 ${chevronSize}L${chevronSize} 0H${chevronSize /
      2}L0 ${chevronSize / 2}M${chevronSize} ${chevronSize}V${chevronSize / 2}L${chevronSize /
      2} ${chevronSize}'/%3E%3C/g%3E%3C/svg%3E")`,
  },
}));

const CalendarUnavailableSlot = ({ className, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return <div className={classNames(classes.root, className)} {...other} />;
};

CalendarUnavailableSlot.propTypes = {
  className: PropTypes.string,
};

CalendarUnavailableSlot.defaultProps = {
  className: '',
};

export default CalendarUnavailableSlot;
