import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: 8,
      paddingLeft: 24,
      paddingRight: 24,
      marginBottom: 24,
    },
  };
});

const Typo = ({ children, className, classes: eClasses, ...props }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Typography
      variant="body2"
      color="inherit"
      className={classNames(classes.root, className)}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default Typo;
