// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { TextField, Typography } from '@material-ui/core';

import Label from '../Label';

const useStyles = makeStyles(theme => ({
  root: {},
  // someState: {},
  multiline: {
    whiteSpace: 'pre-wrap',
  },
  rhythmFix: {
    // Make total height respect vertical rhythm when wrapped in Label:
    paddingBottom: 2,
  },
}));

// Conditionally renders an editable or read-only value (String)
// in the correct component (TextField for editable, Label + Typography for read-only)
// Accepts props for configuration used by both components (eg label)

// TBD: what if we want to use a single value Autosuggest to add stuff? :/
// abstract away component used for editing to seperate component after all?
// hmmmm.....
// NOTE: Autosuggest uses an Input/TextField internally (Input currently, investigate why)
// so maybe we can still pass the shared props to it?

// TBD/TODO: implement generic empty state handling for Typography here?

// TBD/TODO: placeholder prop for use in empty state and textfield placeholder?

const DataFieldText = ({
  children, // NOTE: only works if select = true
  className: classNameProp,
  editable,
  label,
  LabelProps,
  margin,
  multiline,
  select,
  TextFieldProps,
  TypographyProps,
  value,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      // [classes.someState]: prop_expression_here,
    },
    classNameProp,
  );

  const readOnlyText = (
    <Typography
      variant="subtitle1"
      className={classNames(
        label && classes.rhythmFix,
        multiline && classes.multiline,
        !label && className,
      )}
      {...TypographyProps}
    >
      {value}
    </Typography>
  );

  return editable ? (
    <TextField
      label={label}
      value={value}
      margin={margin}
      multiline={multiline}
      select={select}
      className={className}
      {...TextFieldProps}
    >
      {select && children}
    </TextField>
  ) : label ? (
    <Label label={label} margin={margin} className={className} {...LabelProps}>
      {readOnlyText}
    </Label>
  ) : (
    readOnlyText
  );
};

DataFieldText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  editable: PropTypes.bool,
  label: PropTypes.string,
  LabelProps: PropTypes.object,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  multiline: PropTypes.bool,
  select: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  TypographyProps: PropTypes.object,
  value: PropTypes.string.isRequired,
};

DataFieldText.defaultProps = {
  children: '',
  className: '',
  editable: false,
  label: '',
  LabelProps: null,
  margin: 'none',
  multiline: false,
  select: false,
  TextFieldProps: null,
  TypographyProps: null,
};

export default DataFieldText;
