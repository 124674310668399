import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, LinearProgress } from '@material-ui/core';

import Flex from '../Flex';

import ColoredLinearProgress from './ColoredLinearProgress';

const useStyles = makeStyles(() => ({
  labels: {
    paddingTop: 8,
  },
}));

const LabeledProgress = ({ leftLabel, rightLabel, className, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={className}>
      <ColoredLinearProgress variant="determinate" {...other} />
      <Flex justifyContent="space-between" className={classes.labels}>
        <Typography variant="body1" color="textSecondary">
          {leftLabel}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {rightLabel}
        </Typography>
      </Flex>
    </div>
  );
};

LabeledProgress.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

LabeledProgress.defaultProps = {
  color: '',
  className: '',
};

export default LabeledProgress;
