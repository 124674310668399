// @flow
import React from 'react';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';

function CurrencyInput(props) {
  const { inputRef, onChange, currency, thousandSeparator, decimalSeparator, ...other } = props;
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      prefix={currency}
    />
  );
}

CurrencyInput.propTypes = {
  currency: PropTypes.string.isRequired,
  decimalSeparator: PropTypes.oneOf([' ', ',', '.', true, false]),
  thousandSeparator: PropTypes.oneOf([' ', ',', '.', true, false]),
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

CurrencyInput.defaultProps = {
  currency: '€',
  decimalSeparator: '.',
  thousandSeparator: ' ',
};

export default CurrencyInput;
