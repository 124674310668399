import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Downshift from 'downshift';
import DefaultInput from './Input';
import Menu from './Menu';

const useStyles = makeStyles(() => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
}));

// TODO: make configurable whether you can add the currentValue from input
// (last item in suggestions container)
//       for use in filters where you can't create new labels

const Autosuggest = ({
  children,
  className,
  theme,
  items,
  fullWidth,
  selectedItem,
  getInputProps,
  loading,
  onRemoveItem,
  classes: eClasses,
  ...props
}) => {
  const classes = useStyles({ classes: eClasses });

  // TODO: children might also be an array
  // {CustomInputComponent ? <CustomInputComponent /> : null}
  const hasCustomInput = !!children;

  // console.log('INPUT', Input);
  return (
    <Downshift {...props}>
      {downshiftProps => {
        const { isOpen } = downshiftProps;
        const inputProps = {
          selectedItem,
          onRemoveItem,
          getInputProps,
          loading,
          downshiftProps,
        };
        return (
          <div className={classNames(classes.root, fullWidth && classes.fullWidth, className)}>
            {hasCustomInput ? (
              React.cloneElement(children, inputProps)
            ) : (
              <DefaultInput {...inputProps} />
            )}
            {isOpen && <Menu items={items} downshiftProps={downshiftProps} />}
          </div>
        );
      }}
    </Downshift>
  );
};
Autosuggest.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  getInputProps: PropTypes.func,
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onRemoveItem: PropTypes.func,
  selectedItem: PropTypes.array,
  theme: PropTypes.object.isRequired,
};

Autosuggest.defaultProps = {
  itemToString: i => i,
};

export default Autosuggest;
