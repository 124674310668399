import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    height: '100%',
    boxShadow: theme.shadows[12],
    zIndex: theme.zIndex.drawer,
    overflow: 'visible',
  },
  disableShadow: {
    boxShadow: 'none',
  },
  rootOverlay: {
    position: 'fixed',
    top: 0,
    right: 0,
  },
  openOverlay: {
    opacity: 1,
    transform: 'translate3D(0, 0, 0)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  closedOverlay: {
    opacity: 0,
    transform: 'translate3D(100%, 0, 0)',
    transition: theme.transitions.create(['transform', 'opacity'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  openDocked: {
    opacity: 1,
    transition: theme.transitions.create(['width', 'transform', 'opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter,
    }),
  },
  closedDocked: {
    width: '0 !important',
    opacity: 0,
    transition: theme.transitions.create(['width', 'transform', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  childrenContainer: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  shift: {},
}));

// A view that can open and close
// Can be docked or in overlay

const DetailView = ({
  children,
  disableShadow,
  docked,
  open,
  width,
  shift,
  setActionBarContainerNode,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  let rootClassNames = classNames(
    classes.rootOverlay,
    open && classes.openOverlay,
    !open && classes.closedOverlay,
  );

  if (docked) {
    rootClassNames = classNames(open && classes.openDocked, !open && classes.closedDocked);
  }

  let style = {
    // width,
  };

  if (shift) {
    rootClassNames = classNames(rootClassNames, classes.shift);
    style = {
      ...style,
      transform: `translateX(${-shift}px)`,
    };
  }

  return (
    <div
      className={classNames(classes.root, rootClassNames, disableShadow && classes.disableShadow)}
      style={style}
      {...other}
    >
      <div
        className={classes.childrenContainer}
        style={{
          width,
        }}
      >
        {children}
        <div className="actionbar-wrapper" ref={setActionBarContainerNode} />
      </div>
    </div>
  );
};
DetailView.propTypes = {
  children: PropTypes.node.isRequired,
  disableShadow: PropTypes.bool,
  docked: PropTypes.bool,
  open: PropTypes.bool,
  setActionBarContainerNode: PropTypes.func.isRequired,
  shift: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DetailView.defaultProps = {
  docked: false,
  open: false,
  disableShadow: false,
  setActionBarContainerNode: () => console.log('implement setActionBarContainerNode'),
  shift: 0,
};

export default DetailView;
