const EDGE_SIZE = 16;
// Calculates where the user started dragging, so which action should be executed
export function getDragHandlePosition(size, initialNodeOffset, initialPointerOffset) {
  if (!initialNodeOffset || !initialPointerOffset) {
    return null;
  }
  const { x: pointerX } = initialPointerOffset;
  const { x: leftEdge } = initialNodeOffset;
  const rightEdge = leftEdge + size;

  if (Math.abs(leftEdge - pointerX) < EDGE_SIZE) {
    return 'left';
  } else if (Math.abs(rightEdge - pointerX) < EDGE_SIZE) {
    return 'right';
  }

  return 'center';
}

export function getMovedUnits(offsetDifference, scale, dragHandle, size) {
  let units =
    dragHandle === 'left'
      ? Math.floor(offsetDifference.x / scale)
      : Math.ceil(offsetDifference.x / scale);

  if (dragHandle === 'left' && units > (size - scale) / scale) {
    units = (size - scale) / scale;
  } else if (dragHandle === 'right' && units < -(size - scale) / scale) {
    units = -(size - scale) / scale;
  }

  return units;
}

export function getMovedPixels(offsetDifference, scale, dragHandle, size) {
  return getMovedUnits(offsetDifference, scale, dragHandle, size) * scale;
}
