import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import StoringSnack from './StoringSnack';

const AutoSave = ({ open, storingLabel, storedLabel, autoHideDuration, ...other }) => {
  const [status, setStatus] = useState('storing');
  const [snackOpen, setSnackOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  useEffect(() => {
    if (!timer && snackOpen && status === 'storing') {
      setTimer(
        setTimeout(() => {
          setStatus('stored');
          setTimer(null);
        }, 1000 + (Math.random() * (autoHideDuration - 1000)) / 2),
      );
    }
  });
  useEffect(() => {
    console.log('open:: ', open);

    if (open && !snackOpen) {
      setStatus('storing');
      setSnackOpen(true);
    }
    if (!open && status === 'stored') {
      setSnackOpen(false);
    }
  }, [open, status]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open
      // open={snackOpen}
      autoHideDuration={autoHideDuration}
      {...other}
      message="I love snacks"
    >
      <StoringSnack
        variant={status === 'storing' ? 'loading' : 'success'}
        message={status === 'storing' ? storingLabel : storedLabel}
      />
    </Snackbar>
  );
};
AutoSave.propTypes = {
  autoHideDuration: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  storedLabel: PropTypes.string.isRequired,
  storingLabel: PropTypes.string.isRequired,
};

AutoSave.defaultProps = {
  autoHideDuration: 5000,
  storingLabel: 'Storing...',
  storedLabel: 'Done!',
};
export default AutoSave;
