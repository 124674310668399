import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Flex from '../Flex';

const useStyles = makeStyles(() => ({
  root: {},
  spacer: {
    flexGrow: 2,
  },
}));

const AlignRight = ({ children, classes: eClasses, ...other }) => {
  const classes = useStyles(other);
  return (
    <Flex centeredRow justifyContent="space-between" classes={{ root: classes.root }} {...other}>
      <div className={classes.spacer} />
      {children}
    </Flex>
  );
};
AlignRight.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

AlignRight.defaultProps = {};

export default AlignRight;
