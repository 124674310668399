import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    flexGrow: 1,
    flexBasis: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
  highlight: {
    backgroundColor: theme.palette.mediumDivider,
  },
  colorDefault: {
    color: theme.palette.text.primary,
  },
  colorInherit: {
    color: 'inherit',
  },
  colorTextSecondary: {
    color: theme.palette.text.secondary,
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
}));
const BoardLaneHeader = ({ color, title, subTitle, highlight, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(classes.root, {
    [classes.colorDefault]: color === 'default',
    [classes.colorInherit]: color === 'inherit',
    [classes.colorTextSecondary]: color === 'textSecondary',
    [classes.colorPrimary]: color === 'primary',
    [classes.colorSecondary]: color === 'secondary',
    [classes.highlight]: highlight,
  });

  return (
    <div className={className} {...other}>
      <Typography color="inherit" variant="body1">
        {title}
      </Typography>
      {subTitle && (
        <Typography color="inherit" variant="body1">
          {subTitle}
        </Typography>
      )}
    </div>
  );
};

BoardLaneHeader.propTypes = {
  color: PropTypes.oneOf(['default', 'inherit', 'textSecondary', 'primary', 'secondary']),
  highlight: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

BoardLaneHeader.defaultProps = {
  color: 'default',
  highlight: false,
  title: 'Provide title',
};

export default BoardLaneHeader;
