import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    transition: 'none',
    '&:hover': {
      // Disable the hover effect for the Button.
      backgroundColor: 'transparent',
    },
  },
  checked: {},
  disabled: {},
  input: {
    cursor: 'inherit',
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
  },
  label: {},
}));

const SwitchBaseButton = ({
  checked: checkedProp,
  className: classNameProp,
  defaultChecked,
  disabled: disabledProp,
  id,
  inputProps,
  inputRef,
  label,
  name,
  onChange,
  tabIndex,
  type,
  value,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const context = createContext();
  const isControlled = checkedProp !== null;
  const { muiFormControl } = context;
  const [checked, setChecked] = useState(isControlled ? checkedProp : defaultChecked);

  const handleInputChange = event => {
    const checked = event.target.checked;

    if (!isControlled) {
      setChecked(checked);
    }

    if (onChange) {
      onChange(event, checked);
    }
  };

  let disabled = disabledProp;

  if (muiFormControl) {
    if (typeof disabled === 'undefined') {
      disabled = muiFormControl.disabled;
    }
  }

  const hasLabelFor = type === 'checkbox' || type === 'radio';
  return (
    <Button
      data-mui-test="SwitchBaseButton"
      component="span"
      className={classNames(
        classes.root,
        {
          [classes.checked]: checked,
          [classes.disabled]: disabled,
        },
        classNameProp,
      )}
      classes={{ label: classes.label }}
      disabled={disabled}
      tabIndex={null}
      role={undefined}
      {...other}
    >
      {label}
      <input
        id={hasLabelFor && id}
        type={type}
        name={name}
        checked={checked}
        onChange={handleInputChange}
        className={classes.input}
        disabled={disabled}
        tabIndex={tabIndex}
        value={value}
        ref={inputRef}
        {...inputProps}
      />
    </Button>
  );
};

// NB: If changed, please update Checkbox, Switch and Radio
// so that the API documentation is updated.
SwitchBaseButton.propTypes = {
  /**
   * If `true`, the component is checked.
   */
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * @ignore
   */
  defaultChecked: PropTypes.bool,
  /**
   * If `true`, the switch will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the ripple effect will be disabled.
   */
  disableRipple: PropTypes.bool,
  /**
   * The id of the `input` element.
   */
  id: PropTypes.string,
  /**
   * If `true`, the component appears indeterminate.
   */
  indeterminate: PropTypes.bool,
  /**
   * The icon to display when the component is indeterminate.
   */
  indeterminateIcon: PropTypes.node,
  /**
   * Properties applied to the `input` element.
   */
  inputProps: PropTypes.object,
  /**
   * Use that property to pass a ref callback to the native input component.
   */
  inputRef: PropTypes.func,
  /**
   * The label to display inside the button
   */
  label: PropTypes.node,
  /*
   * @ignore
   */
  name: PropTypes.string,
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.checked`.
   * @param {boolean} checked The `checked` value of the switch
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The input component property `type`.
   */
  type: PropTypes.string,
  /**
   * The value of the component.
   */
  value: PropTypes.string,
};

SwitchBaseButton.defaultProps = {
  checked: false,
  defaultChecked: false,
  type: 'checkbox',
};

SwitchBaseButton.contextTypes = {
  muiFormControl: PropTypes.object,
};
SwitchBaseButton.displayName = 'MuiSwitchBaseButton';
export default SwitchBaseButton;
