import lifecycle from 'recompose/lifecycle';

// Returns the difference between two objects.
// Very useful in debugging react performance.
// Only use in development, this is not intended for production use.

function shallowDiff(base, compared) {
  const unchanged = [];
  const deleted = [];
  const added = [];

  const updated = {};

  Object.keys(compared).forEach((key) => {
    const value = compared[key];
    if (!(key in base)) {
      added.push(key);
    } else if (value !== base[key]) {
      // updated.push(key);
      updated[key] = { old: base[key], next: compared[key] };
    } else if (value === base[key]) {
      unchanged.push(key);
    }
  });

  Object.keys(base).forEach((key) => {
    if (!(key in compared)) {
      deleted.push(key);
    }
  });

  return {
    updated,
    unchanged,
    added,
    deleted,
  };
}

export default lifecycle({
  componentWillReceiveProps(nextProps) {
    console.log('SHALLOW DIFF UPDATE', shallowDiff(this.props, nextProps));
  },
});

// HANDY DANDY COMPOSABLE FUNCTION TO DEBUG RENDER TRIGGERS
//
// shouldUpdate((props, nextProps) => {
//   console.log(shallowDiff(props, nextProps).updated);
//   return true;
// }),
