import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// Kinda looks like a chip but smaller and is dumb (presentational only)
// TODO: better color solution than inline style
// Rename and just use classes? Is most flexible

const useStyles = makeStyles(theme => {
  const height = 24;
  const backgroundColor = theme.palette.primary[500];

  return {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height,
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor,
      color: theme.palette.getContrastText(backgroundColor),
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      borderRadius: height / 2,
      whiteSpace: 'nowrap',
    },
  };
});

const ColoredLabel = ({
  className,
  color,
  component: Component,
  label,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Component
      className={classNames(classes.root, className)}
      style={{ backgroundColor: color }}
      {...other}
    >
      {label}
    </Component>
  );
};

ColoredLabel.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  label: PropTypes.node,
};

ColoredLabel.defaultProps = {
  className: '',
  color: '',
  component: 'div',
  label: '',
};

export default ColoredLabel;
