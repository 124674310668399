import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

import { Buffer } from 'buffer';

/** ****************************************************************************
 * Component
 **************************************************************************** */
const Camera = forwardRef(({ width, height }, ref) => {
  const camRef = useRef();
  useImperativeHandle(ref, () => ({
    async getImageSrc(buffer) {
      const imageSrc = camRef.current.getScreenshot();
      if (buffer) {
        return {
          buffer: Buffer.from(imageSrc.replace(/^data:image\/\w+;base64,/, ''), 'base64'),
          url: imageSrc,
        };
      }
      return { url: imageSrc };
    },
  }));

  return (
    <div>
      <Webcam
        audio={false}
        height="auto"
        ref={camRef}
        screenshotFormat="image/jpeg"
        width="100%"
        videoConstraints={{
          width,
          height,
          facingMode: 'user',
        }}
      />
    </div>
  );
});

Camera.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Camera.defaultProps = {
  width: 1280,
  height: 720,
};

export default Camera;
