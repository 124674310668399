import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Flex from '../Flex/Flex';

import Page from './Page';

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'initial',
    width: '100%',
  },
  pages: {
    flexGrow: 2,
    textAlign: 'center',
  },
  page: {
    width: 24,
    minWidth: 24,
  },
}));

const Pagination = ({
  onChange,
  pageSize,
  pageRange,
  totalNumber,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageSelection = value => {
    setCurrentPage(value);
    onChange(value);
  };

  const totalPage = Math.ceil(totalNumber / pageSize);

  let rangeStart = currentPage - pageRange;
  let rangeEnd = currentPage + pageRange;

  if (rangeEnd > totalPage) {
    rangeEnd = totalPage;
    rangeStart = totalPage - pageRange * 2;
    rangeStart = rangeStart < 1 ? 1 : rangeStart;
  }

  if (rangeStart <= 1) {
    rangeStart = 1;
    rangeEnd = Math.min(pageRange * 2 + 1, totalPage);
  }

  const pages = [...Array(rangeEnd - rangeStart + 1)].map((_, i) => rangeStart + i);
  return (
    <Flex flexShrink={0} alignItems="center" className={classNames(classes.root)} {...other}>
      <div className={classNames(classes.pages)}>
        <IconButton onClick={() => handlePageSelection(1)}>
          <FirstPageIcon />
        </IconButton>
        {pages.map(page => (
          <Page
            className={classNames(classes.page)}
            key={page}
            value={page}
            isActive={currentPage === page}
            onClick={() => handlePageSelection(page)}
          />
        ))}
        <IconButton onClick={() => handlePageSelection(totalPage)}>
          <LastPageIcon />
        </IconButton>
      </div>
    </Flex>
  );
};
Pagination.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  pageRange: PropTypes.number,
  pageSize: PropTypes.number,
  totalNumber: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  pageSize: 1, // Entries of per page
  pageRange: 2, // Range of visible page number, the amount on both sides of the selected page
  totalNumber: 1, // Specify the total number of entries
  pageNumber: 1, // Specify the page number at initialization.
};
Pagination.displayName = 'Pagination';
export default Pagination;
