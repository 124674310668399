import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'absolute',
  },
  resizeHandle: {
    position: 'absolute',
    width: 8,
    height: '100%',
    cursor: 'col-resize',
    zIndex: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    '&:last-child': {
      right: 0,
    },
  },
  active: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}));
const TimeslotDragPreview = ({ children, width, className, dragHandle, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(className, classes.root)} style={{ width }}>
      {dragHandle !== 'center' && (
        <div
          className={classNames(classes.resizeHandle, dragHandle === 'left' && classes.active)}
        />
      )}
      {children}
      {dragHandle !== 'center' && (
        <div
          className={classNames(classes.resizeHandle, dragHandle === 'right' && classes.active)}
        />
      )}
    </div>
  );
};

TimeslotDragPreview.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dragHandle: PropTypes.string,
  width: PropTypes.number.isRequired,
};

TimeslotDragPreview.defaultProps = {
  className: '',
  dragHandle: 'center',
};

// prettier-ignore
export default TimeslotDragPreview;
