import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';

const ConfirmationDialog = ({
  cancelLabel,
  confirmLabel,
  content,
  disableButtonConfirm,
  onCancel,
  onConfirm,
  text,
  title,
  ...other
}) => (
  <Dialog onClose={onCancel} {...other}>
    {!!title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{content || <DialogContentText>{text}</DialogContentText>}</DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        {cancelLabel}
      </Button>
      <Button disabled={disableButtonConfirm} onClick={onConfirm} color="primary">
        {confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  content: PropTypes.node,
  disableButtonConfirm: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  cancelLabel: 'Cancel',
  content: null,
  disableButtonConfirm: false,
  text: 'You forgot to set the Dialog text prop :)',
  title: '',
};

export default ConfirmationDialog;
