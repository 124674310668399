import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Tabs, Tab } from '@material-ui/core';
import { useWidth } from '../Hooks';

import Flex from '../Flex';
import ScrollableContent from '../ScrollableContent';
import ResponsiveAppBar from '../AppBar/ResponsiveAppBar';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {},
  tabs: {
    width: '100%',
  },
  bigTabs: {
    minHeight: theme.heights.toolbarBottom,
  },
  appBar: {
    position: 'static', // TODO: remove after SimpleAppBar className prop fix
    zIndex: 2,
    boxShadow: 'none',
  },
  extraToolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  evenWidth: {
    // Rules to make Tabs all equal width without shenanigans:
    flexBasis: 0,
    width: 0,
    minWidth: 'initial',
  },
  scrollContainer: {},
}));

const TabView = ({
  children,
  tabs,
  activeTab,
  onTabChange,
  appBarProps,
  fullWidth,
  evenWidth,
  classes: eClasses,
  ...props
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const scrollable = !tabs[activeTab].dontWrap;
  const isPhone = isWidthDown('xs', width);
  const TabsC = (
    <Tabs
      variant={!fullWidth || isPhone ? 'scrollable' : 'fullWidth'}
      scrollButtons="on"
      {...props}
      value={activeTab}
      indicatorColor="secondary"
      onChange={onTabChange}
      classes={{
        root: classNames(classes.tabs, !isPhone && classes.bigTabs),
      }}
    >
      {Object.keys(tabs).map(
        t =>
          tabs[t].label && (
            <Tab
              key={t}
              classes={{ root: !isPhone && classes.bigTabs }}
              className={classNames(
                classes.tabButton,
                fullWidth && !isPhone && evenWidth && classes.evenWidth,
              )}
              label={tabs[t].label}
              value={t}
            />
          ),
      )}
    </Tabs>
  );
  const { announcer, extraToolbar, ...otherAppBarProps } = appBarProps;
  return (
    <Flex fullColumn classes={{ root: classes.root }} {...props}>
      <ResponsiveAppBar
        titleOnTop
        extraToolbar={!isPhone && TabsC}
        classes={{ extraToolbar: classes.extraToolbar }}
        {...otherAppBarProps}
      />
      {isPhone && TabsC}
      {children ||
        (scrollable ? (
          <ScrollableContent classes={{ root: classes.scrollContainer }}>
            {tabs[activeTab].component}
          </ScrollableContent>
        ) : (
          <Fragment>
            {announcer && announcer}
            {tabs[activeTab].component}
          </Fragment>
        ))}
    </Flex>
  );
};

TabView.propTypes = {
  activeTab: PropTypes.string,
  appBarProps: PropTypes.object,
  children: PropTypes.object,
  default: PropTypes.string,
  evenWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.object.isRequired,
};

TabView.defaultProps = {
  fullWidth: true,
  evenWidth: true,
};

export default TabView;
