import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { isWidthDown } from '@material-ui/core/withWidth';
import { useWidth } from '../Hooks';

import Flex from '../Flex';

import FilterDrawer from './FilterDrawer';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    alignItems: 'stretch',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  inner: {
    flex: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  innerFull: {
    // width: '100vw',
  },
}));

const FilterView = ({
  children,
  className,
  open,
  onClose,
  filterContent,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const isPhone = isWidthDown('sm', width);

  return (
    <Flex className={classes.root} {...other}>
      <FilterDrawer open={open} onClose={onClose} overlay={isPhone}>
        {filterContent}
      </FilterDrawer>
      <div className={classNames(classes.inner, isPhone ? classes.innerFull : '')}>{children}</div>
    </Flex>
  );
};
FilterView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  filterContent: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

/* prettier-ignore */
export default FilterView;
