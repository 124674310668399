// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { Paper, IconButton, Input } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    overflow: 'hidden',
  },
  icon: {
    color: theme.palette.icon,
    marginLeft: 12,
    marginRight: 12,
  },
  input: {
    height: 48,
    padding: 0,
  },
  closeButton: {},
}));

// Search box in a card, for desktop

const SearchBox = ({ InputProps, onClickClose, placeholder, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <Paper elevation={2} className={classes.root} {...other}>
      <SearchIcon className={classes.icon} />
      <Input
        fullWidth
        disableUnderline
        placeholder={placeholder}
        classes={{ input: classes.input }}
        {...InputProps}
      />
      <IconButton className={classes.closeButton} onClick={onClickClose}>
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};
SearchBox.propTypes = {
  InputProps: PropTypes.object,
  onClickClose: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchBox.defaultProps = {
  InputProps: {},
  placeholder: '',
};

export default SearchBox;
