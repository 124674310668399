import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import classNames from 'classnames';

import ElementWidth from '../ElementWidth';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4),
    padding: theme.spacing(3),
    marginBottom: 0,
  },
  noPaper: {
    background: 'none',
    boxShadow: 'none',
    marginTop: 0,
    marginBottom: 0,
  },
  phone: {
    width: '100%',
    margin: 0,
    boxShadow: 'none',
    background: 'none',
  },
  noPaperPhone: {
    paddingTop: 0,
    paddinBottom: 0,
  },
}));

const ResponsiveSheet = ({ children, noPaper, maxWidth, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  const upMap = {
    [maxWidth]: { className: 'wide' },
    0: { className: 'phone' },
  };
  return (
    <ElementWidth upMap={upMap} style={{ width: '100%' }}>
      {({ width, className, bound }) => (
        <Paper
          elevation={2}
          className={classNames(
            classes.root,
            classes[className],
            noPaper && classes.noPaper,
            className === 'phone' && noPaper && classes.noPaperPhone,
          )}
          {...(bound ? { style: { maxWidth: bound } } : {})}
          {...other}
        >
          {children}
        </Paper>
      )}
    </ElementWidth>
  );
};

ResponsiveSheet.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  maxWidth: PropTypes.number.isRequired,
  noPaper: PropTypes.bool,
  width: PropTypes.string.isRequired,
};

ResponsiveSheet.defaultProps = {
  maxWidth: 480,
};
export default ResponsiveSheet;
