import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { LinearProgress } from '@material-ui/core';

const useStyles = ({ color }) =>
  makeStyles(theme => ({
    root: {
      display: 'flex',
      width: '100%',
      height: theme.spacing(1),
    },
    indicator: {
      display: 'block',
      flexGrow: 1,
      borderRadius: 2,
      backgroundColor: theme.palette.divider,
      // marginRight: theme.spacing(1),
      marginRight: 2,
    },
    active: {
      backgroundColor: theme.palette[color][500],
    },
  }));

const DiscreteProgress = ({ value, max, className, color, goLinearFrom, classes: eClasses }) => {
  const classes = useStyles({ classes: eClasses, color })();
  if (max >= goLinearFrom) {
    return (
      <LinearProgress
        variant="determinate"
        value={Math.round((100 * value) / max)}
        className={className}
      />
    );
  }
  return (
    <div className={classNames(classes.root, className)}>
      {Array(...Array(max)).map((i, index) => (
        <span className={classNames(classes.indicator, { [classes.active]: index < value })} />
      ))}
    </div>
  );
};

DiscreteProgress.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  goLinearFrom: PropTypes.number,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

DiscreteProgress.defaultProps = {
  color: 'primary',
  goLinearFrom: 21,
  value: 0,
};

export default DiscreteProgress;
