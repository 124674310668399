import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { AppBar, Toolbar, Collapse, Grow, Fab } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 1, // preserve shadow and fab
  },
  disableSurface: {
    backgroundColor: 'transparent',
  },
  disableShadow: {
    boxShadow: 'none',
  },
  toolbarTop: {
    height: 56,
    justifyContent: 'space-between',
    paddingLeft: 4,
    paddingRight: 4,
  },
  toolbarBottom: {
    justifyContent: 'space-between',
    height: 72,
    paddingRight: 8,
    paddingLeft: 72,
  },
  fabContainer: {
    position: 'absolute',
    bottom: -20,
    left: 16,
  },
  collapsibleToolbar: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 64,
    backgroundColor: theme.palette.primary[400],
    color: 'inherit',
    padding: 16,
  },
  collapsibleToolbarControl: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
  },
  navButtonContainer: {},
  [theme.breakpoints.up('sm')]: {
    toolbarTop: {
      height: 64,
      paddingRight: 12,
      paddingLeft: 12,
    },
    toolBarTopWide: {
      paddingRight: 20,
      paddingLeft: 20,
    },
    toolbarBottom: {
      height: 80,
      paddingRight: 16,
      paddingLeft: 104,
    },
    toolbarBottomWide: {
      paddingRight: 24,
      paddingLeft: 112,
    },
    fabContainer: {
      left: 24,
      bottom: -28,
    },
    fabContainerWide: {
      left: 32,
    },
    fab: {
      width: 56,
      height: 56,
    },
    noFabKeyline: {
      paddingLeft: `${theme.keylines.tabletContent}px`,
    },
    collapsibleToolbar: {
      paddingTop: 8,
      paddingRight: 24,
      paddingLeft: 104,
      paddingBottom: 8,
    },
  },
}));

// TODO: add theming support?

const AmpedAppBar = ({
  actions,
  collapsibleToolbar,
  collapsibleToolbarOpen,
  collapsibleToolbarControl,
  disableShadow,
  disableSurface,
  fabIcon,
  hideFab,
  navButton,
  onClickFab,
  title,
  wide,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <AppBar
      position="static"
      className={classNames(
        classes.root,
        disableSurface && classes.disableSurface,
        disableShadow && classes.disableShadow,
      )}
    >
      <Toolbar className={classNames(classes.toolbarTop, wide && classes.toolBarTopWide)}>
        <div className={classes.navButtonContainer}>{navButton}</div>
        {actions}
      </Toolbar>
      <Toolbar
        className={classNames(
          classes.toolbarBottom,
          wide && classes.toolbarBottomWide,
          !fabIcon && classes.noFabKeyline,
        )}
      >
        {fabIcon && (
          <Grow in={!hideFab}>
            <div className={classNames(classes.fabContainer, wide && classes.fabContainerWide)}>
              <Fab mini color="secondary" className={classes.fab} onClick={onClickFab}>
                {fabIcon}
              </Fab>
            </div>
          </Grow>
        )}
        {title}
        {collapsibleToolbar && (
          <div className={classes.collapsibleToolbarControl}>{collapsibleToolbarControl}</div>
        )}
      </Toolbar>
      <Collapse in={collapsibleToolbarOpen}>
        <div className={classNames(classes.collapsibleToolbar, !fabIcon && classes.noFabKeyline)}>
          {collapsibleToolbar}
        </div>
      </Collapse>
    </AppBar>
  );
};

AmpedAppBar.propTypes = {
  actions: PropTypes.node,
  collapsibleToolbar: PropTypes.node,
  collapsibleToolbarControl: PropTypes.node,
  collapsibleToolbarOpen: PropTypes.bool,
  disableShadow: PropTypes.bool,
  disableSurface: PropTypes.bool,
  fabIcon: PropTypes.node,
  hideFab: PropTypes.bool,
  navButton: PropTypes.node,
  onClickFab: PropTypes.func,
  title: PropTypes.node.isRequired,
  wide: PropTypes.bool, // Adjusts keylines for DetailView AppBar on tablet and up
};

AmpedAppBar.defaultProps = {
  actions: '',
  collapsibleToolbar: '',
  collapsibleToolbarControl: '',
  collapsibleToolbarOpen: false,
  disableShadow: false,
  disableSurface: false,
  fabIcon: '',
  hideFab: false,
  navButton: '',
  wide: false,
};
export default AmpedAppBar;
