// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

/*
Rounded

Simple wrapper to apply border radius as defined in theme

Has align prop for presets that look like Facebook Messenger's message grouping
*/

// TODO: rename and move

// Idea: StyledList or something
//       component that selects first, middle and last children
//       basicly a JSS styling API wrapper
//       + make it style its children?
//       OR ifI'mFirst, ifI'mLast style stuff to wrap around elements?
//
//
// + move messenger style out of here
const useStyles = makeStyles(theme => {
  const smallRadius = theme.increments.typography;
  const largeRadius = theme.typography.subtitle1.fontSize;

  const select = {
    first: '&:first-child:not(:last-child)',
    middle: '&:not(:first-child):not(:last-child)',
    last: '&:last-child:not(:first-child)',
  };

  return {
    root: {
      borderRadius: largeRadius,
      overflow: 'hidden',
    },
    alignLeft: {
      [select.first]: {
        borderBottomLeftRadius: smallRadius,
      },
      [select.middle]: {
        borderTopLeftRadius: smallRadius,
        borderBottomLeftRadius: smallRadius,
      },
      [select.last]: {
        borderTopLeftRadius: smallRadius,
      },
    },
    alignCenter: {
      [select.first]: {
        borderBottomLeftRadius: smallRadius,
        borderBottomRightRadius: smallRadius,
      },
      [select.middle]: {
        borderRadius: smallRadius,
      },
      [select.last]: {
        borderTopRightRadius: smallRadius,
        borderTopLeftRadius: smallRadius,
      },
    },
    alignRight: {
      [select.first]: {
        borderBottomRightRadius: smallRadius,
      },
      [select.middle]: {
        borderTopRightRadius: smallRadius,
        borderBottomRightRadius: smallRadius,
      },
      [select.last]: {
        borderTopRightRadius: smallRadius,
      },
    },
  };
});

const Rounded = ({
  align,
  children,
  className: classNameProp,
  component: componentProp,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const className = classNames(
    classes.root,
    {
      [classes.alignLeft]: align === 'left',
      [classes.alignCenter]: align === 'center',
      [classes.alignRight]: align === 'right',
    },
    classNameProp,
  );

  const Component = componentProp || 'div';

  return (
    <Component className={className} {...other}>
      {children}
    </Component>
  );
};

Rounded.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Rounded.defaultProps = {
  align: 'left',
  className: '',
};

export default Rounded;
