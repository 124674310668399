import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';

const VirtualizedList = ({ ...listProps }) => (
  <AutoSizer>
    {({ height, width }) => <List height={height} width={width} {...listProps} />}
  </AutoSizer>
);

VirtualizedList.propTypes = {
  classes: PropTypes.object,
  rowCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  rowRenderer: PropTypes.func.isRequired,
};

VirtualizedList.defaultProps = {};

export default VirtualizedList;
