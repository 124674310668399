import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Avatar, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover $avatar:first-child:not(:only-child)': {},
    '&:hover $avatarContainer:not(:first-child)': {
      position: 'static',
      marginLeft: 4,
    },
  },
  avatarContainer: {
    transition: theme.transitions.create(['margin'], {
      duration: theme.transitions.duration.shorter,
    }),
    '&:not(:first-child)': {
      position: 'relative',
      marginLeft: -20, // half the default avatar size
    },
    // '&:nth-child(3)': {
    //   TODO: write function to increment this over all children
    //   so we can use transform instead of margin transition
    //   transform: 'translateX(-75%)',
    // },
  },
  avatar: {},
  tooltip: {},
  [theme.breakpoints.up('sm')]: {},
}));

// TODO: direction support (up, right, down, left)
// TODO: add prop to always spread out instead of only on hover ?
// TODO: disable hover interaction on mobile? :/ not foolproof with just media queries
// TODO: make amount of avatars shown configurable? or handle in user land?

const AvatarStack = ({ className, persons, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(classes.root, className)} {...other}>
      {persons.map(person => (
        <Tooltip
          title={person.username}
          key={person.avatarUrl}
          className={classNames(classes.tooltip, classes.avatarContainer)}
        >
          <Avatar alt={person.username} src={person.avatarUrl} className={classes.avatar} />
        </Tooltip>
      ))}
    </div>
  );
};
AvatarStack.propTypes = {
  className: PropTypes.string,
  persons: PropTypes.arrayOf(
    PropTypes.shape({
      avatarUrl: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }),
  ).isRequired,
  // TODO: more accessible API? rework component to not have map in it and supply a demo instead?
  // check best practices and how MUI handles similar patterns
};

AvatarStack.defaultProps = {
  className: '',
};

export default AvatarStack;
