import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { ListItem, ListItemText } from '@material-ui/core/';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  listItem: {
    width: '100%',
    boxSizing: 'border-box',
  },
  listItemText: {
    maxWidth: '85%',
    boxSizing: 'border-box',
    '& > p, & > h3': {
      fontSize: 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& > h3': {
      fontSize: 14,
      paddingRight: 48,
    },
  },
  listItemAuxText: {
    position: 'absolute',
    right: 16,
    top: 13,
  },
  highlightText: {
    '& > h3': {
      fontWeight: 500,
    },
    '& > p': {
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },
  colored: {
    color: theme.palette.accent[800],
  },
  selected: {
    background: 'rgba(0, 0, 0, .05)',
  },
}));

const ThreadListItem = ({
  children,
  id,
  title,
  timestamp,
  secondary,
  hasNewMessages,
  active,
  onClick,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <ListItem
      button
      dense
      className={classNames(classes.listItem, active && classes.selected)}
      data-thread-id={id}
      onClick={onClick}
    >
      {children}
      <ListItemText
        className={classNames(classes.listItemText, hasNewMessages && classes.highlightText)}
        primary={title}
        secondary={secondary}
      />
      <Typography
        variant="caption"
        className={classNames(classes.listItemAuxText, hasNewMessages && classes.colored)}
      >
        {timestamp}
      </Typography>
    </ListItem>
  );
};

ThreadListItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hasNewMessages: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  secondary: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ThreadListItem.defaultProps = {
  hasNewMessages: false,
  active: false,
  onClick: null,
  secondary: '',
};

export default ThreadListItem;
