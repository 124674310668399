import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import MinusIcon from '@material-ui/icons/RemoveCircleOutline';

import { IconButton } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/AddCircleOutline';
import ScoreItem from './ScoreItem';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
    iconLeft: {
      marginRight: theme.spacing(1),
    },
    iconRight: {
      marginLeft: theme.spacing(1),
    },
  }),
  { withTheme: true },
);

const ScoringListItem = ({
  onChange,
  canIncrement,
  canDecrement,
  value,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classes.root}>
      <IconButton
        className={classes.iconLeft}
        onClick={event => {
          onChange(value - 1);
        }}
        disabled={!canDecrement}
      >
        <MinusIcon />
      </IconButton>
      <ScoreItem value={value} {...other} />
      <IconButton
        className={classes.iconRight}
        onClick={event => {
          onChange(value + 1);
        }}
        disabled={!canIncrement}
      >
        <PlusIcon />
      </IconButton>
    </div>
  );
};

ScoringListItem.propTypes = {
  canDecrement: PropTypes.bool,
  canIncrement: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

ScoringListItem.defaultProps = {
  canIncrement: true,
  canDecrement: true,
  value: 0,
};

export default ScoringListItem;
