// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import RadioButtonGroup from './RadioButtonGroup';

import RadioButton from './RadioButton';

const useStyles = makeStyles(theme => ({
  root: {},
  group: {
    width: 60,
  },
  label: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Boolean = ({ label, value, onChange, yesLabel, noLabel, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classes.root}>
      <Typography variant="caption">{label}</Typography>
      <RadioButtonGroup
        value={value === undefined ? undefined : value ? 'yes' : 'no'}
        classes={{ root: classes.group }}
        onChange={event => {
          onChange({ ...event, target: { ...event.target, value: event.target.value === 'yes' } });
        }}
        {...other}
      >
        <RadioButton label={yesLabel} value="yes" />
        <RadioButton label={noLabel} value="no" />
      </RadioButtonGroup>
    </div>
  );
};

Boolean.propTypes = {
  label: PropTypes.string,
  noLabel: PropTypes.string,
  value: PropTypes.bool,
  yesLabel: PropTypes.string,
};

Boolean.defaultProps = {
  yesLabel: 'yes',
  noLabel: 'no',
};

export default Boolean;
