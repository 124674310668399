// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

const Views = ({ children, classes: eClasses, ...other }) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <main className={classes.root} {...other}>
      {children}
    </main>
  );
};
Views.propTypes = {
  children: PropTypes.node.isRequired,
};

Views.defaultProps = {};
export default Views;
