import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AppDialogs = ({ history, dialogs, panels, dialogProps }) => {
  const handleCloseDialog = () => {
    const { register } = dialogProps;
    const { propsForUrl = {} } = register[history.getQuery('dialog')] || {};
    Object.entries(propsForUrl).forEach(([key, value]) => {
      history.removeQuery(key, value);
    });
    history.removeQuery('dialog');
  };

  const handleConfirmDialog = data => {
    const { register } = dialogProps;
    const dialog = history.getQuery('dialog');
    if (register) {
      const props = register[dialog];
      if (props) {
        const confirm = props.callback;
        const { propsForUrl = {} } = props;
        Object.entries(propsForUrl).forEach(([key, value]) => {
          history.removeQuery(key, value);
        });
        if (confirm) {
          confirm(data);
          delete register[dialog];
        }
      }
    }
    history.removeQuery('dialog');
  };

  const currentDialog = history.getQuery('dialog');

  return (
    <Fragment>
      {Object.keys(dialogs).map(dialog => {
        const DialogComponent = dialogs[dialog].Component;
        return (
          <DialogComponent
            key={dialog}
            history={history}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDialog}
            panels={panels}
            open={currentDialog === dialog}
            {...dialogProps}
          />
        );
      })}
    </Fragment>
  );
};

AppDialogs.propTypes = {
  dialogProps: PropTypes.object,
  dialogs: PropTypes.object.isRequired,
  history: PropTypes.object,
  panels: PropTypes.array.isRequired,
  register: PropTypes.object,
};

export default AppDialogs;
