import React from 'react';
import PropTypes from 'prop-types';
import ReactPinInput from 'react-pin-input';

const PinInput = React.forwardRef(
  ({ focus, onComplete, onChange, pinLength }, ref) => {
    const onPinChange = value => {
      if (value.completed) {
        onComplete(value.value);
      } else if (onChange) {
        onChange(value);
      }
    };
    return (
      <ReactPinInput
        length={pinLength}
        initialValue=""
        secret
        focus={focus}
        onChange={value => onPinChange({ value, completed: false })}
        type="numeric"
        style={{ padding: '10px', margin: 'auto' }}
        inputStyle={{}}
        inputFocusStyle={{}}
        onComplete={value => onPinChange({ value, completed: true })}
        ref={ref}
      />
    );
  },
);

PinInput.propTypes = {
  focus: PropTypes.bool,
  onChange: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  pinLength: PropTypes.number,
};

PinInput.defaultProps = {
  focus: true,
  pinLength: 4,
};

export default PinInput;
