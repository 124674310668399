import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// General purpose flexbox container with shorthands
// for commonly used property combinations
// Defaults: div, display: flex, position: relative

const useStyles = makeStyles(theme => ({
  root: {},
}));

const Flex = ({
  centerContent,
  centeredRow,
  fullColumn,
  spacedRow,
  children,
  className,
  element: Element,
  classes: eClasses,
  ...style
}) => {
  const classes = useStyles({ classes: eClasses });
  const fullColumnStyle = {
    height: '100%',
    flexDirection: 'column',
  };

  const centeredRowStyle = {
    flexDirection: 'row',
    alignItems: 'center',
  };

  const spacedRowStyle = {
    flexDirection: 'row',
    justifyContent: 'space-between',
  };

  const centerContentStyle = {
    alignItems: 'center',
    justifyContent: 'center',
  };

  const elementStyle = {}; // TODO: troubleshoot, doesn't work
  Object.assign(
    elementStyle,
    fullColumn && fullColumnStyle,
    centeredRow && centeredRowStyle,
    spacedRow && spacedRowStyle,
    centerContent && centerContentStyle,
    style,
  );

  return (
    <Element className={classNames(className, classes.root)} style={elementStyle}>
      {children}
    </Element>
  );
};

Flex.propTypes = {
  /* eslint-disable react/require-default-props */
  alignContent: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'space-around',
    'space-between',
    'stretch',
  ]),
  alignItems: PropTypes.oneOf(['baseline', 'center', 'flex-end', 'flex-start', 'stretch']),
  alignSelf: PropTypes.oneOf(['baseline', 'center', 'flex-end', 'flex-start', 'stretch']),
  centerContent: PropTypes.bool,
  centeredRow: PropTypes.bool, // Shorthand
  children: PropTypes.node.isRequired, // Shorthand
  className: PropTypes.string,
  display: PropTypes.oneOf(['flex', 'inline-flex']),
  element: PropTypes.node,
  flex: PropTypes.string,
  flexBasis: PropTypes.string,
  flexDirection: PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  flexWrap: PropTypes.oneOf(['nowrap', 'wrap-reverse', 'wrap']),
  fullColumn: PropTypes.bool, // Shorthand
  height: PropTypes.string,
  justifyContent: PropTypes.oneOf([
    'center',
    'flex-end',
    'flex-start',
    'space-around',
    'space-between',
  ]),
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  order: PropTypes.number,
  position: PropTypes.oneOf(['static', 'absolute', 'relative', 'fixed']),
  spacedRow: PropTypes.bool, // Shorthand
  width: PropTypes.string,
  /* eslint-enable */
};

Flex.defaultProps = {
  centerContent: false,
  centeredRow: false,
  className: '',
  display: 'flex',
  element: 'div',
  fullColumn: false,
  position: 'relative',
  spacedRow: false,
};

export default Flex;
