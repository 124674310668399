import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// A slot to put an item on the calendar
// Positions and sizes it to fit on the calendar according to its start and end index
const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

const CalendarSlot = ({
  cellHeight,
  children,
  className,
  end,
  extraStyle,
  start,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const startOffset = (cellHeight + 1) * start; // +1 for border
  const height = (end - start) * cellHeight;

  return (
    <div
      className={classNames(classes.root, className)}
      style={{ height, top: startOffset, ...extraStyle }}
      {...other}
    >
      {children}
    </div>
  );
};

CalendarSlot.propTypes = {
  cellHeight: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  end: PropTypes.number.isRequired,
  extraStyle: PropTypes.object,
  start: PropTypes.number.isRequired,
};

CalendarSlot.defaultProps = {
  children: '',
  cellHeight: 48,
  className: '',
  extraStyle: {},
};

export default CalendarSlot;
