import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import FileIcon from '@material-ui/icons/InsertDriveFile';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import UploadIcon from '@material-ui/icons/CloudUpload';
import RemoveIcon from '@material-ui/icons/Delete';
import BusyIcon from '@material-ui/icons/Sync';

import { Typography, IconButton, ListItemIcon } from '@material-ui/core';
import Flex from '../Flex';

import { isOfTypeImage, getResizedImage } from './helpers';

const useStyles = makeStyles(theme => ({
  // '@keyframes spin': {
  //   from: { transform: 'rotate(0deg)' },
  //   to: { transform: 'rotate(-90deg)' },
  // },
  fileInputText: {
    flex: '1 0',
    padding: '0 16px',
    minWidth: 0,
  },
  thumbnail: {
    width: 40,
    height: 40,
  },
  fileIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    margin: 0,
    color: theme.palette.icon,
  },
  busyWrapper: {
    width: 48,
    height: 48,
    padding: 0,
    // fontSize: '1.5rem',
    textAlign: 'center',
  },
  busyIcon: {
    animation: '1s linear 0s infinite reverse spin',
    height: 48,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const FileInput = ({
  description,
  fileUrl,
  fileType,
  label,
  onClickDownloadFile,
  onClickRemoveFile,
  onClickUploadFile,
  onClickOpen,
  translations,
  unremoveable,
  uploading,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  const fileName = fileUrl && fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
  const t = {
    upload: 'Upload your file here...',
    uploading: 'Uploading...',
    download: 'download',
    remove: 'remove',
    select: 'select file',
    ...translations,
  };

  let secondaryText = description || fileName || t.upload;

  let thumbnail;
  if (fileUrl) {
    thumbnail = (
      <ListItemIcon className={classes.fileIcon} onClick={onClickOpen}>
        <FileIcon />
      </ListItemIcon>
    );
    if (isOfTypeImage(fileType || fileName)) {
      thumbnail = (
        <img
          src={getResizedImage(fileUrl, 'w=80&h=80&t=square')}
          alt={fileName}
          className={classes.thumbnail}
          onClick={onClickOpen}
        />
      );
    }
  }

  if (uploading) {
    secondaryText = t.uploading;
  }

  return (
    <Flex centeredRow justifyContent="space-between">
      {thumbnail}
      <div className={classes.fileInputText}>
        <Typography variant="subtitle1" noWrap title={fileName}>
          {label}
        </Typography>
        <Typography variant="body1" color="textSecondary" noWrap>
          {secondaryText}
        </Typography>
      </div>
      {fileUrl && (
        <Fragment>
          <IconButton onClick={onClickDownloadFile} data-url={fileUrl} title={t.download}>
            <DownloadIcon />
          </IconButton>
          {!unremoveable && (
            <IconButton onClick={onClickRemoveFile} title={t.remove}>
              <RemoveIcon />
            </IconButton>
          )}
        </Fragment>
      )}
      {!fileUrl &&
        (uploading ? (
          <div className={classes.busyWrapper}>
            <BusyIcon className={classes.busyIcon} />
          </div>
        ) : (
          <IconButton onClick={onClickUploadFile} title={t.select}>
            <UploadIcon />
          </IconButton>
        ))}
    </Flex>
  );
};

FileInput.propTypes = {
  description: PropTypes.string,
  fileType: PropTypes.string,
  fileUrl: PropTypes.string,
  interactive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClickDownloadFile: PropTypes.func,
  onClickOpen: PropTypes.func,
  onClickRemoveFile: PropTypes.func,
  onClickUploadFile: PropTypes.func,
  translations: PropTypes.object,
  unremoveable: PropTypes.bool,
  uploading: PropTypes.bool,
};

FileInput.defaultProps = {
  description: null,
  fileType: null,
  fileUrl: null,
  interactive: false,
  onClickDownloadFile: null,
  onClickUploadFile: null,
  onClickRemoveFile: null,
  onClickOpen: null,
  translations: {},
  unremoveable: false,
  uploading: false,
};

// prettier-ignore
export default FileInput;
