import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    position: 'relative',
  },
  grid: {
    height: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    flexShrink: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
  cell: {
    flex: '1 0 auto',
    boxSizing: 'border-box',
    padding: '4px 24px 4px 8px',
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  label: {},
}));

const TimelineGridMarkings = ({
  children,
  className,
  onClickUnit,
  units,
  width,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.grid, className)} {...other}>
        {units.map((unit, index) => (
          <div
            key={unit}
            role="button"
            className={classes.cell}
            style={{ width }}
            onClick={() => onClickUnit(unit)}
            onKeyPress={() => onClickUnit(unit)}
            tabIndex={index}
          />
        ))}
      </div>
      {children}
    </div>
  );
};

TimelineGridMarkings.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.number,
  onClickUnit: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
};

TimelineGridMarkings.defaultProps = {
  className: '',
  height: 64,
  onClickUnit: () => {
    console.log('onClickUnit');
  },
  width: 72,
};

export default TimelineGridMarkings;
