import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Avatar, Zoom } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 2,
    pointerEvents: 'auto',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.85)' : 'rgba(255,255,255,0.9)',
  },
  icon: {
    position: 'absolute',
  },
  active: {},
  inactive: {},
}));

const ToggleButton = forwardRef(
  ({ className, active, onToggle, IconActive, IconInactive, classes: eClasses }, ref) => {
    const classes = useStyles({ classes: eClasses });
    const toggle = () => onToggle(!active);
    const ActiveIcon = IconActive || CloseIcon;
    const InactiveIcon = IconInactive || CheckIcon;

    return (
      <Avatar
        className={clsx(classes.root, className, {
          [classes.active]: active,
          [classes.inactive]: !active,
        })}
        ref={ref}
      >
        <Zoom in={active}>
          <IconButton className={classes.icon} onClick={toggle}>
            <ActiveIcon />
          </IconButton>
        </Zoom>
        <Zoom in={!active}>
          <IconButton className={classes.icon} onClick={toggle}>
            <InactiveIcon />
          </IconButton>
        </Zoom>
      </Avatar>
    );
  },
);

export default ToggleButton;
